$baseClass: baseclass('Textarea');

.#{$baseClass} {
    &--label {
        position: relative;
        padding-top: 9px;
        white-space: nowrap;
    }

    &--hidden {
        position: absolute;
        left: -9999px;
        visibility: hidden;
    }

    &--initialized {
        .#{$baseClass}__label {
            opacity: 1;
            transition: opacity $_timeS, color $_timeS, font-size $_timeS, transform $_timeS;
        }
    }

    &--focused:not(&--disabled) {
        .#{$baseClass}__label {
            color: $_colorChathamsBlue;
        }

        .#{$baseClass}__textarea {
            border: 1px solid $_colorChathamsBlue;
        }

        &.#{$baseClass}--withButton {
            .#{$baseClass}__textarea {
                border-right: 0;
            }
        }
    }

    &:not(.#{$baseClass}--filled):not(.#{$baseClass}--focused):not(.#{$baseClass}--top) .#{$baseClass}__label {
        transform: translate3d(0, 24px, 0);
        font-size: $_fontSizeM;
        color: $_colorBlackSecondary;
    }

    &--disabled {
        pointer-events: none;

        .#{$baseClass}__label {
            color: $_colorBoulder;
        }

        .#{$baseClass}__textarea {
            color: $_colorSilver;
        }
    }

    &--error {
        .#{$baseClass}__error,
        .#{$baseClass}__label {
            color: $_colorCarnation;
        }

        .#{$baseClass}__textarea {
            border-color: $_colorCarnation;
        }

        &.#{$baseClass}--focused:not(.#{$baseClass}--disabled) {
            .#{$baseClass}__label {
                color: $_colorCarnation;
            }

            .#{$baseClass}__textarea {
                border: 1px solid $_colorCarnation;
            }

            &.#{$baseClass}--withButton {
                .#{$baseClass}__textarea {
                    border-right: 0;
                }
            }
        }
    }

    &--onlytext {
        textarea {
            display: none;
        }

        div {
            b {
                font-weight: bold;
            }

            i {
                font-style: italic;
            }
        }
    }

    &--announcement {
        display: flex;
        border: 1px solid $_colorAlto;
        background: $_colorAlabaster;
        line-height: 18px;
        padding: $_insetM;

        .#{$baseClass}__messageText {
            flex-grow: 1;
        }
    }
}

.#{$baseClass}__label {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 10px;
    opacity: 0;

    color: $_colorMineShaft;
    font-size: $_fontSizeS;
    line-height: 16px;
    padding: 0 6px;
    background: $_colorWhite;

    &--required::after {
        content: '*';
    }
}

.#{$baseClass}__textarea {
    background: $_colorAlabaster;
    border: none;
    border: 1px solid $_colorAlto;
    border-radius: $_borderRadiusDefault;
    width: 100%;
    padding: $_stackM;
    transition: border-color $_timeS ease-in-out;
    appearance: none;
    outline: none;
    overflow: hidden;
    resize: none;
    min-height: 7em;
    display: block;

    color: $_colorMineShaft;
    font-size: 16px;
    line-height: 24px;
}

.#{$baseClass}__message,
.#{$baseClass}__error {
    color: $_colorMineShaft;
    font-size: $_fontSizeS;
    line-height: 16px;
    padding-left: 17px;
}
