$baseClass: baseclass('Checkout');

.#{$baseClass} {
    width: 100%;
    background: $_colorWhite;
    padding-top: $_insetM;
    position: relative;

    @include clearfix;
}

.#{$baseClass}__steps {
    margin-top: $_stackM;
    border: 1px solid $_colorAlto;
}

.#{$baseClass}__cart {
    width: 100%;
}

.#{$baseClass}__steps {
    width: 100%;
}

@media #{$_mediaLDown} {
    .#{$baseClass}__cart {
        position: sticky;
        top: 0;
        align-self: flex-start;
        z-index: 499;
        box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.3);
    }
}

@media #{$_mediaXLUp} {
    .#{$baseClass}__steps {
        margin-top: 0;
        width: calc(100% - 380px);
    }

    .#{$baseClass}__cart {
        float: right;
        width: 355px;
    }

    .#{$baseClass}__cartInner {
        z-index: 1;
        max-width: 355px;
        max-height: calc(100vh - 130px);
        overflow: auto;
        width: 100%;
    }
}

.#{$baseClass}__loader {
    margin: 10px;
    display: flex;
    justify-content: center;
    min-height: 200px;
}

.#{$baseClass}__loaderIcon {
    background: $_colorWhite;
    animation: linearRotationKeyframes $_timeL ease-in-out infinite;
    transform-origin: center;
}

.#{$baseClass}__storedCCForm {
    display: none;
}

.#{$baseClass}__storedCCForm input {
    border: 1px solid red;
    background: white;
    width: 100%;
    padding: 10px;
    margin: 10px;
}

.#{$baseClass}__storedCCForm button[type=submit] {
    border: 1px solid black;
    background: lightgray;
    padding: 10px;
    margin: 10px;
}

.#{$baseClass}__storedCCForm iframe {
    border: 1px solid black;
    padding: 10px;
    margin: 10px;
}

.#{$baseClass}--rebranding {
    .#{$baseClass}__steps {
        border-color: $_colorRebrandingGallery;
    }
}
