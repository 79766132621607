$baseClass: baseclass('ProgressBar');

.#{$baseClass}__inner {
    padding: 4px;
    background: $_colorWildSand;
    border-radius: 6px;
    display: flex;
}

.#{$baseClass}__progressBar {
    height: 18px;
    border-top-left-radius: 7px;
    border-bottom-left-radius: 7px;
    width: 0;
    background-color: $_colorToryBlue;
    transition: 0.8s linear;
    transition-property: width, background-color;
    color: $_colorWhite;
    text-align: right;
}

.#{$baseClass}__header {
    color: $_colorRollingStone;
    font-size: 13px;
    font-weight: bold;
    margin-left: 7px;
    margin-bottom: 4px;
}

.#{$baseClass}__outsideText,
.#{$baseClass}__insideText {
    display: none;
    font-size: 14px;
    align-self: center;
    padding: 0 7px;
}

.#{$baseClass}__insideText {
    font-weight: bold;
}