$baseClass: baseclass('Icon');

.#{$baseClass} {
    display: block;

    &--positive {
        fill: $_colorJapaneseLaurel;
    }

    &--neutral {
        fill: $_colorMineShaft;
    }

    &--white {
        fill: $_colorWhite;
    }
}
