$baseClass: baseclass('Toast');

.#{$baseClass} {
    width: 100%;
}

@media print {
    .#{$baseClass} {
        display: none;
    }
}
