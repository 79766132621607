$baseClass: baseclass('TheDialog');

.#{$baseClass} {
    position: fixed;
    z-index: 99999;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;

    opacity: 0;
    transition: opacity $_timeXS ease-in-out;

    background: rgba(51, 51, 51, 0.5);

    &--visible {
        display: flex;
    }

    &--background {
        opacity: 1;
    }

    &--content {
        .#{$baseClass}__inner {
            opacity: 1;
            transform: translate(0, 0);
        }
    }

    &--noPadding {
        .#{$baseClass}__content {
            padding: 0;
        }
    }

    &--wide {
        .#{$baseClass}__inner {
            max-width: 730px;
        }
    }

    &--loading {
        text-align: center;
    }
}

.#{$baseClass}__inner {
    display: flex;
    width: 100%;
    flex-direction: column;
    max-height: calc(100vh - 40px);
    max-width: 480px;
    box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.3);
    background: $_colorWhite;

    opacity: 0;
    transform: translate(0, -20px);
    transition: opacity $_timeXS ease-in-out, transform $_timeXS ease-in-out;
}


.#{$baseClass}__header {
    position: relative;
    display: flex;
    align-items: center;
    padding: $_insetM $_insetM $_insetM #{$_insetM + 7px};
    border-bottom: 1px solid $_colorBlackDecorative;
    flex-shrink: 0;

    &::before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 7px;
        height: 100%;
        background: $_colorBrand;
    }
}

.#{$baseClass}__close {
    flex-shrink: 0;
}

.#{$baseClass}__title {
    min-height: 0;
    display: flex;
    width: 100%;
    align-items: center;

    font-weight: bold;
    color: $_colorMineShaft;
}

.#{$baseClass}__content {
    flex-grow: 1;
    padding: $_insetM;

    overflow: auto;
    -webkit-overflow-scrolling: touch;

    font-size: $_fontSizeS;
    line-height: 16px;

    strong {
        font-weight: bold;
    }
}

.#{$baseClass}__buttons {
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
    align-items: center;
    padding: $_insetS;
    border-top: 1px solid $_colorBlackDecorative;
    flex-shrink: 0;

    &:empty {
        display: none;
    }
}

.#{$baseClass}__button {
    margin: $_stackS;
    flex-grow: 1;

    &:last-child {
        flex-grow: 999;
    }
}

@media all and (-ms-high-contrast: none) {
    .#{$baseClass}__inner {
        overflow: auto;
        display: block;
    }
}

@media #{$_mediaSDown} {
    .#{$baseClass}__button {
        min-width: calc(50% - #{$_stackM});
        flex-grow: 1;
    }
}

@media #{$_mediaMDown} {
    .#{$baseClass}__inner {
        max-height: 100%;
    }
}

.#{$baseClass}--rebranding {
    .#{$baseClass}__header::before{
        background: $_colorRebrandingPrimary;
    }
}
