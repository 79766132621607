$baseClass: baseclass('CarisItem');

.#{$baseClass} {
    padding: 0 $_insetM $_insetM;
    background: $_colorWhite;
    font-size: 14px;
    line-height: 20px;

    &--single {
        padding-top: $_insetM;
    }

    &--checkedCar {
        .#{$baseClass}__label,
        .#{$baseClass}__name {
            padding-right: 20px;
        }
    }

    &--hasLabel {
        .#{$baseClass}__label {
            color: $_colorNero;
        }

        .#{$baseClass}__name,
        .#{$baseClass}__type {
            color: $_colorRollingStone;
        }
    }
}

.#{$baseClass}__saved {
    float: right;
}

.#{$baseClass}__row {
    display: flex;
    flex-wrap: wrap;
}

.#{$baseClass}__label {
    font-weight: bold;
}

.#{$baseClass}__name {
    font-weight: bold;
    color: $_colorNero;
}

.#{$baseClass}__type {
    flex-grow: 1;
    color: $_colorNero;
}

.#{$baseClass}__buttons {
    margin: 10px -#{$_stackS} 0;
    display: flex;
    flex-wrap: wrap;
}

.#{$baseClass}__button {
    margin: 0 $_insetS;
    font-size: 11px;
    line-height: 16px;
    padding-left: $_insetL;
    padding-right: $_insetL;
}
