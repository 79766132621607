$baseClass: baseclass('AddressPhoneDialog');

.#{$baseClass}__text {
    margin-bottom: $_stackS;

    font-size: $_fontSizeS;
    color: $_colorMineShaft;
    line-height: 20px;

    b {
        font-weight: bold;
    }
}

.#{$baseClass}__input {
    font-size: $_fontSizeM;
}
