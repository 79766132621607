$baseClass: baseclass('IconsTextList');

.#{$baseClass}__item {
    display: flex;
    margin-left: 0;
    color: $_colorMineShaft;
    line-height: 1.3em;

    & + & {
        margin-top: $_stackS;
    }
}

.#{$baseClass}__itemIcon {
    align-self: center;
    flex-shrink: 0;
    margin-right: $_stackS;
    fill: $_colorMineShaft;
    display: block;
    width: 14px;
    height: 12px;
}

.#{$baseClass}--rebranding {
    .#{$baseClass}__item {
        @include rebranding-para-regular;
        color: $_colorRebrandingBlackOut;
    }
}
