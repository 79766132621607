$baseClass: baseclass('CatalogFilterRow');

@mixin flex-column {
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: stretch;
}

@mixin flex-row {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    align-items: center;
}

.#{$baseClass}__grid {
    @include flex-row;

    flex-wrap: nowrap;

    @media #{$_mediaMDown} {
        display: block;
    }
}

.#{$baseClass} {
    padding: $_insetS 0;
    background-color: $_colorWildSand;

    @media #{$_mediaMDown} {
        padding: $_insetM;
        border-bottom: 1px solid $_colorAlt;
    }

    &--collapsed {
        .#{$baseClass}__rowBody {
            margin-top: 0;
        }
    }
}

.#{$baseClass}__rowHead {
    flex: 0 0 120px;

    .#{$baseClass}__titleTab {
        @media #{$_mediaLUp} {
            .#{$baseClass}__titleArrow {
                display: none;
            }
        }
    }
}

.#{$baseClass}__titleTab {
    @media #{$_mediaLUp} {
        .#{baseClass}__titleArrow {
            display: none;
        }
    }
}

.#{$baseClass}__titleText {
    color: $_colorRollingStone;
    display: inline;
    vertical-align: middle;
}

.#{$baseClass}__titleArrow {
    display: inline;
    vertical-align: middle;
    fill: $_colorBrand;
    width: 25px;
}

.#{$baseClass}__rowBody {
    flex: 1 0;

    @media #{$_mediaMDown} {
        margin-top: 12px;
    }
}

.#{$baseClass}__accordion {
    transition: max-height $_timeS ease-in-out;
}

.#{$baseClass}__itemsContainer {
    @include flex-row;

    margin: 0 -($_stackXS);
    max-width: 100%;

    @media #{$_mediaMDown} {
        @include flex-column;

        align-items: stretch;
    }
}

.#{$baseClass}__item {
    padding: $_insetXS $_insetS;
    flex-grow: 1;
    align-self: stretch;

    &--checkbox {
        max-width: 33.3%;
        min-width: 285px;
    }

    &--dropdown {
        flex: 1 1 100%;
        max-width: 25%;
    }

    &--brand {
        flex-grow: 0;
        min-width: 90px;
    }

    @media #{$_mediaMDown} {
        max-width: none;
        padding: 0;
    }
}

.#{$baseClass} {
    @media #{$_mediaMDown} {
        .#{$baseClass}__accordion {
            overflow-y: hidden;
            max-height: 0;
        }
    }

    &--expanded {
        .#{$baseClass}__accordion {
            max-height: 1000px;
        }

        .#{$baseClass}__titleArrow {
            transform: rotate(180deg);
        }

        .#{$baseClass}__mobileValues {
            display: none;
        }
    }
}

.#{$baseClass}--noCollapse {
    border-bottom: none;

    @media #{$_mediaMDown} {
        padding: 0;

        flex: 0 0 120px;

        .#{$baseClass}__itemsContainer {
            margin: 0;
            display: block;
        }

        .#{$baseClass}__accordion {
            max-height: none !important;
            overflow: visible;
        }

        .#{$baseClass}__grid {
            padding: 0;
        }

        .#{$baseClass}__rowBody {
            margin-top: 0;
        }

        .#{$baseClass}__rowHead {
            display: none;
        }
    }
}
