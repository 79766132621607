$baseClass: baseclass('TopCategories');

.#{$baseClass} {
    background: $_colorWhite;
}

.#{$baseClass}__desktop {
    @media #{$_mediaMDown} {
        display: none;
    }
}

.#{$baseClass}__mobile {
    display: none;

    @media #{$_mediaMDown} {
        display: block;
        margin: 0 #{-$_stackM};

        overflow-x: hidden;
    }

    @media #{$_mediaSDown} {
        margin: 0 #{-$_stackS};
    }
}

.#{$baseClass}__categories {
    display: flex;
    flex-wrap: wrap;
}

.#{$baseClass}__category {
    width: 25%;
    margin-bottom: $_stackM;
    padding-right: $_insetM;

    @media (max-width: 850px) {
        width: 50%;
    }

    @media #{$_mediaSDown} {
        width: 100%;
        padding-right: 0;
    }
}

.#{$baseClass}__categoryHeaderLink {
    display: flex;
    align-items: center;
    min-height: 55px;
    justify-content: flex-start;
}

.#{$baseClass}__categoryHeaderText {
    overflow: hidden;
    min-height: 72px;
    align-items: center;
    position: relative;
    flex-grow: 1;
}

.#{$baseClass}__categoryHeaderTextInner {
    overflow: hidden;
    text-overflow: ellipsis;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
}

.#{$baseClass}__categoryHeader {
    color: $_colorBrand;
    font-size: 20px;
    line-height: 24px;
    font-weight: bold;
    margin-bottom: $_insetM;
}

.#{$baseClass}__imageContainer,
.#{$baseClass}__imageContainer img {
    margin-right: 10px;
    max-width: 72px;
    max-height: 72px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
}

.#{$baseClass}__image {
    max-width: 100%;
    max-height: 100%;
    width: 100%;
}

.#{$baseClass}__subCategories {
    margin-bottom: $_insetM;
}

.#{$baseClass}__subCategory {
    display: flex;
    align-items: flex-start;
    margin-bottom: $_stackS;
    word-break: break-word;
    max-width: 260px;

    svg {
        flex-shrink: 0;
        margin-left: -9px;
        width: 24px;
        fill: $_colorRollingStone;
    }

    &:hover {
        color: lighten($_colorMineShaft, 10);

        svg {
            fill: $_colorBrand;
        }
    }
}

.#{$baseClass}__subCategory--disabled {
    color: $_colorRollingStone;
    padding-left: $_insetM;

    &:hover {
        color: $_colorRollingStone;
    }
}

.#{$baseClass}__subCategoryTitle {
    max-width: 235px;
    line-height: 1.4em;
}

.#{$baseClass}__icon {
    fill: $_colorBoulder;
    padding-right: $_insetS;
    width: 35px;
}

.#{$baseClass}__arrowRight {
    fill: $_colorBrand;
}

.#{$baseClass}__bottom {
    display: flex;
    width: 100%;
    justify-content: center;
    margin-top: $_stackL;
}

.#{$baseClass}__mobileCategories {
    display: flex;
    flex-direction: column;
}
