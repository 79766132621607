$baseClass: baseclass('FormWrapper');

.#{$baseClass}__errors {
    margin-bottom: $_stackM;
}

.#{$baseClass}__error {
    color: $_colorRed;
    line-height: 20px;

    & + & {
        margin-top: $_stackS;
    }
}
