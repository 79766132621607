$baseClass: baseclass('FormField');

.#{$baseClass}--full {
    display: block;
    width: 100%;

    .#{$baseClass}__inner {
        padding-left: $_insetM;
        padding-right: $_insetM;
    }
}
