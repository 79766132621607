
@mixin u-hidden-xs-up {
    display: none !important;
}

@mixin u-hidden-xs-down {
    @media #{$_mediaXSDown} {
        display: none !important;
    }
}

@mixin u-hidden-sm-up {
    @media #{$_mediaSUp} {
        display: none !important;
    }
}

@mixin u-hidden-sm-down {
    @media #{$_mediaSDown} {
        display: none !important;
    }
}

@mixin u-hidden-md-up {
    @media #{$_mediaMUp} {
        display: none !important;
    }
}

@mixin u-hidden-md-down {
    @media #{$_mediaMDown} {
        display: none !important;
    }
}

@mixin u-hidden-lg-up {
    @media #{$_mediaLUp} {
        display: none !important;
    }
}

@mixin u-hidden-lg-down {
    @media #{$_mediaLDown} {
        display: none !important;
    }
}

@mixin u-hidden-xl-up {
    @media #{$_mediaXLUp} {
        display: none !important;
    }
}

@mixin u-hidden-xs-only {
    @media #{$_mediaXSDown} {
        display: none !important;
    }
}

@mixin u-hidden-sm-only {
    @media #{$_mediaS} {
        display: none !important;
    }
}

@mixin u-hidden-md-only {
    @media #{$_mediaM} {
        display: none !important;
    }
}

@mixin u-hidden-lg-only {
    @media #{$_mediaL} {
        display: none !important;
    }
}

@mixin u-hidden-xl-only {
    @media #{$_mediaXLUp} {
        display: none !important;
    }
}

.u-hidden-xs-up,
.u-hidden-xl-down {
    @include u-hidden-xs-up;
}

.u-hidden-xs-down {
    @include u-hidden-xs-down;
}

.u-hidden-sm-up {
    @include u-hidden-sm-up;
}

.u-hidden-sm-down {
    @include u-hidden-sm-down;
}

.u-hidden-md-up {
    @include u-hidden-md-up;
}

.u-hidden-md-down {
    @include u-hidden-md-down;
}

.u-hidden-lg-up {
    @include u-hidden-lg-up;
}

.u-hidden-lg-down {
    @include u-hidden-lg-down;
}

.u-hidden-xl-up {
    @include u-hidden-xl-up;
}

.u-hidden-xs-only {
    @include u-hidden-xs-only;
}

.u-hidden-sm-only {
    @include u-hidden-sm-only;
}

.u-hidden-md-only {
    @include u-hidden-md-only;
}

.u-hidden-lg-only {
    @include u-hidden-lg-only;
}

.u-hidden-xl-only {
    @include u-hidden-xl-only;
}
