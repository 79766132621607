@import 'scss/pikaday';

$baseClass: baseclass('Date');

.#{$baseClass} {
    opacity: 0;
    transition: opacity $_timeXS ease-in-out;

    &--ready {
        opacity: 1;
    }
}
