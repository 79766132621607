$baseClass: baseclass('CheckoutSummaryTile');

.#{$baseClass} {
    display: flex;
    background: $_colorWhite;

    &:not(&--readonly) {
        cursor: pointer;
    }
}

.#{$baseClass}__items {
    flex-grow: 999;

    display: flex;
    flex-wrap: wrap;
    margin: -$_stackM;
}

.#{$baseClass}__item {
    flex-grow: 1;
    min-width: 50%;
    padding: $_stackM;

    font-size: $_fontSizeS;
    line-height: 20px;
}

.#{$baseClass}__itemHeadline {
    font-weight: bold;
}

.#{$baseClass}__itemContentRow {
    overflow: hidden;
    text-overflow: ellipsis;
}

.#{$baseClass}__trigger {
    padding: 0;
    color: $_colorRedRibbon;
    font-weight: bold;
    text-decoration: underline;
    cursor: pointer;
}

@media #{$_mediaSDown} {
    .#{$baseClass} {
        display: flex;
    }
}
