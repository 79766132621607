$baseClass: baseclass('UspItem');

.#{$baseClass} {
    min-height: 100px;

    &__link {
        display: block;
        width: 100%;
        height: 100%;
    }

    &__content {
        display: flex;
        flex-flow: column nowrap;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        height: 100%;
        padding: $_insetS $_insetM;
    }

    &__caption {
        text-align: center;
        line-height: 19px;
        width: 80%;
        min-width: 125px;

        @media #{$_mediaMDown} {
            font-size: $_fontSizeS;
            line-height: 14px;
        }
    }

    &__icon {
        width: 30px;
        height: 40px;
        margin-bottom: $_stackS;
        flex-shrink: 0;

        path,
        g {
            fill: $_colorChathamsBlue;
        }
    }
}
