$baseClass: baseclass('Overlay');

.#{$baseClass} {
    position: fixed;
    z-index: 99999;
    top: 0;
    left: 0;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    display: none;

    background: rgba(51, 51, 51, 0.5);

    opacity: 0;
    transition: opacity $_timeS ease-in-out;

    &--visible {
        display: flex;
    }

    &--background {
        opacity: 1;
    }

    &--content {
        .#{$baseClass}__center {
            opacity: 1;
            transform: translate(0, 0);
        }
    }
}

.#{$baseClass}__center {
    width: 100%;
    min-width: 300px;
    max-height: 100%;
    max-width: 680px;

    overflow: auto;
    -webkit-overflow-scrolling: touch;

    @include removeScrollbar;

    opacity: 0;
    transform: translate(0, -20px);
    transition: opacity $_timeXS ease-in-out, transform $_timeXS ease-in-out;
}

.#{$baseClass}__content {
    overflow: hidden;
}


@media #{$_mediaMUp} {
    width: calc(100% - 40px);
    max-height: calc(100% - 40px);
}
