$baseClass: baseclass('CategoriesRow');

.#{$baseClass} {
    width: 100%;
    padding-bottom: 58px;

    &__menuItems {
        display: flex;
        flex-flow: row wrap;
        align-content: baseline;
        margin: 0 (-$_stackS) (-$_stackS);
        justify-content: flex-start;
    }

    &__rowItem {
        width: 25%;
        padding: 0 $_stackS;
        margin-bottom: $_stackM;

        @media #{$_mediaLDown} {
            min-width: 33%;
        }

        @media #{$_mediaMDown} {
            min-width: 50%;
        }
    }
}

@media #{$_mediaSDown} {
    .#{$baseClass} {
        padding: $_insetS 0 58px 0;
    }
}

