$baseClass: baseclass('DefaultTemplate');

.#{$baseClass} {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    background: $_colorWhite;
}

.#{$baseClass}__main {
    flex-grow: 1;
}

.#{$baseClass}__mainInner {
    @include pageWidth;
    @include pagePadding;

    padding-top: $_insetM;
    padding-bottom: $_insetM;

    display: flex;
    flex-direction: column;
}

@media #{$_mediaLUp} {
    .#{$baseClass}__mainInner {
        padding-top: 24px;
        flex-direction: row;
    }

    .#{$baseClass}__navigation {
        flex-shrink: 0;
        margin-right: 50px;
    }

    .#{$baseClass}__content {
        flex-grow: 1;
        width: 100%;
    }

    .#{$baseClass}__AccountHeader {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        width: 100%;
    }
}

.#{$baseClass}--magazine {
    .#{$baseClass}__mainInner {
        flex-direction: column-reverse;

        @media #{$_mediaLUp} {
            flex-direction: row-reverse;
        }
    }

    @media #{$_mediaLUp} {
        .#{$baseClass}__navigation {
            margin-right: 0;
            margin-left: 25px;
            width: 320px;
        }
    }
}
