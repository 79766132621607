$baseClass: baseclass('Phone');

.#{$baseClass} {
    &--error {
        .#{$baseClass}__error {
            color: $_colorRed;
        }
    }

    &--wide {
        .#{$baseClass}__dropdown {
            flex-basis: 34%;
            min-width: 150px;
        }

        .#{$baseClass}__text {
            flex-basis: 66%;
        }
    }

    @media #{$_mediaSDown} {
        .#{$baseClass}__inner {
            flex-wrap: wrap;
        }

        .#{$baseClass}__dropdown,
        .#{$baseClass}__text {
            flex-basis: 100%;
            padding-right: 0;
            padding-left: 0;
        }

        .#{$baseClass}__dropdown {
            margin-bottom: $_stackS;
        }
    }
}

.#{$baseClass}__inner {
    display: flex;
}

.#{$baseClass}__dropdown {
    flex-basis: 25%;
    padding-right: $_insetS;
}

.#{$baseClass}__text {
    flex-basis: 75%;
    padding-left: $_insetS;
}

.#{$baseClass}__message,
.#{$baseClass}__error {
    color: $_colorMineShaft;
    font-size: $_fontSizeS;
    line-height: 16px;
    padding-left: 17px;
}
