$baseClass: baseclass('ToastItem');

.#{$baseClass} {
    width: 100%;
    background: $_colorConcrete;
    box-shadow: inset 0 -1px 0 0 rgba(0, 0, 0, 0.2);
    max-height: 0;
    overflow: hidden;
    transition: max-height $_timeS ease-in-out;

    &--error {
        background: $_colorMonza;

        .#{$baseClass}__message {
            color: $_colorWhite;
        }

        .#{$baseClass}__icon {
            fill: $_colorWhite;
        }

        .#{$baseClass}__close {
            svg {
                fill: $_colorWhite;
            }
        }
    }

    &--show {
        .#{$baseClass}__inner {
            opacity: 1;
        }
    }

    &--push {
        max-height: 200px;
    }
}

.#{$baseClass}__inner {
    opacity: 0;
    transition: opacity $_timeS ease-in-out;
    position: relative;

    @include pageWidth;
}

.#{$baseClass}__content {
    display: flex;
    justify-content: center;
    align-items: center;

    padding: 7px $_inlineXL 7px $_inlineM;
}

.#{$baseClass}__message {
    font-weight: bold;
    line-height: 26px;
    color: $_colorLimitedSpruce;
    text-align: center;

    a {
        text-decoration: underline;
    }
}

.#{$baseClass}__icon {
    fill: $_colorLimitedSpruce;
    margin-right: $_stackS;
    flex-shrink: 0;
}


.#{$baseClass}__close {
    position: absolute;
    right: $_inlineM;
    top: 50%;
    transform: translate(0, -50%);

    svg {
        fill: $_colorLimitedSpruce;
    }
}

@media #{$_mediaXS} {
    .#{$baseClass}__message {
        text-align: left;
    }
}
