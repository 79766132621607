$baseClass: baseclass("Availability");

.#{$baseClass} {
  display: flex;
  align-items: center;

  @media #{$_mediaSDown} {
    flex-wrap: wrap;
  }

  &__text {
    font-size: $_fontSizeS;
    text-transform: capitalize;
    flex-shrink: 0;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    text-transform: capitalize;
  }

  &::before {
    flex-shrink: 0;
    content: "";
    width: $_insetS;
    height: $_insetS;
    border-radius: 50%;
    background: $_colorRollingStone;
    margin-right: $_stackXS;
    margin-top: -2px;
    display: block;
  }

  &__available {
    color: $_colorJapaneseLaurel;

    div {
      font-weight: bold !important;
    }

    &::before {
      background: $_colorJapaneseLaurel;
    }
  }

  &__available_soon {
    color: $_colorNero;

    &::before {
      background: $_colorAmber;
    }
  }

  &__available2To3Days {
    color: $_colorNero;

    &::before {
      background: $_colorJapaneseLaurel;
    }
  }

  &__available--5-10-days {
    color: $_colorNero;

    &::before {
      background: $_color24Blue;
    }
  }

  &__not_available {
    color: $_colorNero;

    &::before {
      background: $_colorMonza;
    }
  }
}

.#{$baseClass}__message {
  color: $_colorRollingStone;
  font-size: $_fontSizeS;
  font-weight: normal;
  display: flex;
  align-items: center;

  @media #{$_mediaSDown} {
    flex-basis: 100%;
    margin-top: $_stackS;
  }

  &::before {
    flex-shrink: 0;
    content: "|";
    margin: 0 $_stackXS;
    display: block;

    @media #{$_mediaSDown} {
      display: none;
    }
  }
}

.#{$baseClass}--rebranding {
  color: $_colorRebrandingBlackOut;

  .#{$baseClass}__available {
    color: $_colorRebrandingSemanticGreen;

    &::before {
      background: $_colorRebrandingSemanticGreen;
    }
  }

  .#{$baseClass}__available_soon {
    &::before {
      background: $_colorRebrandingSemanticYellow;
    }
  }

  .#{$baseClass}__available2To3Days {
    &::before {
      background: $_colorRebrandingSemanticGreen;
    }
  }

  .#{$baseClass}__not_available {
    &::before {
      background: $_colorRebrandingSemanticRed;
    }
  }

  .#{$baseClass}__text {
    @include rebranding-helper-text-bold;
  }
}

.is-bplus-user {
  .#{$baseClass} {
    &__available2To3Days {
      color: $_colorNero;

      &::before {
        background: $_color24Blue;
      }
    }
  }
}
