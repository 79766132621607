$baseClass: baseclass('VehicleTile');

.#{$baseClass} {
    min-height: 100px;

    &--inactiveAction {
        opacity: 0.5;
        pointer-events: none;
    }
}

.#{$baseClass}__edit,
.#{$baseClass}__delete {
    &--disabled {
        background-color: $_colorSilver !important;
    }
}
