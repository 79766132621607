$baseClass: baseclass('Footer');

.#{$baseClass} {
    background: $_colorWhite;
}

.#{$baseClass}__inner {
    display: flex;
    flex-direction: column;
    margin-top: $_stackM;
    padding-bottom: $_insetM;
    border-top: 1px solid $_colorAlto;
}

.#{$baseClass}__row {
    @include pageWidth;

    display: flex;
    flex-flow: column wrap;

    &:first-child {
        .#{$baseClass}__column {
            margin-top: $_stackM;
        }
    }
}

.#{$baseClass}__headline {
    font-weight: bold;
    font-size: 18px;
    color: $_colorMineShaft;
    line-height: 30px;
}
.#{$baseClass}__newsletter {
    padding: 25px $_insetM;
    width: 60%;
    min-width: 300px;
}

.#{$baseClass}__socialWrapper {
    margin-left: auto;
}

.#{$baseClass}__socialHeadline {
    margin-bottom: $_stackS;

    white-space: nowrap;
    font-weight: bold;
    font-size: $_fontSizeM;
    color: $_colorMineShaft;
    line-height: 30px;
}

.#{$baseClass}__socials {
    display: flex;
}

.#{$baseClass}__social {
    & + & {
        margin-left: $_stackS;
    }
}

.#{$baseClass}__phone {
    margin-top: $_stackXS;

    font-weight: bold;
    font-size: $_fontSizeL;
    color: $_colorSecondary;
    line-height: 30px;
}

.#{$baseClass}__times {
    font-size: 14px;
    line-height: 30px;
    color: $_colorRollingStone;
}

.#{$baseClass}__hotlineContent {
    width: 100%;
}

.#{$baseClass}__buttons {
    margin: 0 -#{$_stackS};
    display: flex;
    flex-wrap: wrap;
}

.#{$baseClass}__button {
    margin: $_stackXS $_stackS;
    flex-grow: 1;
    white-space: nowrap;
}

.#{$baseClass}__services {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.#{$baseClass}__hotline {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
}

.#{$baseClass}__disclaimer {
    width: 100%;
    padding: 0 $_insetM;
    margin-bottom: 30px;
}

.#{$baseClass}__disclaimerInner {
    max-width: 1000px;

    font-size: $_fontSizeS;
    color: $_colorRollingStone;
    line-height: 16px;
}

.#{$baseClass}__copyright {
    display: flex;
    align-items: center;
    flex-grow: 1;
    font-size: 14px;
    padding: 0 $_insetM;

    color: $_colorRollingStone;
    line-height: 20px;
}

.#{$baseClass}__logos {
    display: flex;
    align-items: flex-end;
    padding: 0 $_insetM;
}

.#{$baseClass}__logo {
    & + & {
        margin-left: $_stackM;
    }
}

@media #{$_mediaMDown} {
    .#{$baseClass}__row {
        &--row {
            flex-direction: row;
            justify-content: space-between;
        }
    }

    .#{$baseClass}__column,
    .#{$baseClass}__channels {
        width: 100%;

        &--last {
            order: 999;
        }

        &--desktop {
            display: none;
        }
    }

    .#{$baseClass}__copyright {
        padding: 0 $_insetM;
    }

    .#{$baseClass}__disclaimer {
        padding: 0 $_insetM $_insetM;
    }

    .#{$baseClass}__newsletter {
        padding: $_insetM;
    }

    .#{$baseClass}__socialWrapper,
    .#{$baseClass}__hotline {
        padding: $_insetM;
    }

    .#{$baseClass}__newsletter {
        flex-grow: 1;
        max-width: 400px;
    }

    .#{$baseClass}__newsletterText {
        display: none;
    }

    .#{$baseClass}__socialWrapper {
        display: block;
        flex-grow: 0;
        -ms-flex-positive: 1;
    }

    .#{$baseClass}__hotline {
        border-bottom: 1px solid $_colorAlto;
    }

    .#{$baseClass}__logos {
        order: -1;
        padding: 0 $_stackM $_stackM;
    }

    .#{$baseClass}__mobile {
        order: -2;
        padding: $_insetM;
    }

    .#{$baseClass}__mobileLink {
        color: $_colorRollingStone;
        font-size: 14px;

        & + & {
            margin-left: $_stackM;
        }
    }

    .#{$baseClass}__disclaimer {
        margin-bottom: 0;
    }

    .#{$baseClass}__accordion {
        margin-top: $_stackXS;
        border-top: 1px solid $_colorAlto;
    }
}

@media #{$_mediaSDown} {
    .#{$baseClass}__socialWrapper,
    .#{$baseClass}__newsletter,
    .#{$baseClass}__hotline,
    .#{$baseClass}__disclaimer,
    .#{$baseClass}__copyright,
    .#{$baseClass}__logos,
    .#{$baseClass}__mobile {
        padding-right: $_insetS;
        padding-left: $_insetS;
    }

    .#{$baseClass}__row {
        display: block;
    }
}

@media #{$_mediaLUp} {
    .#{$baseClass}__row {
        flex-direction: row;

        &--down {
            align-items: flex-end;
        }
    }

    .#{$baseClass}__column,
    .#{$baseClass}__channels {
        padding: 0 $_insetM;
    }

    .#{$baseClass}__services {
        flex-direction: row;

        &--reverse {
            flex-direction: column-reverse;
        }
    }

    .#{$baseClass}__buttons {
        margin-top: $_stackM;
    }

    .#{$baseClass}__mobile {
        display: none;
    }

    .#{$baseClass}__column,
    .#{$baseClass}__channels {
        flex-grow: 1;
        flex-shrink: 0;
        width: 100%;
        margin-top: 25px;
        margin-bottom: 25px;

        &--1 {
            width: 20%;
        }

        &--2 {
            width: 40%;
        }

        &--3 {
            width: 60%;
        }

        &--space {
            display: flex;
            justify-content: space-between;
        }

        &--right {
            display: flex;
            align-items: flex-end;
            flex-direction: column;
        }
    }
}
