$baseClass: baseclass('AccordionList');

.#{$baseClass} {
    border-bottom: 1px solid $_colorAlt;
}

.#{$baseClass}__accordionTitle {
    color: $_colorCodGray;
    font-size: 20px;
    font-weight: bold;
    line-height: 26px;
    padding: 10px 10px 7px 20px;
    border: 1px solid $_colorAlt;
    border-bottom: 0;
}

.#{$baseClass}__toggle {
    width: 100%;
    display: flex;
    align-items: center;

    text-align: left;
    border-bottom: 0;
    cursor: pointer;
    padding: 0;

    @include blueFocus;
}

.#{$baseClass}__icon {
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    transition: transform $_timeS ease-in-out;
}

.#{$baseClass}__title {
    color: $_colorCodGray;
    font-size: 16px;
    font-weight: bold;
    line-height: 26px;
    padding: 7px 10px 7px 0;
}

.#{$baseClass}__subTitle {
    color: $_colorNevada;
    font-size: 14px;
    line-height: 26px;
}

.#{$baseClass}__accordion {
    opacity: 0;
    overflow: hidden;

    max-height: 0;
    transition: opacity $_timeS ease-in-out, max-height $_timeS ease-in-out;

    &--scrollable {
        height: 300px;
        overflow: auto;
    }
}

.#{$baseClass}__accordionBody {
    padding: 5px 10px 10px 40px;
}

.#{$baseClass}__accordionContainer {
    overflow: hidden;
    border: 1px solid $_colorAlt;
    border-bottom: 0;

    &--active {
        .#{$baseClass}__accordion {
            opacity: 1;

            & .#{$baseClass}__title {
                color: $_colorSecondary;
            }
        }

        .#{$baseClass}__icon {
            transform: rotate(-180deg);
            transform-origin: center;
        }
    }
}

@media #{$_mediaSDown} {
    .#{$baseClass}__accordionBody {
        padding-left: 10px;
    }
}
