$baseClass: baseclass('BreadcrumbsList');

.#{$baseClass} {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    margin-top: $_stackM;
    margin-left: -#{$_stackXS};

    @media #{$_mediaMDown} {
        flex-flow: column nowrap;
        align-items: flex-start;
    }
}

.#{$baseClass}__breadcrumbs {
    padding-top: 0;

    @media #{$_mediaLUp} {
        padding-left: 10px;
        border-left: solid 2px $_colorGoldDrop;
        margin-left: $_stackS;

        &:first-of-type {
            padding-left: 0;
            border-left: none;
            margin-left: 0;
        }
    }

    &--empty strong {
        font-weight:  400;
    }
}