$baseClass: baseclass('TileExtras');

.#{$baseClass} {
  display: flex;
  flex-direction: column;
  background: #F9F9F9;
  padding: 16px 16px 16px 134px;

  &:empty {
    display: none;
  }
}

.#{$baseClass}__row {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-bottom: 4px;

  &--with-discount {
    padding-right: 54px;
  }
}

.#{$baseClass}__label {
  flex-grow: 1;
}

.#{$baseClass}__unit-price {
  margin-right: 32px;
}

.#{$baseClass}__quantity {
  width: 82px; // Fixed width is required to be in sync with quantity selector of ProductTile component
  text-align: right;
}

.#{$baseClass}__total-price {
  font-weight: bold;
  width: 100px; // Fixed width is required to be in sync with total price of ProductTile component
  text-align: right;
}

@media #{$_mediaMDown} {
  .#{$baseClass} {
    padding-left: 16px;
  }

  .#{$baseClass}__unit-price {
    display: none;
  }

  .#{$baseClass}__row--with-discount {
    padding-right: 0;
  }
}

.#{$baseClass}--rebranding {
  color: $_colorRebrandingBlackOut;

  .#{$baseClass}__label, .#{$baseClass}__unit-price, .#{$baseClass}__quantity {
    @include rebranding-para-regular;
  }

  .#{$baseClass}__total-price {
    @include rebranding-para-bold;
  }
}
