$baseClass: baseclass('CatalogDropdownFilter');

.#{$baseClass} {
    position: relative;

    @include forceScrollbar;

    &--expanded {
        .#{$baseClass}__buttonIcon svg {
            transform: rotate(180deg);
        }

        .#{$baseClass}__mobileValues {
            display: none;
        }

        .#{$baseClass}__accordion {
            border: solid 1px $_colorAlt;
            max-height: 500px;
            -webkit-overflow-scrolling-: touch;
            z-index: 1;
            overflow-y: visible;
            margin-top: 12px;
        }

        .#{$baseClass}__buttonText {
            white-space: normal;
            text-overflow: ellipsis;
            max-height: 105px;
        }
    }

    &--showApplyButton {
        .#{$baseClass}__closeButton {
            display: none !important;
        }

        .#{$baseClass}__applyButton {
            display: block !important;
        }
    }

    .#{$baseClass}__closeButton {
        display: block;
    }

    .#{$baseClass}__applyButton {
        display: none;
    }

    @media #{$_mediaMDown} {
        padding: $_insetM;
        border-bottom: 1px solid $_colorAlt;
    }
}

.#{$baseClass}__dropdownHeader {
    @include u-hidden-md-down;

    .#{$baseClass}__inner {
        position: relative;
        background-color: $_colorWhite;
    }

    .#{$baseClass}__buttonIcon {
        border-left: 1px solid $_colorAlto;
    }
}

.#{$baseClass}__sectionHeader {
    @include u-hidden-lg-up;

    cursor: pointer;

    .#{$baseClass}__headerTitle {
        color: $_colorRollingStone;
        display: inline;
        vertical-align: middle;
    }

    .#{$baseClass}__buttonIcon {
        margin-left: 0;
        width: 25px;
        display: inline;
        vertical-align: middle;
    }
}

.#{$baseClass}__label {
    display: block;
    color: $_colorBlackPrimary;
    font-size: $_fontSizeS;
    line-height: 16px;
    position: absolute;
    top: 1px;
    transform: translate(0, -50%);
    z-index: 1;
    background: $_colorWhite;
    padding: 0 6px;
    margin: 0 10px;

    overflow: hidden;
    text-overflow: ellipsis;
    max-width: calc(100% - 66px);
    white-space: nowrap;

    transition: color $_timeS ease-in-out, font-size $_timeS ease-in-out, transform $_timeS ease-in-out;

    .#{$baseClass}--focused & {
        color: $_colorChathamsBlue;
    }
}

.#{$baseClass}__button {
    display: flex;
    border: 1px solid $_colorAlto;
    border-radius: $_borderRadiusDefault;
    background: $_colorWhite;
    transition: all $_timeS;
    cursor: pointer;

    .#{$baseClass}--label & {
        margin-top: 9px;
    }
}

.#{$baseClass}__buttonText {
    padding: 12px $_stackM;
    flex-grow: 1;
    font-size: 16px;
    color: $_colorMineShaft;
    line-height: 24px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    min-height: 48px;
}

.#{$baseClass}__buttonIcon {
    flex-shrink: 0;
    width: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: auto;
}

.#{$baseClass}__icon {
    fill: $_colorBrand;
    transition: fill $_timeS ease-in-out;

    .#{$baseClass}--focused & {
        fill: $_colorBrand;
    }
}

.#{$baseClass}__accordion {
    height: auto;
    width: 100%;
    max-height: 0;
    border-radius: 0 0 $_borderRadiusDefault $_borderRadiusDefault;
    border: none;
    background-color: $_colorWhite;
    overflow-y: hidden;
    position: absolute;
    bottom: 0;
    transform: translate(0, 100%);

    @media #{$_mediaMDown} {
        position: static;
        transform: none;
    }
}

.#{$baseClass}__accordionFrame {
    padding: $_insetM;
    padding-bottom: 0;
}

.#{$baseClass}__choicesFrame {
    position: relative;
    max-height: 220px;
    overflow-y: scroll;
    overflow: -moz-scrollbars-vertical;
    -webkit-overflow-scrolling-: touch;
}

.#{$baseClass}__choice {
    &--hidden {
        display: none;
    }
}

.#{$baseClass}__controllers {
    padding: $_insetM;
}

.#{$baseClass}__controllersPosition {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
}

.#{$baseClass}__controllersButton {
    width: 100%;

    &--hidden {
        display: none;
    }
}

.#{$baseClass}__searchBar {
    border-bottom: 1px solid $_colorAlto;
    padding: $_insetM $_insetM $_insetS;

    .#{$baseClass}__searchIcon {
        margin-right: 10px;
        flex-shrink: 0;

        path {
            fill: $_colorRollingStone;
        }
    }

    .#{$baseClass}__searchInput {
        width: 100%;
        height: 100%;
    }

    .#{$baseClass}__searchBarTitle {
        color: $_colorRollingStone;
    }
}

.#{$baseClass}__searchBarPosition {
    display: flex;
    flex-flow: row nowrap;
}
