$baseClass: baseclass('TileActions');

.#{$baseClass} {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.#{$baseClass}__action {
    flex-grow: 1;
    border-top: 1px solid $_colorAlto;
    border-right: 1px solid $_colorAlto;

    &:last-child {
        border-right: none;
    }
}

@media #{$_mediaMUp} {
    .#{$baseClass} {
        flex-direction: column;
    }

    .#{$baseClass}__action {
        border-left: 1px solid $_colorAlto;
        border-top: none;
        border-bottom: 1px solid $_colorAlto;
        border-right: none;

        &:last-child {
            border-bottom: none;
        }
    }
}

.#{$baseClass} {
    &.#{$baseClass}--break {
        flex-direction: row;
        width: 100%;

        .#{$baseClass}__action {
            flex-grow: 1;
            border-top: 1px solid $_colorAlto;
            border-left: none;
            border-right: 1px solid $_colorAlto;
            border-bottom: none;

            &:last-child {
                border-right: none;
            }
        }
    }
}

.#{$baseClass}--rebranding {
    button {
        border-color: $_colorAlto !important;
    }
}
