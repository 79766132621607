$baseClass: baseclass('NewsletterForm');

.#{$baseClass} {
    &--magazine {
        margin: $_stackL 0;

        .#{$baseClass}__body {
            flex-direction: column-reverse;
            align-items: stretch;
        }
        .#{$baseClass}__text {
            padding: 0;
            padding-top: 0;
            padding-bottom: $_insetM;
            width: 100%;
            max-width: auto;

        }
        .#{$baseClass}__form {
            padding-right: 0;
        }
    }
}

.#{$baseClass}__headline {
    display: inline-block;
    font-weight: bold;
    font-size: 18px;
    color: $_colorMineShaft;
    line-height: 30px;
}

.#{$baseClass}__body {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
}

.#{$baseClass}__form {
    padding-right: $_insetM;
    position: relative;
    flex-grow: 1;
}

.#{$baseClass}__text {
    padding-left: $_insetM;
    width: 40%;
    bottom: -5px;
    flex-shrink: 0;
    max-width: 270px;
    line-height: 24px;
}
