$baseClass: baseclass('GenericForm');

.#{$baseClass} {
    &__fields {
        display: flex;
        flex-direction: column;

        margin-left: -$_stackS;
        margin-right: -$_stackS;
    }

    &__input {
        flex-grow: 1;
        width: 100%;
        padding-left: $_insetS;
        padding-right: $_insetS;
        margin: $_stackS 0 !important;
    }

    &__buttons {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding-top: $_insetL;
    }
}

@media #{$_mediaMUp} {
    .#{$baseClass} {
        &__input {
            &--half {
                flex-grow: 0;
                flex-basis: 50%;
            }

            &--quarter {
                flex-grow: 0;
                flex-basis: 25%;
            }
        }
    }
}

@media #{$_mediaSDown} {
    .#{$baseClass}__fields {
        margin-left: -$_stackXS;
        margin-right: -$_stackXS;
    }

    .#{$baseClass}__input {
        padding-left: $_insetXS;
        padding-right: $_insetXS;
        margin: $_stackXS 0 !important;
    }
}
