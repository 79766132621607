@import 'scss/ion-rangeslider';
@import 'scss/ion-rangeslider-kfz';

$baseClass: baseclass('CatalogRangeFilter');

.#{$baseClass} {
    .irs-bar {
        background-color: $_colorBrand;
        height: 5px;
    }

    .irs-line {
        .irs-line-left {
            height: 5px;
        }

        .irs-line-mid {
            height: 5px;
        }

        .irs-line-right {
            height: 5px;
        }
    }

    .irs-slider {
        top: 20px;

        &::before {
            width: 16px;
            height: 16px;
            background-color: $_colorBrand;
            border-radius: 50%;
            left: 0;
        }
    }

    .irs-from,
    .irs-single,
    .irs-to,
    .irs-min,
    .irs-max {
        font-size: 13px;
        top: -10px;
        filter: drop-shadow(0 0 0 2px rgba(0, 0, 0, 0.5));
    }

    .irs-grid-pol {
        position: absolute;
        top: -22px;
        left: 0;
        width: 2px;
        height: 20px;
        background-color: #CACACA;

        &.small {
            display: none;
        }
    }

    .irs-to,
    .irs-from {
        color: $_colorMineShaft;
        border: solid 1px $_colorBoulder;
        background-color: $_colorAlabaster;

        &::after {
            display: none;
        }
    }

    .irs-grid-text {
        font-size: 12px;
    }
}

.#{$baseClass}__label {
    font-weight: bold;
    color: $_colorMineShaft;
    line-height: 20px;
    margin-bottom: 14px;
}
