// Rebranding

@mixin rebranding-base {
  font-family: 'GT America', sans-serif;
  letter-spacing: 0;
}

@mixin rebranding-header-base {
  @include rebranding-base;
  font-weight: 700;
}

@mixin rebranding-para-base {
  @include rebranding-base;
  font-weight: 400;
}

@mixin rebranding-link-base {
  @include rebranding-para-base;
  text-decoration: underline;
}

@mixin rebranding-h1 {
  @include rebranding-header-base;
  font-size: 32px;
  line-height: 40px;

}

@mixin rebranding-h2 {
  @include rebranding-header-base;
  font-size: 28px;
  line-height: 32px;
}

@mixin rebranding-h3 {
  @include rebranding-header-base;
  font-size: 24px;
  line-height: 28px;
}

@mixin rebranding-h4 {
  @include rebranding-header-base;
  font-size: 20px;
  line-height: 24px;
}

@mixin rebranding-h4--regular {
  @include rebranding-h4;
  font-weight: 400;
}

@mixin rebranding-h5 {
  @include rebranding-header-base;
  font-size: 18px;
  line-height: 22px;
}

@mixin rebranding-h5--regular {
  @include rebranding-h5;
  font-weight: 400;
}

@mixin rebranding-para-regular {
  @include rebranding-para-base;
  font-size: 16px;
  line-height: 24px;
}

@mixin rebranding-para-bold {
  @include rebranding-para-regular;
  font-weight: 700;
}

@mixin rebranding-para-small-regular {
  @include rebranding-para-base;
  font-size: 14px;
  line-height: 18px;
}

@mixin rebranding-para-small-bold {
  @include rebranding-para-small-regular;
  font-weight: 700;
}

@mixin rebranding-link-regular {
  @include rebranding-para-regular;
  @include rebranding-link-base;
}

@mixin rebranding-link-bold {
  @include rebranding-para-bold;
  @include rebranding-link-base;
}

@mixin rebranding-link-small-regular {
  @include rebranding-para-small-regular;
  @include rebranding-link-base;
}

@mixin rebranding-link-small-bold {
  @include rebranding-para-small-bold;
  @include rebranding-link-base;
}

@mixin rebranding-lead-regular {
  @include rebranding-para-regular;
  line-height: 26px;
}

@mixin rebranding-lead-bold {
  @include rebranding-para-bold;
  line-height: 26px;
}

@mixin rebranding-helper-text-regular {
  @include rebranding-base;
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
}

@mixin rebranding-helper-text-bold {
  @include rebranding-helper-text-regular;
  font-weight: 700;
}
