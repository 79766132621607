$baseClass: baseclass('ErrorTemplate');

.#{$baseClass} {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    background: $_colorWhite;

    &__main {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-content: center;
        padding: $_inlineM;
        flex-grow: 1;
        min-height: 50vh;
        margin-bottom: $_stackL;
    }
}

.#{$baseClass}__homeLink {
    max-width: 240px;
    margin: $_stackL auto 0;

    @media #{$_mediaSDown} {
        max-width: none;
        margin: $_stackL 0 0;
    }
}

.#{$baseClass}__searchWrapper {
    margin: $_stackL auto 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
    position: relative;
    max-width: 560px;
    width: 100%;
}

.#{$baseClass}__errorNotice {
    margin-top: $_stackL;
}

.#{$baseClass}__search {
    flex-grow: 1;
    margin: 0 $_stackM;
    position: relative;
    display: flex;
    align-items: center;

    @media #{$_mediaSDown} {
        margin: 0;
    }
}

.#{$baseClass}__searchInput {
    flex-grow: 1;
}

.#{$baseClass}__searchSubmit {
    margin: 0;
    position: absolute;
    right: 30px;
    bottom: 4px;

    font-weight: bold;
    color: $_colorWhite;
    cursor: pointer;

    @include blueFocus;
}

.#{$baseClass}__searchIcon {
    position: absolute;
    left: 5px;
    bottom: 4px;

    fill: $_colorWhite;
    pointer-events: none;
}

@media #{$_mediaLUp} {
    .#{$baseClass} {
        &__main {
            padding: $_inlineL;
        }
    }
}
