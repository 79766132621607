$baseClass: baseclass('OrderTile');

.#{$baseClass} {
    background: $_colorWhite;
    border: 1px solid $_colorAlto;

    & + & {
        margin-top: $_stackM;
    }

    &--open {
        .#{$baseClass}__toggle {
            transform: rotate(180deg);
        }
    }
}

.#{$baseClass}__head {
    border-radius: $_borderRadiusDefault;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    cursor: pointer;

    @include removeScrollbar;

    &--progress {
        .#{$baseClass}__section {
            &--state {
                &::before {
                    background: $_colorAlto;
                    width: 33.333%;
                }
            }
        }
    }

    &--progress2 {
        .#{$baseClass}__section {
            &--state {
                &::before {
                    background: $_colorAmber;
                    width: 66.666%;
                }
            }
        }
    }

    &--completed {
        .#{$baseClass}__section {
            &--state {
                &::before {
                    background: $_colorChristi;
                    width: 100%;
                }
            }
        }
    }
}

.#{$baseClass}__headWrapper {
    width: 100%;
    display: flex;
    align-items: center;
}

.#{$baseClass}__section {
    padding: 5px $_insetS;
    font-size: $_fontSizeS;

    & + & {
        border-left: 1px solid $_colorAlto;
    }

    &--expand {
        flex-grow: 1;
        border-left: none !important;
    }

    &--toggle {
        padding: 5px;
    }

    &--state {
        position: relative;
        flex-basis: 140px;
        border-right: 1px solid $_colorAlto;

        &::before {
            position: absolute;
            left: 0;
            top: 0;
            z-index: 0;
            content: '';
            height: 100%;

            transition: width $_timeM ease-in-out;
        }
    }
}

.#{$baseClass}__toggle {
    transform-origin: center;
    transition: transform $_timeS ease-in-out;
}

.#{$baseClass}__label {
    font-weight: bold;
    color: $_colorRollingStone;
}

.#{$baseClass}__label,
.#{$baseClass}__value {
    position: relative;
    z-index: 1;

    line-height: 19px;
}

.#{$baseClass}__value {
    white-space: nowrap;

    &--amount {
        min-width: 70px;
    }
}

.#{$baseClass}__body {
    max-height: 0;
    overflow: hidden;
    transition: max-height $_timeS ease-in-out;
}

.#{$baseClass}__bodyInner {
    border-top: 1px solid $_colorAlto;
    background: $_colorGallery;
    padding: $_insetS;
}

.#{$baseClass}__bodyContent {
    border: 1px solid $_colorAlto;
}

.#{$baseClass}__product {
    & + & {
        border-top: 1px solid $_colorAlto;
    }
}

.#{$baseClass}__summary {
    margin: $_stackM 0;
}

.#{$baseClass}__summaryHeadline {
    margin-bottom: $_stackS;

    font-size: $_fontSizeM;
    font-weight: bold;
    line-height: 22px;
}

.#{$baseClass}__summaryInner {
    display: flex;
    flex-wrap: wrap;
    position: relative;
    padding: $_insetS $_insetM;
    background: $_colorWhite;

    &--amounts {
        justify-content: flex-end;
    }

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 7px;
        background: $_colorBrand;
    }
}

.#{$baseClass}__payment,
.#{$baseClass}__address {
    padding: $_insetS $_insetL $_insetS $_insetS;
}

.#{$baseClass}__downloadInvoice {
    flex-grow: initial;
}


@media #{$_mediaMUp} {
    .#{$baseClass}__section {
        padding: 5px $_insetM;

        &--toggle {
            padding: 5px;
        }
    }
}
