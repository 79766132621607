$baseClass: baseclass('CatalogFilterSection');

.#{$baseClass} {
    .facets-wrapper {
        padding: $_insetL;
        border-top: 1px solid $_colorAlt;

        @media #{$_mediaMDown} {
            padding: $_insetS;
            background-color: $_colorWhite;
            position: fixed;
            bottom: -20%;
            left: 0;
            right: 0;
            z-index: 2;
            transition: bottom $_timeM ease-in;
        }

        &.no-top-border {
            border-top: none;
            padding-top: 12px;
        }
    }
}

.#{$baseClass}__mobileControllers {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: stretch;
    padding-bottom: 12px;
    margin-top: -$_stackS;

    @include u-hidden-lg-up;
}

.#{$baseClass}__sectionContainer {
    background-color: $_colorWildSand;

    @media #{$_mediaLDown} {
        height: 100%;
    }

    @media #{$_mediaMDown} {
        background-color: $_colorGallery;
    }
}

.#{$baseClass}__accordion {
    overflow: hidden;
    transition: max-height $_timeS ease-in-out, opacity $_timeS ease-in-out;
    max-height: 0;
    opacity: 0;
    padding-bottom: 20px;

    &--expanded {
        opacity: 1;
        max-height: none;
        overflow: visible;
    }

    @media #{$_mediaMDown} {
        max-height: none;
        opacity: 1;
        padding-bottom: $_stackXS;
    }
}

.#{$baseClass}__triggerFrame {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 10px 0 20px;
}

.#{$baseClass}__trigger {
    padding: 11px $_insetL;
    background-color: $_colorWhite;
    border: solid 1px $_colorAlt;
    border-radius: $_borderRadiusDefault;
    cursor: pointer;
    color: $_colorRollingStone;
    text-transform: uppercase;
    font-weight: bold;

    &:hover {
        background-color: $_colorAlabaster;
    }

    @media #{$_mediaMDown} {
        @include u-hidden-md-down;
    }
}

.#{$baseClass}__suggestion {
    padding: $_insetM 0;
    font-size: 14px;
}

.#{$baseClass}__suggestionLink {
    font-weight: bold;
    color: $_colorSecondary;
}

.#{$baseClass}--slidedIn {
    .#{$baseClass}__rowsContainer {
        padding-bottom: 30px;
    }

    .#{$baseClass}__rowsContainer.#{$baseClass}__extraPadding {
        padding-bottom: 67px;
    }

    .facets-wrapper {
        @media #{$_mediaMDown} {
            bottom: 0;
        }
    }
}

.#{$baseClass}__mobileFacets {
    @include u-hidden-lg-up;

    padding: $_insetM;
    background-color: $_colorWildSand;
    border-bottom: 1px solid $_colorAlt;
    margin-bottom: $_stackXS;

    .#{$baseClass}__mobileFacetsTitle {
        font-size: 14px;
        color: $_colorRollingStone;
        margin-bottom: $_stackXS;
    }
}

// PLP-Split-Style
.#{$baseClass}__sectionWrapper {
    padding-top: 20px;
    position: relative;

    &--gray {
        padding: 20px 0 0;
        margin: 0;

        background: $_colorWildSand;

        @media #{$_mediaMDown} {
            margin: 0 #{-$_stackM};
            padding: 20px $_insetM 0;
        }

        @media #{$_mediaSDown} {
            margin: 0 #{-$_stackS};
            padding: 20px $_insetS 0;
        }

        &::before {
            display: block;
            content: ' ';
            background: $_colorWildSand;
            width: 100%;
            left: -100%;
            position: absolute;
            height: 100%;
            top: 0;
        }

        &::after {
            display: block;
            content: ' ';
            background: $_colorWildSand;
            width: 100%;
            left: 100%;
            position: absolute;
            height: 100%;
            top: 0;
        }
    }
}