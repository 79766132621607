$baseClass: baseclass('CatalogList');

.#{$baseClass} {
    margin: 10px 0;
    background: $_colorWhite;

    & & {
        &:last-child {
            margin-bottom: 0;
        }
    }
}

.#{$baseClass}__headline {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    svg {
        margin-right: $_stackXS;
        flex-shrink: 0;
        fill: $_colorRollingStone;
        transition: transform $_timeS ease-in-out;
        cursor: pointer;
        width: 16px;
        height: 16px;
    }
}

.#{$baseClass}__toggle {
    display: flex;
    align-items: center;
    cursor: pointer;

    &:hover {
        .#{$baseClass}__headlineIcon {
            fill: $_colorBrand;
        }

        .#{$baseClass}__headlineText {
            color: $_colorSecondary;
        }
    }
}

.#{$baseClass}__headlineIcon {
    & + .#{$baseClass}__headlineText {
        margin-left: 0;
    }
}

.#{$baseClass}__headlineText {
    margin-left: 28px;

    font-size: 14px;
    line-height: 24px;
    color: $_colorBlackSecondary;
    text-overflow: ellipsis;
    overflow: hidden;
}

.#{$baseClass}__body {
    max-height: 0;
    overflow: hidden;
}

.#{$baseClass}__bodyInner {
    overflow: hidden;
}

.#{$baseClass}__item {
    margin: 16px 0 0 16px;

    &--hidden {
        display: none;
    }
}

.#{$baseClass}__link {
    font-size: 14px;
    color: $_colorBlackSecondary;
    line-height: 20px;

    &:hover {
        svg {
            fill: $_colorSecondary;
        }
    }
}

.#{$baseClass}__link--active {
    color: $_colorSecondary;
}

.#{$baseClass}--open,
.#{$baseClass}--active {
    & > .#{$baseClass}__headline {
        .#{$baseClass}__headlineText {
            color: $_colorSecondary;
        }

        .#{$baseClass}__toggle {
            svg {
                fill: $_colorBrand;
            }
        }
    }
}


.#{$baseClass}--open {
    & > .#{$baseClass}__headline {
        .#{$baseClass}__toggle {
            svg {
                transform: rotate(-135deg);
                transform-origin: center;
            }
        }
    }

    & > .#{$baseClass}__body {
        max-height: none;
    }
}
