$baseClass: baseclass('CrossSelling');

.#{$baseClass} {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin: $_stackM 0;

    &[data-enabled=''],
    &[data-enabled='false'] {
        display: none;
    }

    .hidden {
        display: none;
    }
}

.#{$baseClass}__crossContainer {
    justify-content: flex-start;
    border: 1px solid $_colorAlto;
    border-radius: $_borderRadiusDefault;
    display: none;
    width: 100%;

    @media #{$_mediaLDown} {
        flex-wrap: wrap;
    }
}

.#{$baseClass}__crossellingItem {
    background: $_colorWhite;
    margin-bottom: $_stackM;
    padding: $_insetM;
    display: flex;
    justify-content: space-between;
    flex-flow: column;
    width: 25%;

    @media #{$_mediaLDown} {
        width: 50%;
    }

    @media #{$_mediaMDown} {
        width: 100%;
        padding: $_insetS;
    }
}

.#{$baseClass}__content {
    display: flex;
}

.#{$baseClass}__titleContainer {
    flex-basis: 100%;
    margin-bottom: $_stackM;
    display: flex;
    align-items: center;
}

.#{$baseClass}__title {
    margin-right: $_stackS;
    font-size: 14px;
    line-height: 18px;
    color: $_colorNero;
    font-weight: bold;
}

.#{$baseClass}__image {
    display: flex;
    min-height: 80px;
    align-items: center;
    margin: 0 $_stackM $_stackM;
    justify-content: center;

    img {
        display: block;
        max-height: 80px;
    }

    @media #{$_mediaMDown} {
        display: block;
        float: left;
    }
}

.#{$baseClass}__addToCartButton {
    flex-grow: 1;
    margin-right: $_stackS;
}

.#{$baseClass}__price {
    color: $_colorChathamsBlue;
    font-weight: bold;
    font-size: 24px;
    line-height: 28px;
    margin-bottom: $_stackM;
    min-height: 95px;

    .#{$baseClass}__name {
        width: 100%;
        display: none;
    }

    @media #{$_mediaMDown} {
        min-height: auto;

        .#{$baseClass}__name {
            display: block;
        }
    }
}

.#{$baseClass}__priceTax {
    font-weight: normal;
    color: $_colorRollingStone;
    font-size: 12px;
    line-height: 15px;
}

.#{$baseClass}__name {
    margin-bottom: 4px;
    font-weight: bold;
    color: $_colorMineShaft;
    font-size: $_fontSizeM;
    line-height: 20px;
    text-overflow: ellipsis;
    overflow: hidden;
    min-height: 40px;

    @media #{$_mediaMDown} {
        display: none;
    }
}

.#{$baseClass}__buttons {
    display: flex;
}

.#{$baseClass}.loaded {
    .#{$baseClass}__loading {
        display: none;
    }

    .#{$baseClass}__crossContainer {
        display: flex;
    }
}

.#{$baseClass}__loading {
    animation: linearRotationKeyframes $_timeL ease-in-out infinite;
    margin: $_stackXL auto;

    circle {
      stroke: $_colorAlto;
    }
    path {
      stroke: $_colorTangerine;
    }

    &--autoteile24 path  {
      stroke: $_colorChathamsBlue;
    }
}
