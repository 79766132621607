
$baseClass: baseclass('CarisGarage');

.#{$baseClass}__text {
    padding: $_insetM $_insetM 0;
    font-size: 14px;
    line-height: 20px;
}

.#{$baseClass}__buttons {
    padding: $_insetM;
}
