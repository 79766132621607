@mixin blueFocus() {
    &:focus {
        outline: none;

        @include secondaryFocusBoxShadow;
    }

    &:active {
        outline: none;
        box-shadow: none;
    }
}

@mixin orangeFocus() {
    &:focus {
        outline: none;

        @include brandFocusBoxShadow;
    }

    &:active {
        outline: none;
        box-shadow: none;
    }
}

@mixin whiteFocus() {
    &:focus {
        outline: none;

        @include whiteFocusBoxShadow;
    }

    &:active {
        outline: none;
        box-shadow: none;
    }
}

@mixin secondaryFocusBoxShadow($inset: 'inset') {
    box-shadow: #{$inset} -1px 0 0 2px $_colorSecondary, #{$inset} 1px 0 0 2px $_colorSecondary, #{$inset} 0 -1px 0 2px $_colorSecondary, #{$inset} 0 1px 0 2px $_colorSecondary;
}

@mixin brandFocusBoxShadow($inset: 'inset') {
    box-shadow: #{$inset} -1px 0 0 2px $_colorBrand, #{$inset} 1px 0 0 2px $_colorBrand, #{$inset} 0 -1px 0 2px $_colorBrand, #{$inset} 0 1px 0 2px $_colorBrand;
}

@mixin whiteFocusBoxShadow($inset: 'inset') {
    box-shadow: #{$inset} -1px 0 0 2px $_colorWhite, #{$inset} 1px 0 0 2px $_colorWhite, #{$inset} 0 -1px 0 2px $_colorWhite, #{$inset} 0 1px 0 2px $_colorWhite;
}

@mixin fancyScroll() {
    overflow-y: auto;
    overflow-x: hidden;

    &::-webkit-scrollbar {
        width: 4px !important;
    }

    &::-webkit-scrollbar-thumb {
        background-color: $_colorBrand;
        outline: 1px solid $_colorAlto;
    }

    &::-webkit-scrollbar-track {
        background-color: $_colorAlto;
    }
}
