$baseClass: baseclass("CarpageManufacturerSeries");

.#{$baseClass} {
  margin-top: $_stackL;
  width: 100%;
}

.#{$baseClass}__sectionTitle {
  color: $_colorChathamsBlue;
  font-size: 20px;
  margin-bottom: $_stackS;
  font-weight: 700;
  line-height: 20px;
}

.#{$baseClass}__container {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: flex-start;
  margin: $_stackM -#{$_stackS};

  @media #{$_mediaMDown} {
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: stretch;
  }
}

.#{$baseClass}__item {
  padding: 0 $_insetS;
  flex-grow: 1;
  width: 20%;
  margin-bottom: $_stackL;
  max-width: 20%;

  @media #{$_mediaMDown} {
    border-top: solid 1px $_colorAlt;
    max-width: 100%;
    width: 100%;
    margin-bottom: 0;
    padding: 0;

    &:last-of-type {
      border-bottom: solid 1px $_colorAlt;
    }
  }
}
