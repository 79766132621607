$baseClass: baseclass('CheckoutStep');

.#{$baseClass} {
  background: $_colorWhite;

  &--next {
    .#{$baseClass}__header {
      color: $_colorRollingStone;

      &::before {
        background: $_colorAlto;
      }
    }
  }

  &--done {
    .#{$baseClass}__header {
      &::before {
        background: $_colorChathamsBlue;
      }

      color: $_colorChathamsBlue;
    }
  }

  &--selected {
    .#{$baseClass}__header {
      &::before {
        background: $_colorBrand;
      }

      color: $_colorBrand;
    }
  }

  &--error {
    .#{$baseClass}__header {
      color: $_colorRedRibbon;

      &::before {
        background: $_colorRedRibbon;
      }
    }
  }
}

.#{$baseClass}__header {
  position: relative;
  display: flex;
  align-items: center;
  padding: $_insetM 12px #{$_insetM - 4px} 24px;

  font-weight: bold;

  transition: color $_timeS ease-in-out;

  &::before {
    content: '';
    position: absolute;
    width: calc(100% + 2px);
    height: 5px;
    top: -1px;
    left: -1px;

    transition: background $_timeS ease-in-out;
  }
}

.#{$baseClass}__number {
  padding-right: 12px;

  font-size: 30px;
  line-height: 32px;

  &--1 {
    margin-left: -3px;
  }
}

.#{$baseClass}__title {
  line-height: 24px;
}

.#{$baseClass}__body {
  opacity: 0;
  overflow: hidden;

  transition: height $_timeS ease-in-out $_timeS, opacity $_timeS ease-in-out;

  &--show {
    opacity: 1;
  }
}

.#{$baseClass}__content {
  padding: $_insetS $_insetM $_insetM;
}

.#{$baseClass}__buttons {
  display: flex;
  width: 100%;
  margin: $_stackM 0 $_stackS;
}

.#{$baseClass}__button {
  &--cta {
    flex-grow: 1;
  }

  & + & {
    margin-left: $_stackS;
  }
}

@media #{$_mediaMUp} {
  .#{$baseClass}__content {
    padding: 6px 24px 24px;
  }

  .#{$baseClass}__title {
    font-size: 18px;
  }
}

.#{$baseClass}--rebranding {
  .#{$baseClass}__header {
    color: $_colorRebrandingManatee;

    &::before {
      background: $_colorRebrandingSecondary;
    }
  }

  .#{$baseClass}__number {
    @include rebranding-h1;
  }

  .#{$baseClass}__title {
    @include rebranding-para-bold;
  }

  &.#{$baseClass}--selected {
    .#{$baseClass}__header {
      color: $_colorRebrandingPrimary;
    }
  }
}
