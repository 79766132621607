$baseClass: baseclass('LoginContainer');

.#{$baseClass} {
  width: 100%;
  margin: $_stackXL 0;
  background-color: $_colorWhite;
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  @media #{$_mediaMDown} {
    margin-top: $_stackL;
    margin-bottom: $_stackL;
  }

  @media #{$_mediaSDown} {
    margin-top: $_stackM;
    margin-bottom: $_stackM;
  }

  &--single {
    flex-direction: column;

    .#{$baseClass}__form {
      width: calc(100% - 16px);
    }

    .#{$baseClass}__titleWrapper,
    .#{$baseClass}__forms {
      justify-content: center;
    }
  }
}


.#{$baseClass}__titleWrapper {
  display: flex;
  margin: 0 -8px;
  flex-grow: 1;
}

.#{$baseClass}__titleInner {
  min-width: 466px;
  display: flex;
  flex-grow: 1;
  justify-content: center;
  margin: 0 $_stackS;
  padding: 0 $_insetL;

  @media #{$_mediaMDown} {
    padding: 0 $_insetM;
  }

  @media #{$_mediaSDown} {
    min-width: auto;
  }
}

.#{$baseClass}__title {
  margin: $_stackS 0;
  padding-bottom: $_insetL;
  width: 100%;
  max-width: 400px;
  color: $_colorChathamsBlue;
  font-weight: bold;
  font-size: 24px;
  line-height: 26px;

  @media #{$_mediaMDown} {
    padding-bottom: $_insetL;
  }

  @media #{$_mediaSDown} {
    margin: 0;
    padding-bottom: 0;
  }
}

.#{$baseClass}__forms {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -8px;
  flex-grow: 1;
}

.#{$baseClass}__form {
  width: calc(50% - 16px);
  min-width: 350px;
  display: flex;
  flex-grow: 1;
  justify-content: center;
  border: 1px solid $_colorAlto;
  padding: $_insetL;
  margin: $_stackS;

  @media #{$_mediaMDown} {
    padding: $_insetL $_insetM;
  }

  @media #{$_mediaSDown} {
    min-width: calc(100% - 16px);
    padding: $_insetS $_insetM $_insetM;
  }
}

.#{$baseClass}--rebranding {
  .#{$baseClass}__title {
    @include rebranding-h3;
    color: $_colorRebrandingSecondary;
  }

  .#{$baseClass}__form {
    border-color: $_colorRebrandingGallery;
  }
}
