$baseClass: baseclass('ProductList');

.#{$baseClass} {
    width: 100%;
    border: 1px solid $_colorAlto;
    background: $_colorWhite;

    & + & {
        margin-top: $_stackM;
    }
}

.#{$baseClass}__vehicle {
    position: relative;
    border-bottom: 1px solid $_colorBlackDecorative;

    &::before {
        content: '';
        position: absolute;
        width: calc(100% + 1px);
        height: 5px;
        top: -1px;
        left: -1px;
        background: $_colorBrand;
    }
}

.#{$baseClass}__vehicleIcon {
    flex-shrink: 0;
    fill: $_colorRollingStone;
}

.#{$baseClass}__vehicleInner {
    display: flex;
    align-items: center;
    padding: 10px $_insetM 5px;
    overflow: auto;
    -webkit-overflow-scrolling: touch;

    @include removeScrollbar;

    &--showScrollButtons {
        .#{$baseClass}__title {
            padding-right: 84px;
        }

        .#{$baseClass}__buttons {
            display: flex;
        }
    }
}

.#{$baseClass}__title {
    padding: 0 $_insetM;

    font-weight: bold;
    font-size: $_fontSizeS;
    color: $_colorRollingStone;

    line-height: 20px;
    white-space: nowrap;
}

.#{$baseClass}__product {
    width: 100%;

    & + & {
        border-top: 1px solid $_colorBlackDecorative;
    }
}

.#{$baseClass}__buttons {
    position: absolute;
    transform: translate3d(0, 0, 0);
    right: 0;
    bottom: 0;

    display: none;
    z-index: 1;
}

.#{$baseClass}__button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 36px;
    padding: 0;
    margin: 0;
    border-left: 1px solid $_colorAlto;
    border-top: 1px solid $_colorAlto;

    background: $_colorWildSand;
    cursor: pointer;

    &--disabled {
        cursor: default;

        .#{$baseClass}__icon {
            opacity: 0.3;
        }
    }
}

.#{$baseClass}__icon {
    fill: $_colorBrand;

    transition: opacity $_timeS;
}

@media #{$_mediaMDown} {
    .#{$baseClass}__header {
        display: none;
    }
}

@media print {
    .#{$baseClass}__vehicle {
        &::before {
            -webkit-print-color-adjust: exact;
            color-adjust: exact;
        }
    }

    .#{$baseClass}__buttons {
        display: none !important;
    }
}

.#{$baseClass}--rebranding {
    .#{$baseClass}__vehicle {
        &::before {
            background: $_colorRebrandingSecondary;
        }
    }

    .#{$baseClass}__title {
        color: $_colorRebrandingAbbey;
        @include rebranding-helper-text-bold;
    }
}
