$baseClass: baseclass('Dummy');

.#{$baseClass} {
    position: relative;
    width: 100%;
    height: 100%;
    background: $_colorAlto;
    border: 1px solid $_colorRollingStone;

    display: flex;
    justify-content: center;
    align-items: center;
}

.#{$baseClass}__label {
    font-weight: bold;
    color: $_colorMineShaft;
    text-transform: uppercase;
}
