$baseClass: baseclass('CarisCockpit');

.#{$baseClass} {
    position: relative;
    background: $_colorWhite;
    box-shadow: 0 0 24px 0 rgba(51, 51, 51, 0.3);
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    height: 434px;
    max-height: calc(100vh - 206px);

    @include fancyScroll;

    @media #{$_mediaMDown} {
        height: 200vh;
        max-height: 100vh;
        box-shadow: none;
    }

    &[data-mode="selection"] {
        .#{$baseClass}__views {
            transform: translate(0, 0);
        }

        .caris-tab:first-of-type {
            border-top: 4px solid $_colorBrand;
            font-weight: bold;
            color: $_colorBrand;
            background: white;
            border-bottom: 1px solid transparent;
        }
    }

    &[data-mode="entry"] {
        .#{$baseClass}__views {
            transform: translate(0%, 0);
        }

        .caris-tab:first-of-type {
            border-top: 4px solid $_colorBrand;
            font-weight: bold;
            color: $_colorBrand;
            background: white;
            border-bottom: 1px solid transparent;
        }
    }

    &[data-mode="garage"] {
        .#{$baseClass}__views {
            transform: translate(-50%, 0);
        }

        .caris-tabs-mobile {
            transform: translate(100%, 0);
        }

        .caris-tab:nth-of-type(2) {
            border-top: 4px solid $_colorBrand;
            font-weight: bold;
            color: $_colorBrand;
            background: white;
            border-bottom: 1px solid transparent;
        }
    }

    &--animate {
        .#{$baseClass}__views {
            transition: transform $_timeS ease-in-out;
        }
    }

    .caris-tabs {
        display: flex;
        text-align: center;
        background: $_colorWhite;

        .caris-tab {
            flex-basis: 50%;
            height: 44px;
            line-height: 41px;
            background: $_colorWildSand;
            border-top: 4px solid transparent;
            border-bottom: 1px solid $_colorAlt;
            color: $_colorNero;
            font-size: 14px;
            transition: all $_timeS ease-in-out;
            cursor: pointer;
            text-transform: uppercase;

            &:first-child {
                border-right: 1px solid $_colorAlt;
            }
        }

        @media #{$_mediaMDown} {
            display: none;
        }
    }

    .caris-tabs-mobile {
        position: absolute;
        width: 50%;
        top: 56px;
        left: 0;
        transition: transform $_timeS ease-in-out;
        z-index: 2;
        display: none;

        @media #{$_mediaMDown} {
            display: flex;
        }
    }

    .partial-garage {
        min-height: 100px;
        position: relative;
    }
}

.#{$baseClass}__views {
    display: flex;
    flex-direction: row;
    overflow: hidden;
    width: 200%;
    position: relative;
}

.#{$baseClass}__view {
    display: flex;
    flex-direction: column;
    width: 50%;
}

.#{$baseClass}__viewContent {
    display: flex;
    flex-direction: column;
    position: relative;
    flex-grow: 1;
    overflow-y: auto;
    overflow-x: hidden;
    height: 390px;
    width: 100%;

    @include fancyScroll;

    @media #{$_mediaMDown} {
        height: 100vh;
        max-height: calc(100vh - 204px);
        margin-top: 44px;
    }

    @media #{$_mediaSDown} {
        max-height: calc(100vh - 174px);
    }

    &--garage {
        max-height: calc(100% - 73px);

        @media #{$_mediaMDown} {
            max-height: calc(100vh - 247px);
            margin-bottom: 73px;
        }
    }
}

.iosSafari .#{$baseClass}__viewContent {
    &--garage {
        @media #{$_mediaMDown} {
            max-height: calc(100vh - 321px);
        }

        @media #{$_mediaSDown} {
            max-height: calc(100vh - 291px);
        }
    }
}

.#{$baseClass}__fixed-button {
    position: absolute;
    bottom: 0;
    width: 50%;
    left: 50%;
    background: $_colorWhite;
    z-index: 10;
    border-top: 1px solid $_colorAlto;
    padding: $_insetM;
}

.iosSafari .#{$baseClass}__fixed-button {
    bottom: 44px;
}

.#{$baseClass}__headline {
    padding: $_insetM $_insetM $_insetS;
    font-weight: bold;
    color: $_colorSecondary;

    & + .#{$baseClass}__button {
        padding-top: 0;
        margin-top: -#{$_stackXS};
    }
}

.#{$baseClass}__divider {
    margin: 0 $_stackM;
    text-align: center;

    font-size: $_fontSizeS;
    color: $_colorRollingStone;
    line-height: 20px;

    & + .#{$baseClass}__label {
        margin-top: $_stackS;
        margin-bottom: -$_stackS;
    }
}

.#{$baseClass}__label {
    margin: 6px 0;
    color: $_colorRollingStone;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    min-height: 16px;
    text-align: center;
}

.#{$baseClass}__help {
    margin-left: 12px;
    width: 16px;
    height: 16px;
    cursor: pointer;
}


.#{$baseClass}__header {
    flex-shrink: 0;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: $_insetM;
    background: $_colorSecondary;
}

.#{$baseClass}__headerContent {
    flex-grow: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    white-space: nowrap;
    overflow: hidden;
    padding: $_insetM $_insetM $_insetM 14px;
}

.#{$baseClass}__headerIcon {
    fill: $_colorWhite;
    max-width: 28px;
}

.#{$baseClass}__headerText {
    text-overflow: ellipsis;
    color: $_colorWhite;
    font-size: 18px;
    line-height: 22px;
    font-weight: bold;
}

.#{$baseClass}__headerClose {
    fill: $_colorWhite;
    cursor: pointer;
}


.#{$baseClass}__filterTriggers {
    width: 100%;
    padding: $_insetXS $_insetM;
}

.#{$baseClass}__licenseSection {
    .#{$baseClass}__label {
        margin: 6px 0;
    }
}

.#{$baseClass}__licensePlateWrapper {
    width: 100%;
    padding: $_insetXS $_insetM 4px;
    position: relative;
    margin-top: $_stackS;
}

.#{$baseClass}__licensePlateEU {
    background-color: $_colorChathamsBlue;
    border-top-left-radius: $_borderRadiusDefault;
    border-bottom-left-radius: $_borderRadiusDefault;
    position: absolute;
    top: 4px;
    left: 16px;
    min-height: 48px;
    min-width: 30px;
    z-index: 1;
}

.#{$baseClass}__licensePlate {
    margin-left: 28px;
    padding-top: 0;
}

.#{$baseClass}__euIcon {
    display: block;
    margin: $_stackS auto $_stackXS;
}

.#{$baseClass}__countryCode {
    display: block;
    color: $_colorWhite;
    text-align: center;
    font-weight: bold;
    font-size: $_fontSizeS;
}

.#{$baseClass}__savedText {
    color: $_colorRollingStone;
    font-weight: bold;
    font-size: 14px;
}

.#{$baseClass}__saveFormWrap + #partial-garage:not(:empty) {
    padding-top: $_insetS;
    border-top: 1px solid $_colorAlto;
}


.#{$baseClass}__form {
    width: 100%;
    position: relative;
}

.#{$baseClass}__fields {
    display: flex;
    padding: 0 $_insetS;
}

.#{$baseClass}__field {
    width: 50%;
    padding: 0 $_insetS 0;

    &--full {
        width: 100%;
    }
}

.#{$baseClass}__explanationText {
    line-height: 20px;
    font-size: 14px;
}

.#{$baseClass}__explanationImage {
    margin-top: $_stackM;
    width: 100%;
    max-width: 440px;
}

.#{$baseClass}__trigger {
    & + & {
        margin-top: 12px;
    }
}

.#{$baseClass}__button {
    padding: $_insetS;
    display: flex;
    flex-wrap: wrap;
    min-height: 72px;
}

.#{$baseClass}__accountButton {
    flex-grow: 1;
    flex-shrink: 0;
    margin: $_insetS;
}

.#{$baseClass}__submit {
    margin: $_insetS;
    display: flex;
    flex-grow: 1;
    flex-shrink: 0;
}

.#{$baseClass}__loader {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: center;
}

.#{$baseClass}__loaderIcon {
    animation: linearRotationKeyframes $_timeL ease-in-out infinite;
    transform-origin: center;
    width: 40px;
    height: 40px;
    fill: $_colorBrand;
}

@media #{$_mediaLUp} {
    .#{$baseClass} {
        border-bottom-left-radius: $_borderRadiusDefault;
        border-bottom-right-radius: $_borderRadiusDefault;
    }

    .#{$baseClass}__header {
        display: none;
    }
}
