$baseClass: baseclass('CatalogCheckboxFilter');

.#{$baseClass} {
    height: 100%;
    margin: 0 auto;
    background-color: $_colorWhite;
    border-radius: $_borderRadiusDefault;
    border: solid 1px $_colorAlt;

    &__frame {
        cursor: pointer;
    }

    &--disabled {
        .#{$baseClass}__frame {
            transition: none;
            filter: grayscale(100);
            cursor: initial;
            border-color: $_colorAlt;
            color: $_colorAlt;

            &__position {
                cursor: initial;
            }

            img {
                opacity: 0.35;
            }

            &:hover {
                border-color: $_colorAlt;

                .#{$baseClass}__inputBox {
                    border-color: $_colorAlt;
                }
            }

            .#{$baseClass}__inputBox {
                transition: none;
                border-color: $_colorAlt;
            }
        }
    }

    &--active {
        border-color: $_colorBrand;

        .#{$baseClass}__inputBox {
            border-color: $_colorBrand;
        }
    }

    &:hover {
        border-color: $_colorBrand;

        .#{$baseClass}__inputBox {
            border-color: $_colorBrand;
        }
    }

    @media #{$_mediaLDown} {
        border-radius: 0;
    }
}

.#{$baseClass}__frame {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    height: 100%;
    transition: border-color $_timeXS ease-in-out;
}

.#{$baseClass}__inputBox {
    border-color: $_colorAlt;
    border-right-style: solid;
    border-width: 1px;
    flex: 0 0;
    transition: border-color $_timeXS ease-in-out;
    position: relative;
    padding: $_insetL;

    &--hidden {
        display: none;
    }

    .#{$baseClass}__checkbox {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%);
        margin-top: -#{$_stackM};
        margin-left: #{$_stackS};
    }
}

.#{$baseClass}__content {
    position: relative;
    flex: 1 1;
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;
    padding: $_insetXS 0;
    min-height: 58px;
}

.#{$baseClass}__visuals {
    margin: 0 $_insetS;
    flex: 0 0;

    &--icon {
        margin-right: -$_stackS;
    }
}

.#{$baseClass}__text {
    font-size: $_fontSizeM;
    line-height: 1.2;
    padding: 0 $_insetXS 0 $_insetM;
}

.#{$baseClass}__title {
    font-size: $_fontSizeM;
    color: $_colorMineShaft;
    white-space: nowrap;
}

.#{$baseClass}__subtitle {
    color: $_colorRollingStone;
    font-size: 14px;
}
