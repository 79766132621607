$baseClass: baseclass('InfoDialog');

.#{$baseClass}__content {
    line-height: 20px;
    font-size: 14px;
}

.#{$baseClass}__fineprint {
    position: relative;
    margin-top: $_stackM;
    padding-left: #{$_insetS};

    font-size: $_fontSizeXS;
    line-height: 16px;

    &::before {
        content: '*';
        display: block;
        position: absolute;
        top: 1px;
        left: 0;
        font-size: $_fontSizeS;
    }
}

.#{$baseClass}__wysiwyg {
    b,
    strong {
        font-weight: bold;
    }

    u {
        text-decoration: underline;
    }

    em,
    i {
        font-style: italic;
    }
}
