$baseClass: baseclass('PageTitle');

.#{$baseClass} {
    &:not(.#{$baseClass}--embedded) {
        @include pageWidth;

        padding-top: 8px;
        padding-bottom: 4px;
    }

    &--embedded {
        flex-shrink: 0;
    }

    &--car-title {
        flex-shrink: initial;
        padding: 0 320px 0 0;

        @media #{$_mediaMDown} {
            margin-bottom: $_insetS;
            padding-right: 0;
        }
    }
}

.#{$baseClass}__inner {
    width: 100%;
    display: flex;
    align-items: flex-end;
}

.#{$baseClass}__title {
    padding-right: $_insetS;
    transform: translate(0, 8%);

    font-size: 18px;
    font-weight: bold;
    color: $_colorSecondary;

    &.#{$baseClass}__title--reverse {
        color: $_colorBrand;
    }
}

.#{$baseClass}__subtitle {
    display: none;
}

@media #{$_mediaMUp} {
    .#{$baseClass} {
        &:not(.#{$baseClass}--embedded) {
            padding-top: 18px;
            padding-bottom: 18px;
        }
    }

    .#{$baseClass}__title {
        font-size: 24px;
    }

    .#{$baseClass}__subtitle {
        display: block;

        font-size: 14px;
        color: $_colorNero;
    }
}

@media #{$_mediaLUp} {
    .#{$baseClass} {
        &:not(.#{$baseClass}--embedded) {
            padding-top: 28px;
            padding-bottom: 20px;
        }
    }

    .#{$baseClass}__title {
        font-size: 28px;
    }
}
