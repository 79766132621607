$baseClass: baseclass('ProductCard');

.#{$baseClass} {
    margin-bottom: 20px;
    background-color: $_colorWhite;
    transition: box-shadow $_timeS ease-in-out;
    border: 1px solid $_colorAlt;
    border-radius: $_borderRadiusDefault;

    &--noBrandLogo {
        .#{$baseClass}__images {
            .#{$baseClass}__imageWrapper {
                .#{$baseClass}__brandName {
                    display: inline;
                }
            }
        }
    }
}

.#{$baseClass}--hover {
    .#{$baseClass}__headline,
    .#{$baseClass}__subheadline {
        color: $_colorChathamsBlue;
    }

    .#{$baseClass}__headline--com,
    .#{$baseClass}__subheadline--com {
        color: $_colorBrand;
    }

    .#{$baseClass}__imageLink {
        transform: scale(1.06);
    }
}

.#{$baseClass}__titleWrap {
    &--mdDown {
        @include u-hidden-lg-up;

        padding: $_insetM $_insetM 0;
    }

    &--lgUp {
        @include u-hidden-md-down;
    }
}

.#{$baseClass}__inner {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: stretch;

    @media #{$_mediaMDown} {
        display: block;
    }
}

.#{$baseClass}__innerImages {
    display: none;
    float: left;
    margin-right: $_stackM;

    @media #{$_mediaMDown} {
        display: block;
    }
}

.#{$baseClass}__section {
    padding: $_insetM;

    &--images {
        flex: 1 0 15%;
        order: 1;

        @media #{$_mediaMDown} {
            display: none;
        }
    }

    &--product {
        position: relative;
        padding: $_insetM 0;
        flex: 0.9 0 25%;
        order: 2;

        @media #{$_mediaMDown} {
            padding: 0 $_insetM;
        }
    }

    &--price {
        flex: 0.5 0 20%;
        order: 3;
    }

    &--carSelection {
        border-top: solid 1px $_colorAlt;
        padding: 0 $_insetM;
        order: 4;
        flex-grow: 1;
        flex-basis: 100%;
    }
}

.#{$baseClass}__imageLink {
    display: flex;
    align-items: center;
    width: 100%;
    height: 108px;

    transition: transform $_timeS ease-in-out;
}

.#{$baseClass}__controllersWrapper {
    background-color: $_colorWildSand;
    border-top: solid 1px $_colorAlt;
    border-bottom-left-radius: $_borderRadiusDefault;
    border-bottom-right-radius: $_borderRadiusDefault;
}

.#{$baseClass}__controllersInner {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    padding: $_insetS;
}

.#{$baseClass}__controllerItem {
    margin: 0 $_stackS;

    &:first-of-type {
        margin-right: auto;
        margin-left: 0;

        @media #{$_mediaSDown} {
            position: relative;
            top: 6px;
            left: 5px;
        }
    }

    &:last-of-type {
        margin-right: 0;
    }

    @media #{$_mediaSDown} {
        margin: 0 $_stackXXS;
    }
}

.#{$baseClass}__headline {
    padding-bottom: $_insetXXS;
    font-size: 20px;
    font-weight: bold;
    line-height: 26px;
    color: $_colorMineShaft;
    transition: color $_timeS ease-in-out;
}

.#{$baseClass}__subheadline {
    padding-bottom: $_insetS;
    font-size: 14px;
    line-height: 17px;
    color: $_colorRollingStone;
    font-weight: normal;
    transition: color $_timeS ease-in-out;
}

.#{$baseClass}__articleTypeTrigger,
.#{$baseClass}__oeNumberTrigger,
.#{$baseClass}__carTypeTrigger {
    margin-bottom: $_stackS;
    cursor: pointer;
    color: $_colorSecondary;
    font-size: 14px;
    font-weight: bold;
    line-height: 20px;
}

.#{$baseClass}__images {
    display: flex;
    width: 100%;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: $_stackS;

    @media #{$_mediaMDown} {
        flex-direction: column;
    }

    @media #{$_mediaSDown} {
        flex-direction: column-reverse;
    }
}

.#{$baseClass}__picWrapper {
    width: 100%;
    min-width: 163px;
}

.#{$baseClass}__productImageWrapper {
    width: 163px;
    display: flex;
    justify-content: center;
}

.#{$baseClass}__productImage {
    height: auto;
    width: auto;
    max-width: 163px;
    max-height: 108px;
    margin: 0 auto;
}

.#{$baseClass}__imageWrapper {
    margin: 0 auto $_stackS;

    .#{$baseClass}__unavailableImageIcon {
        display: none;
        align-self: center;
        margin: 0 auto;
    }

    .#{$baseClass}__brandName {
        display: none;
    }

    &.#{$baseClass}__logoWrapper .#{$baseClass}__unavailableImageIcon {
        min-width: 42px;
        width: 42px;
    }

    &.#{$baseClass}__productImageWrapper .#{$baseClass}__unavailableImageIcon {
        min-width: 108px;
    }

    &--error {
        .#{$baseClass}__unavailableImageIcon {
            display: flex;
        }

        .#{$baseClass}__productImage,
        .#{$baseClass}__logo {
            display: none;
        }
    }
}

.#{$baseClass}__logoWrapper {
    &--loaded {
        min-width: 100px;

        .#{$baseClass}__logo {
            opacity: 1;
        }
    }
}

.#{$baseClass}__imageWrapper.#{$baseClass}__logoWrapper {
    width: 100px;
    display: block;
    max-width: 100px;
}

.#{$baseClass}__logo {
    max-width: 100px;
    max-height: 42px;
    width: auto;
    height: auto;
    display: block;
    margin: 0 auto;
    z-index: 1;
    opacity: 0;
    transition: opacity $_timeS ease-in-out;
}

.#{$baseClass}__logoWrapper {
    @media #{$_mediaLUp} {
        margin-right: $_stackS;

        &.#{$baseClass}__imageWrapper--error {
            margin-right: 0;
            margin-left: 0;
        }
    }

    @media #{$_mediaSDown} {
        margin-top: $_stackS;
    }
}

.#{$baseClass}__lazy {
    opacity: 1;
    transition: opacity 0.3s;

    &[data-src] {
        opacity: 0;
    }
}

.#{$baseClass}__textContainer {
    position: relative;
    overflow: hidden;
    transition: height $_timeS ease-in-out;
}

.#{$baseClass}__detailText {
    color: $_colorMineShaft;
    font-size: 14px;
    line-height: 20px;

    padding-bottom: $_insetM;
    display: flex;
    flex-direction: column;
    opacity: 1;
    width: 100%;

    transition: opacity $_timeS ease-in-out;
    position: relative;
    pointer-events: all;
    top: 0;
    left: 0;
}


.#{$baseClass}__detailTextExtended {
    color: $_colorMineShaft;
    font-size: 14px;
    line-height: 20px;

    padding-bottom: $_insetM;
    display: flex;
    flex-direction: column;
    opacity: 0;
    width: 100%;

    transition: opacity $_timeS ease-in-out;
    position: absolute;
    pointer-events: none;
    top: 0;
    left: 0;
}

.#{$baseClass}__quickViewAvailable {
    overflow: hidden;
    height: 0;
    transition: height $_timeS ease-in-out;
    width: 100%;
}

.#{$baseClass}__quickViewHidden {
    opacity: 1;
    transition: opacity $_timeS ease-in-out;
}

.#{$baseClass}__quickViewQuantity,
.#{$baseClass}__quickViewButton {
    &--disabled {
        cursor: default;
        opacity: 0.7;
        pointer-events: none;
    }
}

.#{$baseClass}--quickViewActive {
    .#{$baseClass}__quickViewAvailable {
        height: 60px;

        @media #{$_mediaLDown} {
            height: 80px;
        }
    }

    .#{$baseClass}__detailTextExtended {
        opacity: 1;
        position: relative;
        pointer-events: all;
    }

    .#{$baseClass}__detailText {
        opacity: 0;
        position: absolute;
        pointer-events: none;
    }
}

.#{$baseClass}__detailsButton {
    text-transform: capitalize;

    @media #{$_mediaSDown} {
        & svg {
            fill: $_colorRollingStone;
        }

        & > div {
            flex-direction: column;
            font-size: $_fontSizeXS;
            padding: 0 !important;
        }
    }
}

