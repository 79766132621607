$baseClass: baseclass('CheckoutStepConfirmation');

.#{$baseClass}__section {
  & + & {
    margin-top: $_stackM;
  }
}

.#{$baseClass}__headline {
  margin-bottom: $_stackS;

  font-weight: bold;
  font-size: 21px;
}

.#{$baseClass}__announcement {
  border-radius: $_borderRadiusDefault;
  background-color: #E02020;
  color: $_colorWhite;
  font-size: 14px;
  font-weight: bold;
  line-height: 17px;
  text-align: center;
  padding: 10px 16px;
  margin-bottom: 24px;
}

.#{$baseClass}__footer {
  display: flex;
  flex-direction: column;
  padding-top: $_insetL;
  @include clearfix;
}

.#{$baseClass}__voucher {
  margin-bottom: $_stackM;
}

.#{$baseClass}__terms {
  margin-top: 1em;
}

.#{$baseClass}__newsletterContainer {
  margin: 15px 0;

  .#{$baseClass}__termsLinks {
    margin-top: 10px;
  }
}

.#{$baseClass}__termsLinks {
  display: none;

  &--visible {
    display: block;
    margin-top: 1em;
    font-size: 14px;
    line-height: 20px;
  }

  a {
    text-decoration: underline;
  }
}

@media #{$_mediaMUp} {
  .#{$baseClass}__section {
    & + & {
      margin-top: 24px;
    }
  }

  .#{$baseClass}__headline {
    margin-bottom: 24px;
  }

  .#{$baseClass}__voucher {
    float: left;
  }

  .#{$baseClass}__amounts {
    float: right;
  }
}

@media #{$_mediaLUp} {
  .#{$baseClass}__footer {
    display: block;
  }
}

.#{$baseClass}__message {
  font-size: 14px;
  display: flex;
  width: 100%;
  line-height: 25px;
  background: $_colorConcrete;
  margin-bottom: $_stackM;
  padding: $_insetM;
  border-radius: $_borderRadiusDefault;
}

.#{$baseClass}__messageTitle {
  font-weight: bold;
  width: 100%;
}

.#{$baseClass}__messageContainer {
  width: 100%;
}

.#{$baseClass}__messageIcon {
  filter: grayscale(1);
  opacity: 0.6;
  margin-right: 12px;
  width: 22px;
}

.#{$baseClass}__reference-number:not(.#{$baseClass}__reference-number--visible) {
  display: none;
}

.#{$baseClass}__reference-number-content {
  padding: 16px;
  border: 1px solid $_colorNaturalGrey;
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
}

.#{$baseClass}__reference-number-message {
  margin-bottom: 12px;
  font-size: 14px;
  line-height: 18px;
  color: $_colorNero;
}

.#{$baseClass}__reference-number-input {
  border: 1px solid $_colorFrenchGrey !important;
  border-radius: 3px !important;
  padding: 12px 16px;
}

.#{$baseClass}__reference-number-counter {
  margin: 4px;
  font-size: 10px;
  color: $_colorNero;
}

.#{$baseClass}--rebranding {
  color: $_colorRebrandingBlackOut;

  .#{$baseClass}__headline {
    @include rebranding-h4;
  }
}
