$baseClass: baseclass('PriceInfo');

.#{$baseClass} {
    &--productCard {
        .#{$baseClass}__mainPrice {
            font-size: $_fontSizeXL;
            color: $_colorChathamsBlue;
            font-weight: bold;

            &--highlighted {
                color: $_colorMonza;
            }

            @media #{$_mediaSDown} {
                font-size: 28px;
            }
        }

        .#{$baseClass}__formerPrice {
            font-size: 20px;
        }
    }

    a {
        color: $_colorBrand;
    }
}

.#{$baseClass}__priceWrap {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;

    @media #{$_mediaSDown} {
        display: block;
        margin-bottom: $_stackS;
    }
}

.#{$baseClass}__mainPrice {
    font-size: 40px;
    margin-right: $_stackS;
}

.#{$baseClass}__formerPrice {
    font-size: 20px;
}

.#{$baseClass}__crossedOutPrice {
    font-size: 22px;
    font-weight: 600;
    color: $_colorChathamsBlue;
    text-decoration: line-through;
}

.#{$baseClass}__priceTax {
    font-size: $_fontSizeS;
    line-height: 14px;
    color: $_colorRollingStone;
    margin-bottom: $_stackXS;
}

.#{$baseClass}__priceSale {
    display: none;
}

.#{$baseClass}__availability {
    margin: 15px 0;
}

.#{$baseClass}__priceText {
    font-size: $_fontSizeS;
    line-height: 16px;
    color: $_colorMineShaft;
    margin: 15px 0;
}

.#{$baseClass}__bold {
    font-weight: bold;
}
