$baseClass: baseclass('BcTwoColumnTemplate');

.#{$baseClass} {
    display: flex;
    flex-direction: column;
    background: $_colorWhite;
}

.#{$baseClass}__header {
    width: 100%;
}

.#{$baseClass}__main {
    flex-grow: 1;
}

.#{$baseClass}__mainInner {
    @include pageWidth;
    @include pagePadding;

    padding-top: $_insetM;
    padding-bottom: $_insetM;

    display: flex;
    flex-direction: column;
}

@media #{$_mediaLUp} {
    .#{$baseClass}__mainInner {
        padding-top: $_insetL;
        flex-direction: row;
    }

    .#{$baseClass}__navigation {
        flex-shrink: 0;
        width: 190px;
        margin-right: 15px;
    }

    .#{$baseClass}__content {
        flex-grow: 1;
    }

    .#{$baseClass}__AccountHeader {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        width: 100%;
    }
}
