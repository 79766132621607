$baseClass: baseclass('Repeated');

.#{$baseClass} {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
}

.#{$baseClass}__input {
    flex-grow: 1;
    width: 100%;
    margin: $_stackS 0;
}

.#{$baseClass}__explanation {
    padding: $_insetS 0;
    width: 100%;
}

.#{$baseClass}__title {
    margin-bottom: $_stackXS;

    color: $_colorBlackPrimary;
    font-size: 15px;
    line-height: 20px;
}

.#{$baseClass}__text {
    color: $_colorBlackSecondary;
    font-size: $_fontSizeS;
    line-height: 16px;
}

.#{$baseClass}__error {
    width: 100%;
    margin-top: $_stackM;
    margin-bottom: $_stackXS;

    font-size: 15px;
    line-height: 20px;
    color: $_colorRed;
}


@media #{$_mediaSDown} {
    .#{$baseClass}__input {
        margin: $_stackXS 0 !important;
    }
}
