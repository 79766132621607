$baseClass: baseclass('CheckboxList');

.#{$baseClass} {
    &__label {
        padding-bottom: $_stackS;

        font-weight: bold;
        color: $_colorMineShaft;
        font-size: $_fontSizeM;
        line-height: 22px;
    }
}

@media #{$_mediaLUp} {
    .#{$baseClass}__column {
        column-count: 2;
    }
}
