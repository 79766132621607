$baseClass: baseclass('CmsNavigationTree');


.#{$baseClass} {
    width: 100%;
    background: $_colorWhite;
}

.#{$baseClass}__item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 60px;
    border-bottom: 1px solid $_colorAlto;
    padding: 0 $_insetM;
    margin: 0 #{-$_stackM};
    font-weight: bold;

    &:hover,
    &:focus {
        background-color: $_colorWildSand;
    }
}

.#{$baseClass}__itemIcon {
    fill: $_colorRollingStone;
    padding-right: $_insetS;
    height: auto;
    width: 35px;
}

.#{$baseClass}__itemTitle {
    flex-grow: 1;
    overflow: hidden;

    line-height: 1.2;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: $_colorMineShaft;
}

.#{$baseClass}__itemArrow {
    fill: $_colorBrand;
    height: 30px;
    width: 30px;
}

@media #{$_mediaLUp} {
    .#{$baseClass} {
        display: none;
    }
}
