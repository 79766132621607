$baseClass: baseclass('InfoBar');

.#{$baseClass} {
    background: $_colorWhite;

    font-size: 16px;
    color: $_colorRollingStone;
    height: 32px;
    line-height: 32px;
}

.#{$baseClass}__inner {
    @include pageWidth;
    @include pagePadding;

    height: 29px;
    overflow: hidden;
}

.#{$baseClass}__inner2 {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    overflow: hidden;

    @media (max-width: 910px) {
        justify-content: center;
    }
}

.#{$baseClass}__service {
    white-space: nowrap;
    align-self: flex-start;

    &--mobile {
        line-height: 22px;

        .#{$baseClass}__bold {
            display: block;
        }
    }
}

.#{$baseClass}__facts {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    min-width: 0;
    flex-grow: 1;

    @media (max-width: 910px) {
        display: none;
    }
}

.#{$baseClass}__fact {
    display: flex;
    align-items: center;
    white-space: nowrap;
    margin-left: 25px;
    flex-grow: 1;

    &:first-child {
        margin-left: 0;
    }

    &:last-child {
        margin-right: 25px;
    }
}

.#{$baseClass}__text {
    display: inline-block;
    transform: translate(0, 1px);
}

.#{$baseClass}__icon {
    margin-right: $_stackS;

    &,
    g,
    path {
        fill: $_colorRollingStone;
    }
}

.#{$baseClass}__bold {
    font-weight: bold;
}
