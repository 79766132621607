$baseClass: baseclass('FooterServices');

.#{$baseClass}__headline {
    display: flex;
    align-items: center;

    font-weight: bold;
    font-size: 18px;
    color: $_colorMineShaft;
    line-height: 30px;
}

.#{$baseClass}__headlineIcon {
    fill: $_colorBrand;
    margin-right: $_stackS;

    transform: rotate(0);
    transform-origin: center;
    transition: transform $_timeS ease-in-out;
}

.#{$baseClass}__items {
    overflow: hidden;

    transition: max-height $_timeS ease-in-out;
}

.#{$baseClass}__itemsInner {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.#{$baseClass}__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: $_stackXS $_stackL #{$_stackS + $_stackXS} 0;

    background: $_colorWhite;
}

.#{$baseClass}__itemIcon {
    max-height: 30px;
    max-width: 70px;

    &--generic {
        &,
        g,
        path {
            filter: invert(52%) sepia(21%) saturate(168%) hue-rotate(149deg) brightness(88%) contrast(92%);
        }
    }

    &--expanded {
        max-width: 110px;
    }

    &--gray {
        filter: grayscale(100%);
        opacity: 0.9;
    }
}

.#{$baseClass}__itemLabel {
    margin-top: $_stackS;

    font-size: $_fontSizeS;
    color: $_colorRollingStone;
}

@media #{$_mediaMDown} {
    .#{$baseClass} {
        padding: 0 $_insetS;
        border-bottom: 1px solid $_colorAlto;

        &--open {
            .#{$baseClass}__headlineIcon {
                transform: rotate(-180deg);
            }
        }
    }

    .#{$baseClass}__headline {
        padding: $_insetS 0;
        font-size: 14px;
        cursor: pointer;
    }

    .#{$baseClass}__items {
        max-height: 0;
    }

    .#{$baseClass}__itemsInner {
        padding: 0 $_insetM $_insetM;
    }
}

@media #{$_mediaLUp} {
    .#{$baseClass}__headlineIcon {
        display: none;
    }

    .#{$baseClass}__items {
        max-height: none !important;
    }
}
