$baseClass: baseclass('KeyValue');

.#{$baseClass} {
    font-size: 14px;
    line-height: 20px;

    text-align: left;
}

.#{$baseClass}__key {
    padding-right: $_insetM;

    font-weight: bold;
    text-align: left;
}

.#{$baseClass}--rebranding {
    .#{$baseClass}__key {
        @include rebranding-para-bold;
    }

    .#{$baseClass}__value {
        @include rebranding-para-small-regular;
    }
}
