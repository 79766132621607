$baseClass: baseclass('CarisDropdown');

.#{$baseClass} {
    position: relative;

    &--disabled {
        display: none;

        .#{$baseClass}__header {
            cursor: initial;
            color: $_colorGallery;
            border-color: $_colorGallery;
        }

        .#{$baseClass}__icon {
            fill: $_colorGallery;
        }
    }

    &--expanded {
        .#{$baseClass}__icon {
            transform: rotate(90deg);
        }

        .#{$baseClass}__optionsWrapper {
            max-height: 1000px;
            display: block;
        }
    }
}

.#{$baseClass}__header {
    border: 1px solid $_colorAlto;
    border-radius: $_borderRadiusDefault;
    padding: 12px $_insetM;
    color: $_colorRollingStone;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    cursor: pointer;
}

.#{$baseClass}__headerWrapper {
    z-index: 1;
}

.#{$baseClass}__icon {
    fill: $_colorBrand;
}

.#{$baseClass}__optionsWrapper {
    position: absolute;
    top: 100%;
    width: 100%;
    overflow: hidden;
    z-index: 1;
    transition: max-height $_timeS ease-in-out;
    padding-right: $_insetM;
    padding-bottom: $_stackM;
    display: none;
    max-height: 0;
}

.#{$baseClass}__optionsList {
    border-bottom-left-radius: $_borderRadiusDefault;
    border-bottom-right-radius: $_borderRadiusDefault;
    border: solid 1px $_colorAlto;
    background-color: $_colorWhite;
    max-height: 200px;
    overflow: auto;
}

.#{$baseClass}__option {
    cursor: pointer;
    list-style: none;
    padding: $_insetM;

    &:hover {
        background-color: $_colorAlabaster;
    }
}
