$baseClass: baseclass('QuantitySelector');

.#{$baseClass} {
    width: 82px;

    html:lang(nl) & {
        width: 110px;
    }

    &--autoteile24 {
      .#{$baseClass}__select,
      .#{$baseClass}__input {
        color: $_colorChathamsBlue
      }

      .#{$baseClass}__selectWrapper,
      .#{$baseClass}__inputWrapper {
        .#{$baseClass}__icon {
          fill: $_colorChathamsBlue
        }
      }

    }

    &--wide {
        width: 125px;
    }

    &--error {
        .#{$baseClass}__error,
        .#{$baseClass}__label {
            color: $_colorRed;
        }

        .#{$baseClass}__select {
            border-color: $_colorRed;
            height: 38px;
        }
    }

    &--disabled {
        pointer-events: none;

        .#{$baseClass}__select,
        .#{$baseClass}__input {
            color: $_colorRollingStone;
        }

        .#{$baseClass}__icon {
            display: none;
        }
    }

    &--showInput {
        .#{$baseClass}__selectWrapper {
            display: none;
        }

        .#{$baseClass}__inputWrapper {
            display: block;
        }
    }

    select::-ms-expand {
        display: none;
    }

}

.#{$baseClass}__label {
    display: block;
    position: absolute;
    top: 5px;
    left: 8px;

    font-size: $_fontSizeXS;
    color: $_colorBlack;

    &--required::after {
        content: '*';
    }
}

.#{$baseClass}__selectWrapper,
.#{$baseClass}__inputWrapper {
    position: relative;
    cursor: pointer;
}

.#{$baseClass}__selectWrapper {
    .#{$baseClass}__icon {
        pointer-events: none;

        fill: $_colorBrand;
    }
}

.#{$baseClass}__inputWrapper {
    display: none;

    .#{$baseClass}__icon {
        fill: $_colorBrand;
    }
}


.#{$baseClass}__select,
.#{$baseClass}__input {
    appearance: none;
    outline: none;
    background: $_colorWhite;

    padding-left: $_insetM;
    height: 38px;
    padding-top: 0;
    width: 100%;
    transition: all $_timeS;

    font-weight: bold;
    color: $_colorBrand;
    font-size: $_fontSizeM;
    line-height: 16px;

    border: 1px solid $_colorCodGrayLight;
    border-radius: $_borderRadiusDefault;

    html:lang(nl) & {
        width: 110px;
    }
}

.#{$baseClass}--focused {
    .#{$baseClass}__select,
    .#{$baseClass}__input {
        border: 1px solid $_colorChathamsBlue;
    }
}

.#{$baseClass}--wide {
    .#{$baseClass}__select,
    .#{$baseClass}__input {
        width: 125px;
    }
}

.#{$baseClass}__select {
    cursor: pointer;

    &:focus::-ms-value {
        color: $_colorBrand;
        background: $_colorWhite;
    }
}

.#{$baseClass}__input {
    -moz-appearance: textfield;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
    }
}

.#{$baseClass}__icon {
    position: absolute;
    right: 11px;
    top: 50%;
    transform: translate(0, -50%);

    transition: fill $_timeS ease-in-out;
}

.#{$baseClass}__error,
.#{$baseClass}__message {
    padding-top: $_inlineXS;
    padding-left: $_inlineXXS;

    color: $_colorBlackPrimary;
    font-size: $_fontSizeS;
    line-height: 16px;
}

@media print {
    .#{$baseClass} {
        &--disabled {
            width: 60px;

            .#{$baseClass}__select,
            .#{$baseClass}__input {
                width: 60px;
            }
        }
    }
}

.#{$baseClass}--rebranding {
    .#{$baseClass}__select {
        color: $_colorRebrandingBlackOut;
    }

    .#{$baseClass}__icon {
        fill: $_colorRebrandingBlackOut;
    }
}

.#{$baseClass}--maxStockReached {
    border-color: $_colorRed;
}
