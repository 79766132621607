$baseClass: baseclass('Pagination');

.#{$baseClass}__inner {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}

.#{$baseClass}__button {
    & + & {
        margin-left: $_stackXS;
    }
}

.#{$baseClass}__seperator {
    margin: 0 $_stackS;
}

.#{$baseClass}__info {
    max-width: 400px;
    margin: $_stackM auto 0;
    text-align: center;

    color: $_colorBrand;
    font-size: 12px;
    line-height: 16px;

    em {
        font-weight: bold;
    }
}
