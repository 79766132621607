$baseClass: baseclass('Price');

.#{$baseClass} {
  display: block;
  white-space: nowrap;
  text-align: right;

  color: $_colorMineShaft;

  &--inline {
    display: inline-block;
    color: inherit;
  }

  &--bold {
    font-weight: bold;
  }

  &--big {
    text-align: left;
    font-weight: bold;
    font-size: 40px;
    color: $_colorChathamsBlue;
  }

  &--com {
    color: $_colorBrand !important;
  }

  &--highlighted {
    color: $_colorMonza !important;
  }

  &--outdated {
    color: $_colorChathamsBlue;
    text-decoration: line-through;
  }
}

.#{$baseClass}--rebranding {
  color: $_colorRebrandingBlackOut;
  @include rebranding-para-regular;

  &.#{$baseClass}--bold {
    @include rebranding-para-bold;
  }

  &.#{$baseClass}--large {
    @include rebranding-h4--regular;
  }
}
