$baseClass: baseclass('FooterNavigation');

.#{$baseClass} {
    &--2-columns {
        .#{$baseClass}__itemsInner {
            column-count: 2;
            column-gap: $_insetM;
        }
    }

    &--5-columns {
        .#{$baseClass}__itemsInner {
            column-count: 5;
            column-gap: $_insetM;
        }
    }
}

.#{$baseClass}__headline {
    display: flex;
    align-items: center;

    font-weight: bold;
    font-size: 18px;
    color: $_colorMineShaft;
    line-height: 30px;
}

.#{$baseClass}__headlineIcon {
    fill: $_colorBrand;
    margin-right: $_stackS;

    transform: rotate(0);
    transform-origin: center;
    transition: transform $_timeS ease-in-out;
}

.#{$baseClass}__items {
    overflow: hidden;

    transition: max-height $_timeS ease-in-out;
}

.#{$baseClass}__item {
    color: $_colorRollingStone;
    line-height: 26px;

    & + & {
        margin-top: $_stackXS;
    }
}

.#{$baseClass}--channels {
    .#{$baseClass}__itemsInner {
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        @media #{$_mediaMDown} {
            flex-flow: column wrap;
            max-height: 220px;
            align-items: flex-start;
        }
    }

    .#{$baseClass}__item {
        padding-right: 20px;
        padding-bottom: 10px;
        margin-top: 0;
    }

    .#{$baseClass}__itemLink {
        display: flex;
        align-items: center;
    }

    .#{$baseClass}__flagIcon {
        margin-right: $_stackS;
        height: 15px;

        @media #{$_mediaMDown} {
            height: auto;
        }
    }
}

@media #{$_mediaLDown} {
    .#{$baseClass} {
        &--5-columns .#{$baseClass}__itemsInner {
            column-count: 2;
        }
    }
}

@media #{$_mediaMDown} {
    .#{$baseClass} {
        padding: 0 $_insetS;
        border-bottom: 1px solid $_colorAlto;

        &--open {
            .#{$baseClass}__headlineIcon {
                transform: rotate(-180deg);
            }
        }

        &--2-columns .#{$baseClass}__itemsInner,
        &--5-columns .#{$baseClass}__itemsInner {
            column-count: 1;
        }
    }

    .#{$baseClass}__headline {
        padding: $_insetS 0;
        cursor: pointer;
        font-size: 14px;
    }


    .#{$baseClass}__items {
        max-height: 0;
    }

    .#{$baseClass}--initOpen .#{$baseClass}__items {
        max-height: 1000px;
    }

    .#{$baseClass}__itemsInner {
        padding: 0 $_insetM $_insetM;
    }

    .#{$baseClass}__itemLink {
        line-height: 30px;
    }
}

@media #{$_mediaLUp} {
    .#{$baseClass}__headline {
        margin-bottom: $_stackXS;
    }

    .#{$baseClass}__headlineIcon {
        display: none;
    }

    .#{$baseClass}__items {
        max-height: none !important;
    }
}
