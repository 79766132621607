$baseClass: baseclass("ImageCaptionCell");

.#{$baseClass}__frame {
  border-radius: $_borderRadiusDefault;
  border: solid 1px $_colorAlt;
  text-align: center;
  height: 110px;
  background-color: #fff;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  padding: 0 $_insetS;

  .#{$baseClass}__image {
    max-width: 100%;
  }

  @media #{$_mediaLUp} {
    .#{$baseClass}__image {
      max-height: 65px;
    }
  }

  @media #{$_mediaMDown} {
    width: 100%;
    height: 50px;
    border-radius: 0;
    flex-flow: row nowrap;
    justify-content: flex-start;
    border-color: transparent;
    border-top-color: $_colorAlt;
    padding: 0 $_insetM 0 $_insetS;

    &::after {
      content: "";
      height: 25px;
      width: 25px;
      margin-left: auto;
      background-image: url("data:image/svg+xml;base64,PHN2ZyBmaWxsPSIjRUY3RDAwIiBoZWlnaHQ9IjMwIiB2aWV3Qm94PSIwIDAgMzAgMzAiIHdpZHRoPSIzMCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4gICAgPHBhdGggZD0iTTEwIDZMOC41OSA3LjQxIDEzLjE3IDEybC00LjU4IDQuNTlMMTAgMThsNi02eiIvPjwvc3ZnPg==");
    }

    .#{$baseClass}__imageWrapper {
      width: 65px;

      .#{$baseClass}__image {
        max-width: 45px;
        margin: 0 auto;
      }
    }

    .#{$baseClass}__title {
      margin-top: 0;
    }
  }
}

.#{$baseClass}__title {
  font-weight: 600;
  display: block;
  margin-top: $_stackS;
}
