$baseClass: baseclass('PaymentTile');

.#{$baseClass}__action {
    padding: 0;

    color: $_colorSecondary;
    text-decoration: underline;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    cursor: pointer;
}
