$baseClass: baseclass('CmsUsp');

.#{$baseClass} {
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-around;
    align-items: stretch;
    padding: $_insetM 0;

    &__itemWrap {
        flex: 0 0 25%;
    }

    @media #{$_mediaSDown} {
        justify-content: flex-start;
        flex-wrap: wrap;

        &__itemWrap {
            flex: 1 0 50%;
        }
    }

    &__bordered {
        border: solid 1px transparent;

        @media #{$_mediaSDown} {
            border-color: $_colorWildSand;
        }

        &:nth-of-type(1) {
            border-left-color: transparent;
            border-top-color: transparent;
        }

        &:nth-of-type(2) {
            border-right-color: transparent;
            border-top-color: transparent;
        }

        &:nth-of-type(3) {
            border-left-color: transparent;
            border-bottom-color: transparent;
        }

        &:nth-of-type(4) {
            border-right-color: transparent;
            border-bottom-color: transparent;
        }
    }

    &--bordered {
        border-top: 1px solid $_colorAlto;
        position: relative;

        &::before {
            content: '';
            width: 100%;
            height: 100%;
            border-top: 1px solid $_colorAlto;
            position: absolute;
            top: -1px;
            left: -100%;
        }

        &::after {
            content: '';
            width: 100%;
            height: 100%;
            border-top: 1px solid $_colorAlto;
            position: absolute;
            left: 100%;
            top: -1px;
        }

        @media #{$_mediaMDown} {
            border-top-color: transparent;

            &::before {
                border-top-color: transparent;
            }

            &::after {
                border-top-color: transparent;
            }
        }
    }
}
