$baseClass: baseclass('ContentList');

.#{$baseClass} {
  background: $_colorWhite;
}

.#{$baseClass}__addButton {
  margin-top: $_stackM;
}

.#{$baseClass}__subtitle {
  margin-bottom: $_stackM;

  font-size: 14px;
  line-height: 18px;
}

.#{$baseClass}__content {
  & + .#{$baseClass}__footer {
    border-top: none;
  }
}

.#{$baseClass}__empty {
  padding: $_insetM;
  border: 1px solid $_colorAlto;
}

.#{$baseClass}__subtitle {
  flex-grow: 999;
}

.#{$baseClass}__addButton {
  margin-top: 0;
  flex-grow: 1;
  flex-shrink: 0;
}

.#{$baseClass}__empty {
  margin-top: $_stackL;
}

.#{$baseClass}__footer {
  padding: $_insetM;
  border: 1px solid $_colorAlto;
  display: flex;
}

@media #{$_mediaLUp} {
  .#{$baseClass}__footer {
    display: block;
  }

  .#{$baseClass}__AccountHeader {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    width: 100%;
  }
}

@media #{$_mediaSDown} {
  .#{$baseClass}__addButton {
    width: 100%;
  }
}

.#{$baseClass}--rebranding {
  .#{$baseClass}__subtitle {
    @include rebranding-para-small-regular;
  }
}
