$baseClass: baseclass('IconList');

.#{$baseClass} {
    flex-grow: 1;
    padding: $_insetM 0 0;

    &__items {
        display: flex;
        flex-wrap: wrap;
    }

    &__item {
        display: flex;
        flex-shrink: 0;
        margin-right: $_stackS;
        margin-bottom: $_stackM;
        max-width: 130px;
    }

    &__icon {
        margin-bottom: $_stackS;
        height: 43px;
    }

    &__itemLink {
        display: flex;
        align-items: center;
        flex-direction: column;

        color: $_colorNevada;
        font-size: 11px;
        line-height: 13px;
    }

    &--column {
        .#{$baseClass}__items {
            flex-direction: column;
            align-items: start;
        }

        .#{$baseClass}__item {
            margin-bottom: $_stackS;
            margin-right: 0;
            max-width: none;
        }

        .#{$baseClass}__itemLink {
            flex-direction: row;
        }

        .#{$baseClass}__iconContainer {
            width: 50px;
            display: flex;
        }

        .#{$baseClass}__icon {
            margin-bottom: 0;
            height: 43px;
        }
    }
}
