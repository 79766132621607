$baseClass: baseclass('FooterReduced');

.#{$baseClass} {
    margin-top: $_stackM;
    border-top: 1px solid $_colorAlto;
    background: $_colorWhite;

    line-height: 26px;
    font-size: 14px;
    color: $_colorRollingStone;
}

.#{$baseClass}__inner {
    @include pageWidth;

    padding: $_insetL $_insetM $_insetS;
}

.#{$baseClass}__row {
    display: flex;
    justify-content: space-between;
    max-width: 100%;
    overflow: hidden;

    &--top {
        align-items: center;
        flex-wrap: wrap;
    }

    &--bottom {
        align-items: flex-start;
        flex-wrap: wrap-reverse;
    }
}

.#{$baseClass}__info {
    margin-bottom: $_stackM;
}

.#{$baseClass}__item {
    white-space: nowrap;
}

.#{$baseClass}__phone {
    font-size: 18px;
    font-weight: bold;
}

.#{$baseClass}__phoneContainer {
    margin-right: 3px;
}

.#{$baseClass}__links {
    margin-bottom: $_stackM;
}

.#{$baseClass}__link {
    & + & {
        margin-left: $_stackM;
    }
}

.#{$baseClass}__copyright {
    margin-bottom: $_stackM;
}

.#{$baseClass}__logos {
    margin-bottom: $_stackM;
}

.#{$baseClass}__logo {
    & + & {
        margin-left: $_stackM;
    }
}

@media #{$_mediaSDown} {
    .#{$baseClass}__inner {
        padding: $_insetM $_insetS $_insetS;
    }
}

@media #{$_mediaMUp} {
    .#{$baseClass} {
        margin-top: $_stackL;
    }
}

@media print {
    .#{$baseClass} {
        display: none;
    }
}
