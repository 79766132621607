$baseClass: baseclass('PaymentIframeContainer');

.#{$baseClass} {
    margin-bottom: -$_stackS;

    background: $_colorWhite;

    &--loading {
        .#{$baseClass}__loader {
            display: flex;
        }

        .#{$baseClass}__iframe {
            display: none;
        }
    }
}

.#{$baseClass}__message {
    position: relative;
    top: -16px;
    left: -16px;
    width: calc(100% + 32px);
    max-height: 0;
    overflow: hidden;
    transition: max-height $_timeS ease-in-out;

    &--visible {
        max-height: 200px;

        .#{$baseClass}__messageInner {
            opacity: 1;
        }
    }
}

.#{$baseClass}__messageInner {
    opacity: 0;
    transition: opacity $_timeS ease-in-out;
    position: relative;
    background: $_colorMonza;

    color: $_colorWhite;
    text-align: center;
    font-weight: bold;
    line-height: 26px;
    font-size: 16px;

    padding: 7px $_inlineM;
}

.#{$baseClass}__loader {
    display: none;
    flex-direction: column;
    align-items: center;
    padding: $_insetM;
}

.#{$baseClass}__loaderText {
    margin-top: $_stackM;
}

.#{$baseClass}__loaderIcon {
    animation: linearRotationKeyframes $_timeL ease-in-out infinite;
    transform-origin: center;
    width: 60px;
    height: 60px;
}

.#{$baseClass}__iframe {
    display: block;
    width: 100%;
}

.#{$baseClass}__postForm {
    display: none !important;
}
