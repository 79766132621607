$baseClass: baseclass('OrderConfirmation');

.#{$baseClass} {
    width: 100%;
    background: $_colorWhite;
}

.#{$baseClass}__title {
    padding: 80px 0 50px;

    font-weight: bold;
    font-size: 40px;
    color: $_colorSecondary;
    text-align: center;
}

.#{$baseClass}__sectionHeadline {
    margin-bottom: #{$_stackM + 6px};

    font-weight: bold;
    font-size: 18px;
    color: $_colorMineShaft;
    letter-spacing: 0;
    line-height: 26px;

    &--center {
        text-align: center;
    }
}

.#{$baseClass}__sectionSubheadline {
    font-weight: bold;
}

.#{$baseClass}__section {
    display: flex;
    flex-wrap: wrap;
}

.#{$baseClass}__sectionContent {
    padding: $_insetL 0;
    border-top: 1px solid $_colorAlto;
    display: flex;
    justify-content: center;
    flex-grow: 1;

    &--registration {
        width: 100%;
    }

    &--summary,
    &--steps {
        width: 50%;
    }

    &--billing,
    &--support {
        width: 25%;
    }

    &--shipping,
    &--cart {
        width: 75%;
    }

    &--summary,
    &--steps {
        border-top: none;
    }

    &--steps,
    &--billing {
        .#{$baseClass}__sectionContentInner {
            border-left: 1px solid $_colorAlto;
        }
    }

    &--cart {
        order: 0;
    }

    &--support {
        order: 1;
    }
}

.#{$baseClass}__sectionContentInner {
    padding: 0 $_insetL;
    flex-grow: 1;
    min-width: 0;
}


.#{$baseClass}__table {
    margin: 0 auto;
    line-height: 26px;
}

.#{$baseClass}__tableHead {
    text-align: right;
    font-weight: bold;
}

.#{$baseClass}__tableData {
    text-align: left;
    padding-left: 24px;

    a {
        color: $_colorSecondary;
    }
}

.#{$baseClass}__account {
    padding-top: #{$_insetM + 6px};
    padding-bottom: $_insetS;
}

.#{$baseClass}__list {
    line-height: 26px;
    list-style: disc;
}

.#{$baseClass}__listItem {
    margin-left: 1em;
    padding-left: 0.5em;

    strong {
        font-weight: bold;
    }

    & + & {
        margin-top: $_stackS;
    }
}


.#{$baseClass}__registration {
    margin: 0 auto;
    width: 100%;
    max-width: 350px;
}

.#{$baseClass}__registrationInput {
    margin-bottom: 24px;
}


.#{$baseClass}__registrationSubmit {
    width: 100%;
}

.#{$baseClass}__registrationIntro {
    margin-bottom: $_stackM;

    line-height: 26px;
    text-align: center;
}

.#{$baseClass}__registrationBullets {
    display: flex;
    justify-content: center;
    margin-bottom: 24px;
    line-height: 20px;
}

.#{$baseClass}__registrationBullet {
    list-style: disc;
    margin-left: 1em;
    padding-left: 0.5em;

    strong {
        font-weight: bold;
    }

    & + & {
        margin-top: $_stackS;
    }
}


.#{$baseClass}__addressMap {
    display: flex;

    .#{$baseClass}__address {
        padding-left: $_insetL;
    }
}

.#{$baseClass}__map {
    width: calc(66.666% + 12px);
    flex-shrink: 0;

    img {
        display: block;
        width: 100%;
        border: 1px solid $_colorAlto;
    }
}

.#{$baseClass}__address {
    line-height: 26px;
    overflow: hidden;
}

.#{$baseClass}__addressLine {
    text-overflow: ellipsis;
    overflow: hidden;
}


.#{$baseClass}__print {
    width: 100%;
    margin-top: $_stackM;
}


.#{$baseClass}__supportIntro {
    line-height: 20px;

    margin-bottom: $_stackM;
}

.#{$baseClass}__supportFacts {
    line-height: 26px;

    a {
        color: $_colorSecondary;
    }
}

.#{$baseClass}__phoneContainer {
    margin-right: 3px;
}

.#{$baseClass}__amounts {
    display: flex;
    justify-content: flex-end;
    margin-top: $_stackL;
}


@media screen and #{$_mediaMDown} {
    .#{$baseClass}__title {
        padding: 30px 0 20px;
    }

    .#{$baseClass}__table {
        display: block;
        margin: 0;
    }

    .#{$baseClass}__registrationIntro {
        text-align: left;
    }


    .#{$baseClass}__registrationBullets {
        display: block;
    }

    .#{$baseClass}__tableHead,
    .#{$baseClass}__tableData {
        display: inline;
    }

    .#{$baseClass}__tableData {
        padding-left: 0;
    }

    .#{$baseClass}__section {
        padding: 0;
    }

    .#{$baseClass}__sectionHeadline {
        text-align: left;
    }

    .#{$baseClass}__sectionContent {
        padding: $_insetL 0;
        width: 100%;

        &--steps,
        &--billing,
        &--support {
            .#{$baseClass}__sectionContentInner {
                border-left: none;
            }
        }

        &--summary {
            order: 1;
        }

        &--steps {
            order: 5;
        }

        &--registration {
            order: 2;
            border-bottom: 1px solid $_colorAlto;
        }

        &--shipping {
            order: 3;
            border-top: none;
        }

        &--billing {
            order: 4;
        }

        &--cart {
            order: 6;
            border-top: none;
        }

        &--support {
            order: 7;
            border-top: none;
        }
    }

    .#{$baseClass}__sectionContentInner {
        padding: 0 $_insetM;
    }

    .#{$baseClass}__addressMap {
        display: block;

        .#{$baseClass}__address {
            padding-left: 0;
        }
    }

    .#{$baseClass}__map {
        width: 100%;
        margin-bottom: $_stackM;
    }

    .#{$baseClass}__registration {
        margin: 0;
        max-width: none;
    }
}

@media screen and #{$_mediaSDown} {
    .#{$baseClass}__sectionHeadline {
        margin-top: $_stackXS;
    }

    .#{$baseClass}__sectionContent {
        padding: $_insetM 0;
        display: block;
    }

    .#{$baseClass}__sectionContentInner {
        padding: 0 $_insetS;
    }

    .#{$baseClass}__title {
        font-size: 28px;
    }

    .#{$baseClass}__table {
        font-size: 14px;
    }

    .#{$baseClass}__list,
    .#{$baseClass}__bullets,
    .#{$baseClass}__registrationIntro,
    .#{$baseClass}__registrationBullets,
    .#{$baseClass}__address,
    .#{$baseClass}__supportIntro,
    .#{$baseClass}__supportFacts {
        font-size: 14px;
        line-height: 22px;
        text-align: left;
    }

    .#{$baseClass}__listItem,
    .#{$baseClass}__registrationBullet {
        padding-left: 0;
    }
}


@media print {
    @page {
        margin: 0.5cm;
    }

    html {
        margin: 0 -20px;
    }

    .#{$baseClass}__print {
        display: none;
    }

    .#{$baseClass}__title {
        padding: $_insetL 0 $_insetM;

        font-size: $_fontSizeL;
    }

    .#{$baseClass}__sectionHeadline {
        font-size: $_fontSizeM;
    }

    .#{$baseClass}__table,
    .#{$baseClass}__list,
    .#{$baseClass}__address,
    .#{$baseClass}__supportIntro,
    .#{$baseClass}__supportFacts {
        font-size: $_fontSizeS;
        line-height: 18px;
    }

    .#{$baseClass}__section {
        &--cart {
            display: block;
        }
    }

    .#{$baseClass}__sectionContent {
        page-break-inside: avoid;

        &--cart {
            order: 7;
            page-break-before: always;
        }

        &--support {
            order: 6;
        }

        &--support {
            width: 100%;
        }

        &--registration {
            display: none;
        }

        &--cart {
            width: 100%;
            border-left: none;
            border-top: none;
        }
    }

    .#{$baseClass}__amounts {
        page-break-inside: avoid;
    }

    .#{$baseClass}__tableData,
    .#{$baseClass}__supportFacts {
        a {
            color: $_colorBlack;
        }
    }
}
