$baseClass: baseclass('CategoriesList');

.#{$baseClass}__container {
    background: $_colorWhite;

    overflow-y: auto;
    -webkit-overflow-scrolling: touch;

    &.#{$baseClass}__container--slider {
        right: -100%;
        width: 100%;
        z-index: 15;
        position: fixed;
        top: 0;
        height: 100vh;

        transition: right $_timeS ease-in-out;
    }

    &.#{$baseClass}__container--active {
        right: 0;
        height: 100vh;
    }

    &--autoteile24 {
     .#{$baseClass}__category::after {
        background-image: url("data:image/svg+xml;base64,PHN2ZyBmaWxsPSIjMzc4QUVFIiBoZWlnaHQ9IjI0IiB2aWV3Qm94PSIwIDAgMjQgMjQiIHdpZHRoPSIyNCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KICAgIDxwYXRoIGQ9Ik0xMCA2TDguNTkgNy40MSAxMy4xNyAxMmwtNC41OCA0LjU5TDEwIDE4bDYtNnoiLz4KPC9zdmc+");
      }
    }
}

.#{$baseClass}__categories {
    display: flex;
    flex-wrap: wrap;

    border-top: 1px solid $_colorAlto;
}

.#{$baseClass}__category {
    @include pagePadding;

    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    word-break: break-word;
    justify-content: center;
    border-bottom: 1px solid $_colorAlto;
    color: $_colorMineShaft;
    margin-bottom: 0;
    height: 60px;
    width: 100%;

    &:hover,
    &:focus {
        background-color: $_colorWildSand;
    }

    &--disabled {
        filter: grayscale(1);
        opacity: 0.5;
    }

    &::after {
        content: '';
        height: 30px;
        width: 30px;
        min-width: 30px;
        background-image: url("data:image/svg+xml;base64,PHN2ZyBmaWxsPSIjRUY3RDAwIiBoZWlnaHQ9IjMwIiB2aWV3Qm94PSIwIDAgMzAgMzAiIHdpZHRoPSIzMCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4gICAgPHBhdGggZD0iTTEwIDZMOC41OSA3LjQxIDEzLjE3IDEybC00LjU4IDQuNTlMMTAgMThsNi02eiIvPjwvc3ZnPg==");
    }
}

.#{$baseClass}__categoryTitleContainer {
    display: flex;
    width: calc(100% - 32px);
    font-weight: bold;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
}

.#{$baseClass}__icon {
    fill: $_colorRollingStone;
    height: auto;
    width: 27px;
}

.#{$baseClass}__categoryTitle {
    flex-grow: 1;
    line-height: 1.2;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding-left: $_insetS;
}

.#{$baseClass}__arrowRight {
    fill: $_colorBrand;
    height: 30px;
    width: 30px;
}

.#{$baseClass}__itemsCount,
.#{$baseClass}__pretitle {
    font-size: 14px;
    font-weight: 400;
    color: $_colorRollingStone;
    flex-basis: 100%;
    padding-left: $_insetS;
}

.#{$baseClass}__categoryLink {
    display: flex;
    width: 90%;
    margin: 20px auto;
}
