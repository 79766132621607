$baseClass: baseclass('HeaderButton');

.#{$baseClass} {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    width: 40px;
    height: 40px;
    background: transparent;
    border: 1px solid $_colorWhite;
    border-radius: $_borderRadiusDefault;
    cursor: pointer;
    transition: background-color $_timeXS ease-in-out, border-color $_timeXS ease-in-out;

    &:active {
        outline: none;
    }

    @include blueFocus;

    svg {
        width: 100%;
        max-width: 21px;
        max-height: 24px;
        height: auto;
        flex-shrink: 0;
    }

    svg,
    g,
    path {
        fill: $_colorWhite;
    }

    &:hover {
        background: rgba($_colorWhite, 0.2);
    }

    &.#{$baseClass}--inverted {
        background: $_colorWhite;

        @include orangeFocus;

        svg,
        g,
        path {
            fill: $_colorBrand;
        }

        &:hover {
            background: $_colorWhite;
        }

        .#{$baseClass}__label {
            color: $_colorBlack;
        }
    }

    &--active {
        .#{$baseClass}__bubble {
            z-index: 6;
        }
    }
}

.#{$baseClass}__label {
    display: none;
    color: $_colorWhite;
    font-size: 14px;
    margin-left: $_stackS;
}

.#{$baseClass}__bubble {
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(33%, -50%);
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background: $_colorSecondary;
    display: flex;
    justify-content: center;
    align-items: center;

    color: $_colorWhite;
    font-size: 11px;
    font-weight: bold;
}

@media #{$_mediaSDown} {
    .#{$baseClass} {
        width: 32px;
        height: 32px;
    }

    .#{$baseClass}__bubble {
        width: 20px;
        height: 20px;
        top: 0;
        right: 0;
        letter-spacing: -0.5px;
    }
}

@media (min-width: 900px) {
    .#{$baseClass} {
        &--label {
            width: auto;
        }
    }

    .#{$baseClass}__label {
        display: block;
    }
}
