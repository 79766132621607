.irs {
    height: 40px;
}

.irs-with-grid {
    height: 60px;
}

.irs-line-left,
.irs-line-right,
.irs-line-mid {
    background: $_colorAlto;
}

.irs-line {
    height: 12px;
    top: 25px;
}

.irs-line-left {
    height: 12px;
}

.irs-line-mid {
    height: 12px;
}

.irs-line-right {
    height: 12px;
}

.irs-bar,
.irs-bar-edge {
    background: $_colorSilver;
}

.irs-bar {
    height: 12px;
    top: 25px;
}

.irs-bar-edge {
    top: 25px;
    height: 12px;
    width: 9px;
}

.irs-shadow {
    height: 3px;
    top: 34px;
    background: $_colorBlack;
    opacity: 0.25;
}

.irs-slider {
    width: 16px;
    height: 18px;
    top: 22px;

    &::before {
        content: '';
        width: 2px;
        height: 100%;
        position: absolute;
        left: 7px;
        top: 0;
        background: $_colorRollingStone;
    }
}

.irs-min,
.irs-max,
.irs-from,
.irs-to,
.irs-single {
    color: $_colorWhite;
    font-size: 10px;
    line-height: 1.333;
    text-shadow: none;
    padding: 3px 5px 1px;
    background: $_colorBrand;
    border-radius: 2px;

    &::after {
        position: absolute;
        display: block;
        content: '';
        bottom: -6px;
        left: 50%;
        width: 0;
        height: 0;
        margin-left: -3px;
        overflow: hidden;
        border: 3px solid transparent;
        border-top-color: $_colorBrand;
    }
}

.irs-from,
.irs-to {
    cursor: pointer;
}

.irs-min,
.irs-max {
    top: 0;
    color: $_colorMineShaft;
    background: $_colorAlto;

    &::after {
        border-top-color: $_colorAlto;
    }
}

.irs-grid-pol {
    background: $_colorAlto;
}

.irs-grid-text {
    color: $_colorMineShaft;
}
