$baseClass: baseclass('ProductCardList');

.#{$baseClass} {
    width: 100%;
    background: $_colorWhite;
    min-height: 500px;
}

.#{$baseClass}__sectionWrapper {
    padding-top: 20px;
    position: relative;

    &--gray {
        padding: 20px 0 0;
        margin: 0;

        background: $_colorWildSand;

        @media #{$_mediaMDown} {
            margin: 0 #{-$_stackM};
            padding: 20px $_insetM 0;
        }

        @media #{$_mediaSDown} {
            margin: 0 #{-$_stackS};
            padding: 20px $_insetS 0;
        }

        &::before {
            display: block;
            content: ' ';
            background: $_colorWildSand;
            width: 100%;
            left: -100%;
            position: absolute;
            height: 100%;
            top: 0;
        }

        &::after {
            display: block;
            content: ' ';
            background: $_colorWildSand;
            width: 100%;
            left: 100%;
            position: absolute;
            height: 100%;
            top: 0;
        }
    }

    &--filters {
        z-index: 2;

        @media #{$_mediaSDown} {
            z-index: 3;
        }
    }

    &--separated {
        margin-top: $_stackM;
    }
}

.#{$baseClass}__dialogTable {
    max-height: 550px;
}

.#{$baseClass}__sectionContainer {
    &--selectCar {
        display: flex;
        padding-bottom: 20px;
        align-items: center;

        @media #{$_mediaSDown} {
            flex-wrap: wrap;
        }
    }

    &--noCar {
        pointer-events: none;
        position: relative;
        user-select: none;

        &::before {
            content: '';
            background-color: rgba(0, 0, 0, 0.5);
            position: absolute;
            width: 100vw;
            top: -20px;
            left: -50%;
            bottom: 0;
            z-index: 1;
            padding: 0 100%;
        }
    }
}

.#{$baseClass}__carSelectText {
    flex-basis: 50%;
    margin-right: $_stackM;

    color: $_colorNero;
    font-size: 16px;
    font-weight: bold;
    line-height: 26px;

    @media #{$_mediaSDown} {
        flex-basis: 100%;
        margin-right: 0;
        margin-bottom: $_stackS + $_stackM;
    }
}

.#{$baseClass}__selectCar {
    @media #{$_mediaSDown} {
        width: 100%;
    }
}

.#{$baseClass}__loaderIcon {
    animation: linearRotationKeyframes $_timeL ease-in-out infinite;
    transform-origin: center;
    margin: 40px auto;
    display: inline;
}
