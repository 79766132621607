$baseClass: baseclass('CategoryButton');

.#{$baseClass} {
    border-radius: $_borderRadiusDefault;
    border: solid 1px $_colorAlt;
    height: 100%;
    background-color: $_colorWhite;

    &:hover,
    &--active {
        border-color: $_colorBrand;

        & .#{$baseClass}__wrapper {
            border-right-color: $_colorBrand;
        }
    }

    &--disabled {
        filter: grayscale(1);
        border-color: $_colorAlt;
        opacity: 0.5;

        &:hover {
            border-color: $_colorAlt;

            & .#{$baseClass}__wrapper {
                border-right-color: $_colorAlt;
            }
        }

        .#{$baseClass}__wrapper {
            border-color: $_colorAlt;
            filter: grayscale(1);
        }
    }
}

.#{$baseClass}__link {
    display: block;
    height: 100%;
}

.#{$baseClass}__position {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: stretch;
    height: 100%;
}

.#{$baseClass}__wrapper {
    flex: 0 0 90px;
    border-right: 1px solid $_colorAlt;
    padding: $_insetXS;
    position: relative;
}

.#{$baseClass}__imagePosition {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.#{$baseClass}__image {
    max-width: 85px;
    max-height: 80px;
}

.#{$baseClass}__text {
    height: 80px;
    padding: 0;
    padding-left: $_insetS;
    line-height: 80px;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
}

.#{$baseClass}__verticalAlign {
    display: inline-block;
    vertical-align: middle;
    width: 100%;
}

.#{$baseClass}__title {
    width: 98%;
    overflow: hidden;
    text-overflow: ellipsis;
}

.#{$baseClass}__pretitle,
.#{$baseClass}__subtitle {
    color: $_colorRollingStone;
    font-size: 14px;
}

.#{$baseClass}__pretitle {
    margin-top: -5px;
    line-height: 20px;
}

.#{$baseClass}__subtitle {
    line-height: 16px;
    margin-top: $_stackXS;
}

.#{$baseClass}__pretitle + .#{$baseClass}__title {
    margin-top: 0;
}
