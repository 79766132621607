$baseClass: baseclass('NavigationList');

.#{$baseClass}__headline {
    padding-bottom: $_insetS;

    line-height: 26px;
    width: 100%;
}

.#{$baseClass}__headlineText {
    font-weight: bold;
}

.#{$baseClass}__body {
    overflow: hidden;
}

.#{$baseClass}__bodyInner {
    overflow: hidden;
}

.#{$baseClass}__item {
    &:last-child {
        margin-bottom: 0;
    }

    .#{$baseClass} {
        padding-top: $_insetS;
    }
}

.#{$baseClass}__icon {
    margin-left: $_stackM;
    fill: $_colorBrand;
}

.#{$baseClass}__link {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding: $_insetS 0 $_insetS $_insetM;
    border-left: 7px solid $_colorAlto;

    line-height: 26px;

    &--active {
        border-left-color: $_colorBrand;
    }
}
