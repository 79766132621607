$baseClass: baseclass('FilterValues');

.#{$baseClass} {
    color: $_colorBrand;
    font-size: $_fontSizeS;
    margin-left: 24px;

    @media #{$_mediaLUp} {
        display: none;
    }
}
