$baseClass: baseclass('ProductTile');

.#{$baseClass} {
  width: 100%;
  min-width: 0;
  display: flex;
  flex-direction: column;
  background: $_colorWhite;
  overflow: hidden;

  & + & {
    border-top: 1px solid $_colorBlackDecorative;
  }

  &--expand {
    .#{$baseClass}__images {
      max-height: 200px !important;
    }

    .#{$baseClass}__unit {
      opacity: 1;
    }
  }
}

.#{$baseClass}__main {
  flex-basis: 100%;
  display: flex;
  flex-wrap: wrap;
}

.#{$baseClass}__dropshipment {
  padding: 0 $_stackM;
  margin: 12px 0 $_stackM 0;
  display: flex;
  align-items: center;
  flex-basis: 100%;
}

.#{$baseClass}__dropshipment-icon {
  margin-right: 6px;
}

.#{$baseClass}__dropshipment-message {
  font-size: $_fontSizeS;
  line-height: $_fontSizeM;
  color: $_colorNero;
}

.#{$baseClass}__extras {
  flex-basis: 100%;
}

.#{$baseClass}__availability-icon {
  width: 20px;
  height: 20px;

  path {
    fill: $_colorMonza;
  }
}

.#{$baseClass}__max-quantity-error {
  display: flex;
  align-items: flex-start;
  margin-top: $_insetS;
  width: 100%;
  height: 64px;
  padding: $_insetS;
  background-color: $_colorLightRed;
  margin: $_insetS;
  border-radius: 3px;
  line-height: 24px;
}

.#{$baseClass}__max-quantity-error-icon{
  padding: $_insetXXS $_insetS;
}

.#{$baseClass}__max-quantity-message{
  max-width: 1060px;
}

@media screen {
  .#{$baseClass} {
    &.#{$baseClass}--break {
      flex-direction: column;

      .#{$baseClass}__content {
        flex-direction: column;
      }

      .#{$baseClass}__text {
        order: -1;
      }

      .#{$baseClass}__images {
        max-height: 0;
      }

      .#{$baseClass}__quantity {
        padding: 0;
        flex-grow: 0;
      }

      .#{$baseClass}__numbers {
        flex-direction: row;
        justify-content: space-between;
      }

      .#{$baseClass}__details {
        display: flex;
      }
    }

    &.#{$baseClass}--mini {
      .#{$baseClass}__contentContainer {
        flex-grow: 1;
      }

      .#{$baseClass}__quantity {
        padding: 0;
        flex-grow: 0;
      }

      .#{$baseClass}__brand {
        font-weight: normal;
      }
    }
  }
}

.#{$baseClass}__content {
  display: flex;
  flex-direction: column;
  flex-grow: 999;
  padding: $_insetM;
  min-width: 0;
}

.#{$baseClass}__images {
  order: 1;
  max-height: 0;
  overflow: hidden;
  flex-shrink: 0;

  transition: max-height $_timeS ease-in-out;
}

.#{$baseClass}__unit {
  opacity: 0;
  position: absolute;
  top: -#{$_stackM};
  right: 16px;
  transform: translate(0, -100%);

  transition: opacity $_timeS ease-in-out;
}

.#{$baseClass}__imagesInner {
  padding-right: $_insetM;
  padding-top: $_insetM;
}

.#{$baseClass}__image {
  position: relative;
  min-width: 100px;

  img {
    padding: $_insetM;
    max-width: 100px;
  }

  &--error {
    .#{$baseClass}__unavailableImage,
    img {
      opacity: 1;
    }

    .#{$baseClass}__productImage {
      opacity: 0;
    }
  }

  &--loaded {
    .#{$baseClass}__productImage,
    img {
      opacity: 1;
    }
  }
}

.#{$baseClass}__productImage,
.#{$baseClass}__unavailableImage {
  position: absolute;
  top: 0;
  left: 0;
}

.#{$baseClass}__productImage {
  opacity: 1;
}

.#{$baseClass}__unavailableImage {
  opacity: 0;
}

.#{$baseClass}__text {
  flex-grow: 1;
  order: 0;
  overflow: hidden;
  flex-basis: 100%;
}

.#{$baseClass}__brand {
  margin-bottom: 4px;

  font-weight: bold;
  color: $_colorMineShaft;
  font-size: $_fontSizeS;
  line-height: 18px;
}

.#{$baseClass}__title {
  margin-bottom: 4px;

  font-weight: bold;
  color: $_colorMineShaft;
  font-size: $_fontSizeM;
  line-height: 20px;
  text-overflow: ellipsis;
  overflow: hidden;
}

.#{$baseClass}__article {
  margin-bottom: 4px;
  font-size: $_fontSizeS;
  line-height: 20px;
  color: $_colorMineShaft;
}

.#{$baseClass}__check {
  display: flex;
  align-items: center;
  margin-bottom: 4px;

  font-size: 12px;
  line-height: 14px;
  color: $_colorSecondary;

  cursor: pointer;
}

.#{$baseClass}__checkIcon {
  position: relative;
  left: -3px;
  width: 16px;
  fill: $_colorSecondary;
}

.#{$baseClass}__numbers {
  flex-grow: 1;
  position: relative;
  flex-shrink: 0;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
  padding: 0 $_insetM $_insetM;
}

.#{$baseClass}__discountPercentage {
  font-size: $_fontSizeS;
  font-weight: bold;
  line-height: 17px;
  height: 19px;
  border-radius: 2px;
  color: $_colorMonza;
  border: 1px $_colorMonza solid;
  justify-content: center;
  display: flex;
  align-items: center;
  margin: 0 0 $_insetSquishS;
  padding: $_insetS $_insetXS;
}

.#{$baseClass}__price {
  padding-bottom: $_insetXS;
  font-size: 12px;

  &--large {
    padding-bottom: $_insetS;
    font-size: $_fontSizeM;
  }

  &--bold {
    font-weight: bold;
  }

  &--beforeDiscount {
    text-decoration: line-through;
  }

  &--kvi {
    color: $_colorBrand;
  }
}

.#{$baseClass}__kvi-label {
  display: inline-block;
  padding: $_insetXS 5px 0 0;
  text-align: center;
  width: 100px;
  height: 20px;
  margin: 0 0 10px 10px;
  position: relative;
  background: $_colorBrand;
  color: $_colorRebrandingWhite;
  font-weight: bold;
  font-size: 12px;
  line-height: 12px;

  &::before {
    content: "";
    position: absolute;
    right: 0;
    bottom: 0;
    width: 0;
    height: 0;
    border-right: 5px solid $_colorRebrandingWhite;
    border-top: 10px solid $_colorBrand;
    border-bottom: 10px solid $_colorBrand;
  }
}


@media #{$_mediaSDown} {
  .#{$baseClass}__discountLabels {
    width: 200px;
    display: flex;
    justify-content: flex-end;
  }

  .#{$baseClass}__mobileDiscountPercentage {
    justify-content: center;
    display: flex;
    align-items: center;
    font-size: $_fontSizeS; 
    font-weight: bold;
    line-height: 17px;
    padding: 0 0 0 5px;
    text-align: center;
    width: 75px;
    height: 20px;
    margin: 0 5px 5px 5px;
    position: relative;
    background: $_colorMonza;
    color: $_colorRebrandingWhite;

    &::before {
      content: "";
      position: absolute;
      right: 0;
      left: 100%;
      bottom: 0;
      width: 0;
      height: 0;
      border-left: 5px solid $_colorMonza;
      border-top: 10px solid transparent;
      border-bottom: 10px solid transparent;
    }
  }

  .#{$baseClass}__mobileKviLabel {
    display: inline-block;
    padding: $_insetXS 0 0 $_insetXS;
    text-align: center;
    width: 100px;
    height: 20px;
    margin: 0 0 10px 0;
    position: relative;
    background: $_colorBrand;
    color: $_colorRebrandingWhite;
    font-weight: bold;
    font-size: 12px;
    line-height: 12px;
  
    &::before {
      content: "";
      position: absolute;
      left: 0;
      bottom: 0;
      border-left: 5px solid $_colorRebrandingWhite;
      border-top: 10px solid transparent;
      border-bottom: 10px solid transparent;
    }
  }
}

@media #{$_mediaMUp} {
  .#{$baseClass} {
    flex-direction: row;

    .#{$baseClass}__content {
      flex-direction: row;
    }

    .#{$baseClass}__images {
      max-height: none;
    }

    .#{$baseClass}__imagesInner {
      padding-top: 0;
    }

    .#{$baseClass}__image {
      order: 0;
    }

    .#{$baseClass}__text {
      order: 1;
    }

    .#{$baseClass}__numbers {
      flex-direction: column;
      align-items: flex-end;
      padding: $_insetM;
    }

    .#{$baseClass}__unit {
      display: block;
      opacity: 1;
      position: static;
      transform: none;
    }

    .#{$baseClass}__quantity {
      padding: $_insetM 0;
    }

    .#{$baseClass}__total {
      width: 100px;
      display: flex;
      flex-direction: column;
      margin-right: 5px;
    }

    .#{$baseClass}__total-isKvi {
      margin-right: 55px !important;
    }

    .#{$baseClass}__check {
      font-size: 14px;
      line-height: 18px;
    }

    .#{$baseClass}__checkIcon {
      width: 18px;
    }
  }
}

@media #{$_mediaLUp},
print {
  .#{$baseClass} {
    .#{$baseClass}__numbers {
      flex-direction: row;
      align-items: flex-start;
    }

    .#{$baseClass}__quantity {
      padding: 0;
      padding-left: $_insetL;
      flex-grow: 1;
      display: flex;
      justify-content: center;
    }
  }
}

@media print {
  .#{$baseClass} {
    page-break-inside: avoid;
  }

  .#{$baseClass}__details {
    display: none;
  }
}

.#{$baseClass}--rebranding {
  .#{$baseClass}__brand, .#{$baseClass}__title, .#{$baseClass}__article {
    color: $_colorRebrandingBlackOut;
  }

  .#{$baseClass}__brand {
    @include rebranding-helper-text-bold;
  }

  .#{$baseClass}__title {
    @include rebranding-lead-bold;
  }

  .#{$baseClass}__article {
    @include rebranding-helper-text-regular;
  }

  .#{$baseClass}__discountPercentage {
    @include rebranding-helper-text-bold;
  }

  .#{$baseClass}__kvi-label {
    background: $_colorRebrandingPrimary;
    position: relative;

    &::before {
      border-top: 11px solid $_colorRebrandingPrimary;
      border-bottom: 11px solid $_colorRebrandingPrimary;
    }
  }

  .#{$baseClass}__price {
    &--kvi {
      color: $_colorRebrandingPrimary;
    }
  }
}
