$baseClass: baseclass('DevError');

.#{$baseClass}__title {
    font-size: $_fontSizeL;
    background-color: $_colorRed;
    padding: $_insetS;
    color: $_colorWhite;
}

.#{$baseClass}__error {
    margin-top: $_stackS;
    background-color: $_colorGallery;
}

.#{$baseClass}__errorHeader {
    background-color: $_colorSecondary;
}

.#{$baseClass}__serviceName {
    opacity: 1;
    font-weight: bold;
    font-size: $_fontSizeS;
    color: $_colorWhite;
    padding: $_insetS;
}

.#{$baseClass}__errorBody {
    padding: $_insetL;

    h3 {
        font-weight: bold;
        margin-bottom: $_stackS;
    }
}

.#{$baseClass}__errorSection {
    margin-top: $_stackL;
}

.#{$baseClass}__errorValue {
    .#{$baseClass}__msgPre {
        border-radius: $_borderRadiusDefault;
        border: solid 2px $_colorRollingStone;
        background-color: $_colorAlto;
        color: $_colorNero;
        padding: $_insetS;
        overflow: auto;

        &.#{$baseClass}__msgPre {
            white-space: initial;
            line-height: 1.5;
        }
    }

    dt {
        font-weight: bold;
        margin-top: $_stackXS;
        margin-bottom: $_stackXS;
    }

    dd {
        padding-bottom: $_stackXS;
        border-bottom: solid 1px $_colorSilver;

        &:last-of-type {
            border-bottom-color: transparent;
        }
    }
}
