$_colorGoldDrop:        #EF7D00;
$_colorOrangePeel:      #F59C00;
$_colorOrangeLight:     #f59d26;
$_colorTango:           #F47C20;
$_colorAmber:           #FFBF00;
$_colorEndeavour: #184187;
$_color24Blue:          #184187;
$_colorToryBlue:        #164194;
$_colorToryBlueDark:    #143771;
$_colorToryBluelight:   #599def;
$_colorKBlueDark:       #113065;
$_colorPictonBlue:      #378AEE;
$_colorSanMarino:       #5877B4;
$_colorJapaneseLaurel:  #00A500;  //#00A400, //#028A00;
$_colorChristi:         #6AB612;
$_colorCarnation:       #F15060;
$_colorMonza:           #D0011B;
$_colorRed:             #F6000A;
$_colorLightRed:        #FADFDE;
$_colorWhite:           #FFFFFF;
$_colorAlabaster:       #FAFAFA;
$_colorWildSand:        #F8F8F8;
$_colorConcrete:        #F3F3F3; //#F1F1F1
$_colorGallery:         #EEEEEE;
$_colorAlt:             #E0E0E0;
$_colorAlto:            #DDDDDD;
$_colorSilver:          #BDBDBD;
$_colorRollingStone:    #707C80;
$_colorCodGrayLight :   #8E8E93;
$_colorBoulder:         #777777;
$_colorLimitedSpruce:   #41565C;
$_colorMineShaft:       #333333;
$_colorNero:            #222222;
$_colorCodGray:         #1A1A1A;
$_colorBlack:           #000000;
$_colorNaturalGrey:     #EDEDED;
$_colorFrenchGrey:      #BCBCC0;
$_colorBlackPrimary:    rgba(#000000, 0.87);
$_colorBlackSecondary:  rgba(#000000, 0.54);
$_colorBlackDisabled:   rgba(#000000, 0.38);
$_colorBlackDecorative: rgba(#000000, 0.12);


$_colorTangerine: #F68200;
$_colorRedRibbon: #D20A3C;
$_colorGuardsmanRed: #D80000;
$_colorChathamsBlue: #184182;
$_colorNevada: #5D696D;

// Rebranding colors

// -> Primary
$_colorRebrandingPrimary: #614FFB;
$_colorRebrandingPrimaryHover: #3D26FF;
$_colorRebrandingPrimaryPressed: #130254;
$_colorRebrandingPrimaryDisabled: #B8AFFF;

// -> Secondary
$_colorRebrandingSecondary: #130254;
$_colorRebrandingSecondaryEmphasize: #66E8BD;
$_colorRebrandingSecondaryBright: #FB5F24;

// -> Neutral
$_colorRebrandingWhite: #FFFFFF;
$_colorRebrandingAlabaster: #F9F9F9;
$_colorRebrandingGallery: #EDEDED;
$_colorRebrandingFrenchGray: #BCBCC0;
$_colorRebrandingManatee: #8E8E93;
$_colorRebrandingAbbey: #5B5A5A;
$_colorRebrandingBlackOut: #222222;
$_colorRebrandingBlack: #000000;

// -> Semantic
$_colorRebrandingSemanticBlue: #184187;
$_colorRebrandingSemanticLightBlue: #E7EBF3;
$_colorRebrandingSemanticGreen: #37D19E;
$_colorRebrandingSemanticLightGreen: #CDF3E7;
$_colorRebrandingSemanticYellow: #FFBF00;
$_colorRebrandingSemanticLightYellow: #FFF7E4;
$_colorRebrandingSemanticRed: #D0011B;
$_colorRebrandingSemanticLightRed: #FAE5E7;

// Tirendo theme colors
$_colorTirendoBlackout: #3e3e3e;
$_colorTirendoOrange: #ff9000;
$_colorTirendoOrangeLight: #ffc817;
$_colorTirendoGray: #cdcdcd;
$_colorTirendoConcreteGray: #cad2d3;
