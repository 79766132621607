$baseClass: baseclass('ButtonBar');

.#{$baseClass} {
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
    align-items: center;
    padding: $_insetS;
    border-top: 1px solid $_colorBlackDecorative;
}

.#{$baseClass}__button {
    margin: $_stackS;
    flex-grow: 1;

    &:last-child {
        flex-grow: 999;
    }
}

@media #{$_mediaSDown} {
    .#{$baseClass}__button {
        min-width: calc(50% - $_stackM);
        flex-grow: 1;
    }
}
