$baseClass: baseclass('PaymentMethodForm');

.#{$baseClass} {
  margin: -$_stackM;
}

.#{$baseClass}__content {
  padding: $_insetM;
  margin-bottom: -24px;
}

.#{$baseClass}__message {
  margin-bottom: $_stackM;

  font-size: $_fontSizeM;
  color: $_colorMineShaft;
  line-height: 26px;
}

.#{$baseClass}__afterpaySection {
  padding: $_insetM;
  border: 1px solid $_colorAlto;
  margin-top: -1px;

  &--hidden {
    display: none;
  }
}

.#{$baseClass}__afterpay-selection-2 {
  padding: 16px 0 0 17%;
}

.#{$baseClass}__inputContainer {
  display: flex;
  justify-content: space-between;
}

.#{$baseClass}__paymentInput {
  flex-basis: 49%;
}

@media #{$_mediaMDown} {
  .#{$baseClass}__afterpay-selection-2 {
    padding-left: 0;
  }

  .#{$baseClass}__inputContainer {
    flex-direction: column;
  }
}
