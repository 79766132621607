$baseClass: baseclass('ProductDetails');

.#{$baseClass} {
    width: 100%;
    background-color: $_colorWhite;

    &--noBrandLogo {
        .#{$baseClass}__brandLogo {
            display: none;
        }
    }
}

.#{$baseClass}__section {
    position: relative;
    padding-top: 12px;

    &--gray {
        background: $_colorWildSand;

        &::before {
            display: block;
            content: ' ';
            background: $_colorWildSand;
            width: 100%;
            left: -100%;
            position: absolute;
            height: 100%;
            top: 0;
        }

        &::after {
            display: block;
            content: ' ';
            background: $_colorWildSand;
            width: 100%;
            left: 100%;
            position: absolute;
            height: 100%;
            top: 0;
        }

        @media #{$_mediaLDown} {
            background: $_colorConcrete;
        }
    }
}

.#{$baseClass}__sectionInner {
    display: flex;
    flex-wrap: wrap;

    border-bottom: 1px solid $_colorAlto;

    @media #{$_mediaLDown} {
        padding: 0;
    }

    &--flat {
        border-bottom: 0;
    }

    &--title {
        border-bottom: 0;

        @media #{$_mediaLDown} {
            @include pagePadding;
        }
    }

    &--attributes {
        flex-wrap: nowrap;
        padding-bottom: 20px;

        .#{$baseClass}__column {
            margin-top: 23px;
            flex-basis: 24%;

            &.#{$baseClass}__column--imageContainer {
                flex-basis: 52%;
                overflow: hidden;
                margin-top: 0;
                padding: 0 $_insetM;
            }

            @media #{$_mediaMDown} {
                &--cartOptions {
                    margin-top: 0;
                }
            }
        }
    }

    &--information {
        border-bottom: none;

        .#{$baseClass}__column {
            flex-basis: 50%;
            margin-top: 24px;
            margin-bottom: 20px;

            &:nth-of-type(2n+1) {
                padding-right: 20px;
            }

            &:nth-of-type(2n) {
                padding-left: 20px;
            }
        }
    }
}

.#{$baseClass}__column {
    flex-grow: 1;
    background: $_colorWildSand;

    &.#{$baseClass}__column--imageContainer.#{$baseClass}__column--collapseLeft {
        @media #{$_mediaLUp} {
            padding-left: 0;
        }
    }
}

.#{$baseClass}__title {
    font-weight: bold;
    font-size: 28px;
    line-height: 32px;
    color: $_colorChathamsBlue;

    margin-bottom: 10px;

    &--com {
        color: $_colorBrand;
    }
}

.#{$baseClass}__sectionTitle {
    margin-top: $_stackS;
    margin-bottom: 24px;
    flex-basis: 100%;
    display: flex;
    align-items: center;

    font-size: 20px;
    font-weight: bold;
    line-height: 20px;
    color: $_colorNero;
}

.#{$baseClass}__sectionTitleIcon {
    fill: $_colorBrand;
    margin-right: $_stackS;

    transform: rotate(0);
    transform-origin: center;
    transition: transform $_timeS ease-in-out;
}

.#{$baseClass}__mobileAccordionBody {
    overflow: hidden;

    transition: max-height $_timeS ease-in-out;
}

.#{$baseClass}__productId {
    color: $_colorRollingStone;
    font-size: 14px;
    line-height: 17px;
    padding-bottom: 6px;

    @media #{$_mediaMDown} {
        font-size: $_fontSizeS;
        display: flex;
        flex-flow: row nowrap;
        justify-content: flex-start;
        align-items: center;
    }
}

.#{$baseClass}__product-description {
    color: $_colorMineShaft;
    font-size: $_fontSizeM;
    line-height: 26px;
    margin-bottom: $_stackL;
}

.#{$baseClass}__quantity {
    margin-bottom: $_stackM;
}

.#{$baseClass}__button {
    & + & {
        margin-top: 10px;
    }
}

.#{$baseClass}__buttonBar {
    margin-bottom: $_stackM;
    min-width: 300px;

    @media #{$_mediaMDown} {
        display: flex;
        flex-flow: row nowrap;

        .#{$baseClass}__button {
            flex-shrink: 1;
            margin-top: 0;
        }

        .#{$baseClass}__addToCartButton {
            margin-right: $_stackM;
        }
    }

    @media #{$_mediaSDown} {
        margin: $_stackS 0;
        padding-bottom: $_insetM;
        border-bottom: 1px solid $_colorAlt;
    }
}

.#{$baseClass}__articleType,
.#{$baseClass}__setProducts,
.#{$baseClass}__documents {
    margin-bottom: 20px;
    flex-basis: 100%;
}

.#{$baseClass}__setProductBody {
    display: flex;
    flex-wrap: wrap;
}

.#{$baseClass}__setProductImage {
    flex-basis: 15%;
    margin-right: 2%;
}

.#{$baseClass}__setProductInformation {
    flex-basis: 83%;
}

.#{$baseClass}__setProductTrigger {
    margin: 20px 0;
    cursor: pointer;
    color: $_colorSecondary;
    font-size: 14px;
    font-weight: bold;
    line-height: 20px;
}

.#{$baseClass}__footerContainer {
    position: fixed;
    bottom: -1px;
    z-index: 2;
    width: 100%;

    transition: transform $_timeS ease-in-out;
    transform: translateY(150%);

    &--visible {
        transform: translateY(0);
    }
}

@media #{$_mediaLDown} {
    .#{$baseClass}__section--gray {
        padding-top: 0;
    }

    .#{$baseClass}__sectionInner {
        &--attributes {
            flex-wrap: wrap;
            justify-content: center;
        }

        .#{$baseClass}__column {
            &.#{$baseClass}__column--carAttributes {
                order: 2;
                border-bottom: 1px solid $_colorAlt;
                padding: $_insetM;
                margin-top: 5px;
            }

            &.#{$baseClass}__column--imageContainer {
                order: 0;
                padding: $_insetM $_insetS $_insetM $_insetM;
                border-bottom: 1px solid $_colorAlt;
                margin-bottom: 0;
            }

            &.#{$baseClass}__column--cartOptions {
                padding: 36px $_insetM $_insetS $_insetS;
                margin-top: 0;
                border-bottom: 1px solid $_colorAlt;
            }
        }

        &--information {
            padding: 0 $_stackM;
            margin-top: 5px;
            background: $_colorWildSand;
        }
    }
}

@media #{$_mediaMDown} {
    .#{$baseClass}__sectionInner {
        &--attributes {
            border-bottom: none;
            padding-bottom: 0;

            .#{$baseClass}__column {
                flex-basis: 100%;
                padding-right: 0;
                padding-left: 0;
                margin-bottom: 10px;
            }

            .#{$baseClass}__column.#{$baseClass}__column--cartOptions {
                padding: 16px;
            }

            .#{$baseClass}__column.#{$baseClass}__column--imageContainer {
                border-bottom: none;
            }
        }

        .#{$baseClass}__column.#{$baseClass}__column--carAttributes {
            padding-top: $_insetM;
        }

        &--information {
            padding-right: 0;
            padding-left: 0;

            .#{$baseClass}__column {
                flex-basis: 100%;
                padding-right: 0;
                padding-left: 0;
                margin-bottom: 10px;
            }
        }
    }

    .#{$baseClass}__articleType,
    .#{$baseClass}__setProducts {
        padding: 0 $_insetS;
    }

    .#{$baseClass}__mobileAccordionToggle {
        cursor: pointer;

        color: $_colorCodGray;
        font-size: 14px;
        font-weight: bold;
        line-height: 14px;
        padding: 20px 0;
        margin: 0;
    }

    .#{$baseClass}__mobileAccordionBody {
        max-height: 0;
    }

    .#{$baseClass}__section {
        .#{$baseClass}__column.#{$baseClass}__mobileAccordionContainer {
            margin-top: 0;
            margin-bottom: 0;
            padding-left: 0;
        }
    }

    .#{$baseClass}__mobileAccordionContainer {
        border-bottom: 1px solid $_colorAlt;
    }

    .#{$baseClass}__mobileAccordionBodyInner {
        padding: 0 $_stackS;
    }

    .#{$baseClass}__mobileAccordionContainer--open {
        border-bottom: 0;

        .#{$baseClass}__sectionTitleIcon {
            transform: rotate(-180deg);
        }

        &::after {
            display: block;
            content: '';
            height: 5px;
            width: 100%;
            background: $_colorConcrete;
            border-top: 1px solid $_colorAlt;
        }
    }

    .#{$baseClass}__title {
        font-size: 24px;
        line-height: 26px;
    }

    .#{$baseClass}__prices {
        flex-basis: 100%;
    }

    .#{$baseClass}__quantity {
        margin-right: $_stackM;
    }
}

@media #{$_mediaSDown} {
    .#{$baseClass}__setProductImage {
        flex-basis: 100%;
        margin-right: 0;
        margin-bottom: 10px;
    }

    .#{$baseClass}__title {
        font-size: 18px;
        line-height: 26px;
    }
}

@media #{$_mediaLUp} {
    .#{$baseClass}__sectionTitleIcon {
        display: none;
    }

    .#{$baseClass}__mobileAccordionBody {
        max-height: none !important;
    }
}

.#{$baseClass}__brandImage--title {
    margin-right: $_stackS;

    .#{$baseClass}__brandLogo {
        max-width: 110px;
    }

    @media #{$_mediaSDown} {
        .#{$baseClass}__productId {
            padding: 14px 0;
        }

        .#{$baseClass}__brandLogo {
            max-width: 90px;
            padding-right: $_stackM;
        }
    }
}
