$baseClass: baseclass('PdpCarBar');

.#{$baseClass} {
    &__body {
        padding: $_insetM 0 $_insetS 0;
        display: flex;
        flex-wrap: wrap;
        align-items: center;

        font-size: 12px;
        line-height: 16px;
    }

    &__icon {
        display: flex;
        flex-shrink: 0;
        width: 22px;
        height: 22px;
        margin-right: $_stackS;
    }

    &__label {
        font-size: 14px;
        line-height: 16px;
        font-weight: bold;
    }

    &--selected_car_ok .#{$baseClass}__label {
        color: $_colorJapaneseLaurel;
    }

    &--not_selected .#{$baseClass}__label,
    &--selected_car_not_ok .#{$baseClass}__label {
        color: $_colorMonza;
    }

    &__vehicle {
        color: $_colorNevada;
        font-size: 12px;
        line-height: 14px;
        margin-right: $_stackS;
        margin-bottom: $_insetS;
    }

    &__textContainer {
        display: flex;
        align-items: center;
        margin-right: $_stackS;
        margin-bottom: $_insetS;
    }

    &__buttonContainer {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-bottom: $_insetS;
    }

    &--hide-button &__buttonContainer {
        display: none;
    }
}

@media #{$_mediaSDown} {
    .#{$baseClass}__textContainer {
        margin-right: 0;
    }

    .#{$baseClass}__buttonContainer {
        width: 100%;
    }

    .#{$baseClass}__button {
        flex-basis: 100%;
        margin-right: 0;
    }
}

