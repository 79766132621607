$baseClass: baseclass('Breadcrumbs');

.#{$baseClass} {
    padding: $_insetM 0 0;
    transition: opacity $_timeXS ease-in;
    opacity: 0;

    &--loaded {
        opacity: 1;
    }

    @media #{$_mediaMDown} {
        margin: 0 #{-$_stackM};
        padding: $_insetM $_insetM 0;
    }
}

.#{$baseClass}__goBack {
    cursor: pointer;
    max-width: 200px;

    @media #{$_mediaMUp} {
        margin-right: 0;
    }

    &--link {
        padding: 0 $_insetS;
        padding-left: 0;
        color: $_colorRollingStone;
        font-size: 14px;
        white-space: nowrap;
        line-height: 14px;

        &::after {
            content: '|';
            display: inline-block;
            margin: 0 0 0 4px;
            font-size: 17px;
        }
    }

    .#{$baseClass}__backChevron {
        display: inline;
        width: 15px;
        height: 17px;
        vertical-align: top;

        path {
            fill: $_colorRollingStone;
        }
    }
}

.#{$baseClass}__breadcrumbs {
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    strong {
        font-weight: bold;
    }

    &--collapsed {
        .#{$baseClass}__breadcrumb--hidden,
        .#{$baseClass}__iconSvg--hidden {
            @include u-hidden-md-down;
        }
    }

    &--expanded {
        .#{$baseClass}__expandTrigger {
            @include u-hidden-md-down;
        }
    }
}

.#{$baseClass}__expandTrigger {
    color: $_colorRollingStone;
    transition: opacity $_timeS ease-in-out, width $_timeS $_timeS ease-in;
    width: auto;
    display: none;
    cursor: pointer;

    @media #{$_mediaMDown} {
        display: inline;
    }
}

.#{$baseClass}__breadcrumb {
    padding: 5px 7px;
    font-size: 14px;
    line-height: 14px;
    color: $_colorRollingStone;
    white-space: nowrap;

    &--home {
        transform: translateY(-2px);

        path {
            fill: $_colorRollingStone;
        }
    }

    &[href]:hover {
        color: $_colorChathamsBlue;
        text-decoration: underline;

        path {
            fill: $_colorChathamsBlue;
        }
    }
}

.#{$baseClass}__home {
    margin-left: -5px;
}

.#{$baseClass}__iconSvg {
    flex-shrink: 0;
    width: 13px;
    height: 13px;

    path {
        fill: $_colorRollingStone;
    }
}

.#{$baseClass}__caption {
    @include u-hidden-md-down;

    padding: 5px 7px 5px 0;
    color: $_colorRollingStone;
    font-size: 14px;
    line-height: 14px;
}
