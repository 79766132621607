$baseClass: baseclass('SocialMediaList');

.#{$baseClass} {
    padding: 25px $_insetM;

    &--magazine {
        .#{$baseClass}__headline {
            text-align: left;
        }

        .#{$baseClass}__socials {
            justify-content: flex-start;
        }

        padding: 0;
    }
}

.#{$baseClass}__headline {
    margin-bottom: $_stackS;
    text-align: right;
    white-space: nowrap;
    font-weight: bold;
    font-size: $_fontSizeM;
    color: $_colorMineShaft;
    line-height: 30px;
}

.#{$baseClass}__socials {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.#{$baseClass}__social {
    & + & {
        margin-left: $_stackS;
    }
}
