$_timeXS:    0.1s;
$_timeS:     0.3s;
$_timeM:     0.6s;
$_timeL:     1s;
$_timeXL:    2s;


@keyframes linearRotationKeyframes {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
