$baseClass: baseclass('AddressTile');

.#{$baseClass}__trigger {
    padding: 0;

    color: $_colorRedRibbon;
    text-decoration: underline;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    cursor: pointer;
    display: inline-block;
}

.#{$baseClass}__error {
    color: $_colorRedRibbon;
    font-size: 12px;
    font-weight: bold;
    line-height: 20px;
    padding-top: 8px;
    display: flex;
    align-items: center;
}

&.#{$baseClass}__svg {
    margin-right: $_insetS;
    min-width: 18px;
}

.#{$baseClass}__inactive {
    opacity: 0.5;
}

.#{$baseClass}[data-is-b2b="true"] {
    [class$="__actions"] {
        display: none;
    }
}

.#{$baseClass}--rebranding {
    .#{$baseClass}__trigger {
        @include rebranding-para-bold;
    }

    .#{$baseClass}__error{
        @include rebranding-helper-text-bold;
    }
}
