@mixin u-visible-print-block {
    @media print {
        display: block !important;
        height: auto !important;
        min-height: auto !important;
    }
}

@mixin u-visible-print-flex {
    @media print {
        display: flex !important;
        height: auto !important;
        min-height: auto !important;
    }
}

@mixin u-visible-print-inline {
    @media print {
        display: inline !important;
        height: auto !important;
        min-height: auto !important;
    }
}

@mixin u-visible-print-inline-block {
    @media print {
        display: inline-block !important;
        height: auto !important;
        min-height: auto !important;
    }
}

@mixin u-hidden-print {
    @media print {
        display: none !important;
        width: 0 !important;
        height: 0 !important;
        min-height: 0 !important;
        overflow: hidden;
    }
}

.u-visible-print-block {
    @include u-visible-print-block;
}

.u-visible-print-flex {
    @include u-visible-print-flex;
}

.u-visible-print-inline {
    @include u-visible-print-inline;
}

.u-visible-print-inline-block {
    @include u-visible-print-inline-block;
}

.u-hidden-print {
    @include u-hidden-print;
}
