$baseClass: baseclass('OrderList');

.#{$baseClass} {
    background: $_colorWhite;
}

.#{$baseClass}__empty {
    padding: $_insetM;
    border: 1px solid $_colorAlto;
}
