$baseClass: baseclass('Password');

.#{$baseClass}__icon {
    pointer-events: all !important;
    cursor: pointer;
}

.#{$baseClass}__icon--password {
    display: none;
}

.#{$baseClass}__input {
    flex-grow: 1;
}

.#{$baseClass}--text {
    .#{$baseClass}__icon--text {
        display: none;
    }

    .#{$baseClass}__icon--password {
        display: block;
    }
}
