$baseClass: baseclass('InlineFormField');

.#{$baseClass} {
    display: flex;
    flex-wrap: wrap;
    width: 100%;

    &__inner {
        display: flex;
        flex-grow: 999;
        box-shadow: inset 0 0 0 500px $_colorWhite;
        border-radius: 3px;
    }

    &__input {
        flex-grow: 999;
    }

    &__button {
        flex-shrink: 1;
        border-radius: 0 $_borderRadiusDefault $_borderRadiusDefault 0;

        cursor: pointer;

        transition: color $_timeS ease-in-out, border $_timeS ease-in-out;
    }

    &--disabled {
        .#{$baseClass}__button {
            pointer-events: none;
            color: $_colorBlackDisabled;

            &.#{$baseClass}__icon {
                fill: $_colorBlackDisabled;
            }
        }
    }

    &--label {
        .#{$baseClass}__button {
            margin-top: 9px;
        }
    }

    &--focused:not(&--error),
    &--focused:not(&--disabled) {
        .#{$baseClass}__button {
            border: 1px solid $_colorChathamsBlue;
        }
    }

    &--error,
    &--error.#{$baseClass}--focused {
        .#{$baseClass}__error {
            color: $_colorGuardsmanRed;
            opacity: 1;
        }

        .#{$baseClass}__button {
            border: 1px solid $_colorGuardsmanRed;
        }
    }
}

.#{$baseClass}__icon {
    position: absolute;
    z-index: 1;
    top: 50%;
    transform: translate(0, -50%);
    left: 10px;

    path {
        fill: $_colorRollingStone;
    }
}

.#{$baseClass}__message,
.#{$baseClass}__error {
    flex-basis: 100%;
    color: $_colorMineShaft;
    font-size: $_fontSizeS;
    line-height: 16px;
    padding-left: 17px;
}

.#{$baseClass}__error {
    opacity: 0;

    transition: opacity $_timeS ease-in-out;
}
