$baseClass: baseclass('CartAmounts');

.#{$baseClass} {
  width: 100%;
  background: $_colorWhite;
  overflow: hidden;

  @media #{$_mediaLUp} {
    min-width: 300px;
    width: 300px;
  }
}

.#{$baseClass}__table {
  width: 100%;
  color: $_colorNero;
}

.#{$baseClass}__row {
  &--bold {
    .#{$baseClass}__cell {
      padding: $_insetL 0 $_insetS;

      font-weight: bold;
      font-size: 18px;
    }
  }

  &--large,
  &--bold {
    .#{$baseClass}__cell {
      font-size: 20px;
    }
  }

  &--discounts {
    .#{$baseClass}__cell {
      padding-top: $_insetM;
    }
  }
}

.#{$baseClass}--discountsPrice {
  text-decoration: line-through;
  font-size: 12px;
}

.#{$baseClass}__cell {
  padding-top: 8px;
  padding-bottom: 8px;
  line-height: 1.2em;
  font-size: 16px;
  text-align: left;

  & + & {
    padding-left: $_insetS;
  }
}

.#{$baseClass}__label {
  padding-bottom: $_insetXS;

  font-weight: bold;
  line-height: 22px;
}

.#{$baseClass}__smalls {
  float: left;
  color: $_colorMineShaft;
  line-height: 16px;
  text-align: left;
}

.#{$baseClass}__small {
  padding: 1px 0 1px 0;
}

.#{$baseClass}__text,
.#{$baseClass}__smalls {
  font-size: 12px;
  width: 100%;
}

.#{$baseClass}__help {
  display: inline-block;
  vertical-align: middle;
  margin-top: -2px;
  margin-right: $_stackXS;
  cursor: pointer;
}

.#{$baseClass}__helpIcon {
  width: 16px;
  height: 16px;
}

.#{$baseClass}__progressBarContainer {
  padding-top: 15px;
}

.#{$baseClass}__freeShippingMessage {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: $_colorRollingStone;
  font-size: 13px;
  font-weight: bold;
  margin-left: 7px;
  margin-bottom: 4px;
  text-align: right;

  &::before {
    padding-right: 10px;
    content: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMiIgaGVpZ2h0PSIyMiIgdmlld0JveD0iMCAwIDIyIDIyIj48cGF0aCBmaWxsPSIjMTg0Mjg3IiBkPSJNMTEgMGExMSAxMSAwIDEgMCAwIDIyIDExIDExIDAgMCAwIDAtMjJ6TTkgMTYuN0w0IDExLjhsMS43LTEuNkw5IDEzLjRsNy4yLTcuMkwxOCA3LjhsLTkgOC45eiIvPjwvc3ZnPg==');
  }
}

.#{$baseClass}__dropshipment-cell {
  padding: 16px 0;
}

.#{$baseClass}__dropshipment-container {
  position: relative;
  background: #E7EBF3;
  border-radius: 3px;
  font-size: 16px;
  line-height: 22px;
  padding: 10px;
}

.#{$baseClass}__dropshipment-icon {
  position: absolute;
  top: 10px;
  left: 10px;
  height: 20px;
  width: 20px;

  path {
    fill: $_color24Blue;
  }
}

.#{$baseClass}__dropshipment-message {
  max-width: 350px;
  display: block;
  margin-left: 30px;
  color: $_colorNero;
  font-size: $_fontSizeM;
  line-height: 22px;
}


@media #{$_mediaMUp} {
  .#{$baseClass}__info {
    justify-content: flex-end;
  }

  .#{$baseClass}__smalls {
    font-size: $_fontSizeS;
    width: 100%;
  }
}

.#{$baseClass}--rebranding {
  th.#{$baseClass}__cell {
    color: $_colorRebrandingBlackOut;
    @include rebranding-h4--regular;
  }

  .#{$baseClass}__row--bold {
    th.#{$baseClass}__cell {
      @include rebranding-h4;
    }
  }

  .#{$baseClass}__small{
    color: $_colorRebrandingBlackOut;
    @include rebranding-helper-text-regular;
  }

  .#{$baseClass}__dropshipment-message{
    color: $_colorRebrandingBlackOut;
    @include rebranding-h5--regular;
  }
}
