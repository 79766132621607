$baseClass: baseclass('CarpageDropdown');

.#{$baseClass} {
    position: relative;

    &--expanded {
        .#{$baseClass}__buttonIcon svg {
            transform: rotate(180deg);
        }

        .#{$baseClass}__mobileValues {
            display: none;
        }

        .#{$baseClass}__accordion {
            pointer-events: all;
            opacity: 1;
            transform: translateY(0);
            -webkit-overflow-scrolling-: touch;
        }

        .#{$baseClass}__buttonText {
            white-space: normal;
            text-overflow: ellipsis;
            max-height: 105px;
        }
    }

    &--focused {
        outline-style: auto;
        outline-color: $_colorBrand;
        z-index: 1;
    }

    &--disabled {
        .#{$baseClass}__button {
            cursor: default;
        }

        .#{$baseClass}__icon {
            fill: $_colorAlto;
        }

        .#{$baseClass}__buttonText {
            color: $_colorAlto;
        }
    }
}

.#{$baseClass}__dropdownHeader {
    .#{$baseClass}__inner {
        position: relative;
        background-color: $_colorWhite;
    }

    .#{$baseClass}__buttonIcon {
        border-left: 1px solid $_colorAlto;
    }
}

.#{$baseClass}__button {
    display: flex;
    border: 1px solid $_colorAlto;
    border-radius: $_borderRadiusDefault;
    background: $_colorWhite;
    transition: all $_timeS;
    cursor: pointer;

    .#{$baseClass}--label & {
        margin-top: 9px;
    }
}

.#{$baseClass}__buttonText {
    padding: 12px $_stackM;
    flex-grow: 1;
    font-size: 16px;
    color: $_colorMineShaft;
    line-height: 24px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    min-height: 48px;
}

.#{$baseClass}__buttonIcon {
    flex-shrink: 0;
    width: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: auto;
}

.#{$baseClass}__icon {
    fill: $_colorBrand;
    transition: fill $_timeS ease-in-out;

    .#{$baseClass}--focused & {
        fill: $_colorBrand;
    }
}

.#{$baseClass}__accordion {
    pointer-events: none;
    height: auto;
    width: 100%;
    top: 100%;
    z-index: 5;
    border-radius: 0 0 $_borderRadiusDefault $_borderRadiusDefault;
    border: solid 1px $_colorAlt;
    background-color: $_colorWhite;
    opacity: 0;
    transform: translateY(-50px);
    position: absolute;
    transition: transform $_timeXS ease-in, opacity $_timeXS ease-in;

    @media #{$_mediaMDown} {
        position: static;
        transform: none;
    }
}

.#{$baseClass}__accordionFrame {
    padding: $_insetM;
    padding-bottom: 0;
}

.#{$baseClass}__optionsWrap {
    padding: $_insetS;

    @media #{$_mediaMDown} {
        max-height: calc(100vh - 70px);
        overflow: scroll;
        padding-bottom: $_insetL;
    }
}

.#{$baseClass}__choicesFrame {
    max-height: 400px;
    overflow: scroll;

    @media #{$_mediaLUp} {
        @include fancyScroll;
    }

    @media #{$_mediaMDown} {
        max-height: none;
    }
}

.#{$baseClass}__choiceLabel {
    text-transform: uppercase;
    background: $_colorWildSand;
    color: $_colorSecondary;
    min-height: 30px;
    line-height: 30px;
    padding: 0 $_insetS;
    display: block;
    font-weight: bold;
    font-size: 14px;

    &--hidden {
        display: none;
    }
}

.#{$baseClass}__choice {
    min-height: 30px;
    line-height: 30px;
    border-bottom: solid 1px $_colorConcrete;
    padding: 0 $_insetS;

    &--selected {
        background-color: $_colorBrand;
        color: $_colorWhite;

        &:hover {
            background-color: $_colorWildSand;
            color: $_colorBlack;
        }
    }

    &:hover {
        background-color: $_colorWildSand;
    }

    &--hidden {
        display: none;
    }

    &:last-of-type {
        border-bottom-color: transparent;
    }

    a {
        display: block;
        height: 100%;
    }

    @media #{$_mediaMDown} {
        height: 40px;
        line-height: 40px;
    }
}

.#{$baseClass}__searchBar {
    border: 1px solid $_colorAlto;
    border-radius: $_borderRadiusDefault;
    padding: $_insetS $_insetM;
    margin-bottom: $_stackS;

    @media #{$_mediaMDown} {
        margin-right: $_stackXS;
    }

    .#{$baseClass}__searchIcon {
        margin-right: 10px;
        flex-shrink: 0;

        path {
            fill: $_colorRollingStone;
        }
    }

    .#{$baseClass}__searchInput {
        width: 100%;
        height: 100%;
    }

    .#{$baseClass}__searchBarTitle {
        color: $_colorRollingStone;
    }
}

.#{$baseClass}__searchBarPosition {
    display: flex;
    flex-flow: row nowrap;
}
