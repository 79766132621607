$baseClass: baseclass('SliderContainer');

@mixin full-page {
    position: fixed;
    height: 100vh;
    width: 100vw;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    transform: translateX(100%);
    display: flex;
    flex-flow: column nowrap;
    z-index: 15;
    transition: transform $_timeS ease-in-out;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
}

.#{$baseClass}__view {
    @include full-page;

    &--visible {
        transform: translateX(0) !important;
    }
}

.#{$baseClass}__viewResponsive {
    &--u-hidden-lg-up {
        @media #{$_mediaMDown} {
            @include full-page;
        }

        @media #{$_mediaLUp} {
            position: static;
        }
    }
}

.#{$baseClass}__navigation {
    display: flex;
    background-color: $_colorConcrete;
    align-items: center;
    height: 60px;

    &.#{$baseClass}__white {
        background-color: $_colorWhite;
    }
}

.#{$baseClass}__backButton {
    height: 60px;
    width: 60px;

    svg {
        width: 40px;
        height: 40px;
    }
}

.#{$baseClass}__navigationTitleContainer {
    display: flex;
    align-items: center;
    flex-grow: 1;
    justify-content: flex-start;
    padding-right: $_insetM;
}

.#{$baseClass}__navigationTitle {
    display: flex;
    align-items: center;
    font-size: 18px;
    font-weight: bold;
    color: $_colorMineShaft;
    padding-left: $_insetM;

    &.#{$baseClass}__blue {
        color: $_colorChathamsBlue;
    }
}

.#{$baseClass}__close {
    fill: $_colorRollingStone;
    margin-left: auto;
    cursor: pointer;
}

.#{$baseClass}__icon {
    margin-right: $_stackS;
    fill: $_colorBoulder;
    max-height: 30px;
    max-width: 30px;
}

.#{$baseClass}__parentTitle {
    font-size: 14px;
    font-weight: normal;
    margin-bottom: 4px;
}

.#{$baseClass}__viewHeader {
    flex: 0 0;
}

.#{$baseClass}__viewBody {
    flex: 1 0;
    position: relative;
    background-color: $_colorWhite;

    &--transparent {
        background-color: transparent;
    }
}
