$baseClass: baseclass('PaymentForm');

.#{$baseClass} {
    background: $_colorWhite;
    overflow: hidden;
}

.#{$baseClass}__item {
    cursor: pointer;

    & + & {
        border-top: 1px solid $_colorBlackDecorative;
    }
}

.#{$baseClass}__itemHead {
    width: 100%;
    padding: $_insetM;

    font-weight: bold;
    font-size: $_fontSizeM;
    color: $_colorBrand;
    line-height: 26px;
}

.#{$baseClass}__helpImage {
    width: 100%;
    max-width: 327px;
    margin: 0 auto;
    display: block;
}
