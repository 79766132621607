$baseClass: baseclass('ClipboardForm');

.#{$baseClass} {
    width: calc(100% + 10px);
    background: $_colorWhite;
    display: flex;
    flex-wrap: wrap;
    margin: 0 -5px;
}

.#{$baseClass}__title {
    width: 100%;
    margin: 0 5px $_stackS;

    font-weight: bold;
    font-size: $_fontSizeS;
    line-height: 16px;
}

.#{$baseClass}__clipboard {
    display: flex;
    margin: 5px;
    flex-grow: 99;
}

.#{$baseClass}__input {
    flex-grow: 1;
    border: 1px solid $_colorAlto !important;
    border-right: none !important;
    border-top-left-radius: $_borderRadiusDefault !important;
    border-bottom-left-radius: $_borderRadiusDefault !important;
    background: $_colorGallery !important;
    padding: $_insetS $_insetM;

    overflow: hidden;
    text-overflow: ellipsis;
}

.#{$baseClass}__buttons {
    display: flex;
    flex-shrink: 0;
    flex-grow: 1;
}

.#{$baseClass}__copy {
    flex-shrink: 0;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.#{$baseClass}__emailLink,
.#{$baseClass}__whatsappLink {
    margin: 5px;
    flex-grow: 1;
}
