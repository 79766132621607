$baseClass: baseclass('Accordion');

.#{$baseClass} {
  &--noBorder {
    .#{$baseClass}__items {
      border: none;
    }
  }
}

.#{$baseClass}__items {
  background: $_colorWhite;
  border: 1px solid $_colorAlto;
}

.#{$baseClass}__item {
  & + & {
    border-top: 1px solid $_colorAlto;
  }

  &--active {
    .#{$baseClass}__head {
      color: $_colorBlack;
    }

    .#{$baseClass}__body {
      opacity: 1;

      transition: max-height $_timeS ease-in-out, opacity $_timeS ease-in-out $_timeS;
    }
  }

  &--disabled {
    .#{$baseClass}__head {
      color: $_colorRollingStone;
      cursor: not-allowed;

      * {
        color: $_colorRollingStone;
        cursor: not-allowed;
      }
    }
  }
}

.#{$baseClass}__head {
  padding: $_insetM;
  position: relative;
  cursor: pointer;

  line-height: 20px;
  color: $_colorRollingStone;
}

.#{$baseClass}__body {
  max-height: 0;
  opacity: 0;
  overflow: hidden;
  line-height: 20px;

  transition: max-height $_timeS ease-in-out $_timeS, opacity $_timeS ease-in-out;
}

.#{$baseClass}__bodyInner {
  border-top: 1px solid $_colorAlto;
  padding: $_insetM;
}

.#{$baseClass}__message {
  font-size: 14px;
  padding-left: 28px;
  display: flex;
  width: 100%;
  line-height: 25px;
}

.#{$baseClass}__messageIcon {
  filter: grayscale(1);
  opacity: 0.6;
  margin-right: 5px;
}

.#{$baseClass}--rebranding {
  .#{$baseClass}__message {
    @include rebranding-para-small-regular;
  }
}

.#{$baseClass}__dropshipment {
  border-radius: 3px;
  background-color: #FFF7E4;
  padding: 5px 5px 5px 25px;
  margin-top: 10px;
  height: 40px;
  font-size: 14px;
  display: flex;
  width: 100%;
  line-height: 25px;
}

.#{$baseClass}__dropshipmentIcon {
  margin-right: 5px;
}

.#{$baseClass}__dropshipmentMessage {
  color: #222222 !important;
}