$baseClass: baseclass('HeaderAccountButton');

.#{$baseClass} {
    position: relative;

    &--visible {
        .#{$baseClass}__widget {
            pointer-events: all;
            opacity: 1;
            transform: translate(0, 0);
        }
    }
}

.#{$baseClass}__button {
    position: relative;
    white-space: nowrap;
}

.#{$baseClass}__widget {
    opacity: 0;
    pointer-events: none;
    transform: translate(0, -15px);
    transition: all 0.1s ease-in-out;
    width: 320px;
    background: $_colorWhite;
    position: absolute;
    z-index: 6;
    top: 48px;
    left: 0;
    box-shadow: 0 10px 18px 0 rgba(51, 51, 51, 0.3);

    &::before {
        content: '';
        display: block;
        background: $_colorWhite;
        position: absolute;
        top: -12px;
        left: 0;
        height: 12px;
        width: 132px;
    }
}

.#{$baseClass}__widgetInner {
    padding: $_insetS $_insetM $_insetM;
}

.#{$baseClass}__login {
    padding-top: $_insetS;
    padding-bottom: 10px;
}

.#{$baseClass}__register {
    padding-bottom: 20px;
    border-bottom: 1px solid $_colorAlto;
    margin-bottom: 12px;
    font-size: 14px;
    line-height: 22px;
}

.#{$baseClass}__registerNow {
    font-weight: bold;
    color: $_colorToryBlue;
    text-transform: uppercase;
}

.#{$baseClass}__links {
    font-size: 16px;
    line-height: 30px;
}

.#{$baseClass}__logout {
    margin-top: 12px;
    border-top: 1px solid $_colorAlto;
    padding-top: 24px;
}

.#{$baseClass}__user {
    padding-bottom: 20px;
    font-weight: bold;
}

@media (max-width: 899px) {
    .#{$baseClass} {
        &::before {
            content: none;
        }
    }

    .#{$baseClass}__widget {
        display: none;
    }
}
