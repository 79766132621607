$baseClass: baseclass('CarisBar');

.#{$baseClass} {
    background: $_colorSecondary;
    position: relative;

    @media #{$_mediaLUp} {
        width: 320px;
        min-width: 320px;
    }

    .cockpit-inner {
        position: relative;
        z-index: 1;
        width: 100vw;
        max-width: 325px;
        opacity: 0;

        transition: opacity $_timeXS ease-in-out;

        &::before {
            content: '';
            width: 100%;
            height: 100%;
            position: absolute;
            z-index: 1;
            top: 0;
            right: 0;
            opacity: 0;
            pointer-events: none;

            transition: opacity $_timeS ease-in-out;
        }
    }

    &--cockpit {
        .#{$baseClass}__inner {
            &--trigger {
                z-index: 3;
            }
        }

        .#{$baseClass}__cockpitContainer {
            pointer-events: all;
            transform: translate(0, 100%);

            transition: transform $_timeXS ease-out $_timeXS;
        }

        .cockpit-inner {
            opacity: 1;
            transition: opacity $_timeXS ease-out $_timeXS;
        }
    }

    &--filters {
        .#{$baseClass}__filtersContainer {
            opacity: 1;
            pointer-events: all;
            transform: translate(0, 0);

            &::before {
                opacity: 1;
            }
        }

        .cockpit-inner {
            &::before {
                opacity: 1;
            }
        }
    }

    &--selected {
        .#{$baseClass}__text {
            font-size: 12px;
            line-height: 14px;
            max-height: 28px;

            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
        }
    }

    .icon {
        fill: $_colorWhite;
        position: absolute;
        right: 8px;
        top: calc(50% - 12px);
        transform: rotate(90deg);
        transition: opacity $_timeS ease-in-out, transform $_timeS ease-in-out;

        &.chevron {
            opacity: 1;
        }

        &.close {
            opacity: 0;
        }
    }

    &--cockpit {
        .icon {
            transform: rotate(0);

            &.chevron {
                opacity: 0;
            }

            &.close {
                opacity: 1;
            }
        }
    }
}

.#{$baseClass}__inner {
    @include pageWidth;

    &--trigger {
        padding: 0;
        display: flex;
        align-items: center;
        height: 54px;
    }

    &--holder {
        position: relative;
        z-index: 2;
    }
}

.#{$baseClass}__vehicle {
    position: relative;
    z-index: 1;
    display: flex;
    align-items: center;
    flex-grow: 1;
    overflow: hidden;
    height: 100%;
    background: $_colorSecondary;
    padding: 0 $_insetS 0 $_insetM;

    cursor: pointer;
    transition: background $_timeS ease-in-out;

    @include whiteFocus;

    &:hover {
        background: lighten($_colorSecondary, 5);
    }
}

.#{$baseClass}__car {
    fill: $_colorWhite;
    flex-shrink: 0;
    margin-right: 14px;
}

.#{$baseClass}__chevron {
    fill: $_colorBrand;
    flex-shrink: 0;
}

.#{$baseClass}__text {
    flex-grow: 1;
    font-weight: bold;
    color: $_colorWhite;
    max-height: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 75%;
}

.#{$baseClass}__garage {
    position: relative;
    z-index: 1;
    height: 100%;
    padding: 0 14px;
    display: flex;
    align-items: center;
    background: $_colorSecondaryAlternative;

    svg {
        width: 28px;
    }
}

.#{$baseClass}__cockpitContainer {
    position: absolute;
    z-index: 0;
    bottom: 0;
    right: 0;
    cursor: default;
    pointer-events: none;
    transform: translateY(100%) translateY(-50px);

    transition: transform $_timeXS ease-in-out $_timeS;
}

.#{$baseClass}__filtersContainer {
    position: fixed;
    z-index: 999;
    top: 0;
    right: 0;
    height: 100vh;
    transform: translate(101%, 0);
    pointer-events: none;

    transition: transform $_timeS ease-in-out;

    &::before {
        content: '';
        width: 200vw;
        height: 110vh;
        position: fixed;
        z-index: 1;
        top: 0;
        right: 0;
        background: $_colorBlackSecondary;
        opacity: 0;

        transition: opacity $_timeS ease-in-out;
    }
}

.#{$baseClass}__filtersInner {
    position: relative;
    z-index: 2;
    background: $_colorWhite;
    overflow: hidden;
    box-shadow: 0 0 6px 0 rgba(51, 51, 51, 0.3);
    width: 100vw;
    max-width: 420px;

    @media #{$_mediaMDown} {
        max-width: none;
    }
}

@media #{$_mediaLUp} {
    .#{$baseClass}__inner {
        position: relative;
    }

    .#{$baseClass} {
        .cockpit-inner {
            max-width: 320px;
        }
    }
}

@media #{$_mediaMUp} {
    .#{$baseClass}__inner {
        &--trigger {
            height: 54px;
        }
    }
}

@media #{$_mediaMDown} {
    .#{$baseClass} {
        z-index: 2;

        &--cockpit {
            .#{$baseClass}__cockpitContainer {
                opacity: 1;
                pointer-events: all;
                transform: translate(0, 0);
            }
        }

        &--initializing-mobile {
            .#{$baseClass}__cockpitContainer {
                display: none;
            }
        }

        .cockpit-inner {
            position: relative;
            z-index: 2;
            background: $_colorWhite;
            box-shadow: 0 0 24px 0 rgba(51, 51, 51, 0.3);
            max-width: none;
        }
    }

    .#{$baseClass}__cockpitContainer {
        position: fixed;
        z-index: 999;
        bottom: auto;
        margin-top: -54px;
        height: 100vh;
        transform: translate(100%, 0);

        transition: transform $_timeS ease-in-out;
    }

    .#{$baseClass}__filtersContainer {
        &::before {
            background: transparent;
        }
    }
}
