$baseClass: baseclass('PageSubtitle');

.#{$baseClass} {
    display: none;
}

@media #{$_mediaMUp} {
    .#{$baseClass} {
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        font-size: 14px;
        color: $_colorNero;
    }
}
