$baseClass: baseclass('LoginForm');

.#{$baseClass} {
  display: flex;
  flex-wrap: wrap;
  max-width: 400px;
  padding-top: $_insetM;
}

.#{$baseClass}__title {
  width: 100%;
  font-weight: bold;
  color: $_colorMineShaft;
  font-size: 18px;
  line-height: 26px;
}

.#{$baseClass}__subtitle {
  margin-top: $_stackM;
  width: 100%;
}

.#{$baseClass}__features {
  width: 100%;
  margin-top: $_stackM;
  display: flex;
  flex-direction: column;
}

.#{$baseClass}__fields {
  margin-top: $_stackS;
  width: 100%;
}

.#{$baseClass}__input {
  flex-grow: 1;
  width: 100%;
  margin: $_stackXS 0 !important;
}

.#{$baseClass}__forgot {
  width: 100%;
  margin-top: $_stackS;

  text-align: right;
  color: $_colorMineShaft;
  line-height: 26px;
  text-decoration: underline;
}

.#{$baseClass}__buttons {
  margin-top: $_stackL;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.#{$baseClass}__divider {
  margin: 12px $_stackS;
}

@media #{$_mediaSDown} {
  .#{$baseClass}__subtitle {
    order: 1;
  }

  .#{$baseClass}__features {
    order: 2;
  }

  .#{$baseClass}__fields {
    margin-top: 0;
  }

  .#{$baseClass}__forgot {
    margin-top: $_stackXS;
  }

  .#{$baseClass}__buttons {
    margin-top: 12px;
  }
}

// Rebranding

.#{$baseClass}--rebranding {
  .#{$baseClass}__title {
    @include rebranding-h4;
    color: $_colorRebrandingBlackOut;
  }

  .#{$baseClass}__subtitle {
    @include rebranding-para-regular;
    color: $_colorRebrandingBlackOut;
  }

  .#{$baseClass}__link {
    @include rebranding-link-regular;
    color: $_colorRebrandingBlackOut;
  }
}
