$baseClass: baseclass('NavigationDrawer');

// TODO: use measures variables
$_headerHeightDesktop: 78px + 29px;
$_headerHeightTablet: 78px + 50px;
$_headerHeightMobile: 60px + 50px;


.#{$baseClass} {
    transition: transform $_timeS ease-in-out;

    &--kcom {
        .#{$baseClass}__header {
            color: $_colorBrand;
        }
    }
}

.#{$baseClass}__header {
    display: none;
    align-items: flex-end;
    margin-bottom: $_stackM;

    font-weight: bold;
    color: $_colorSecondary;
}

.#{$baseClass}__close {
    margin-right: $_stackM;
}

.#{$baseClass}__small {
    font-size: $_fontSizeS;
    line-height: 20px;
}

.#{$baseClass}__large {
    font-size: $_fontSizeL;
    line-height: 26px;
}

.#{$baseClass}__list {
    & + & {
        margin-top: $_stackL;
    }
}

.#{$baseClass}__logout {
    margin-top: $_stackM;
}


@media #{$_mediaMDown} {
    .#{$baseClass} {
        position: fixed;
        z-index: 6;
        width: 280px;
        height: calc(100% - #{$_headerHeightMobile});
        flex-shrink: 0;
        top: $_headerHeightMobile;
        left: -280px;

        box-shadow: 0 0 2px 0 $_colorBlackDecorative, 0 2px 2px 0 rgba(0, 0, 0, 0.24);

        transition: transform $_timeS ease-in-out;

        &::after {
            content: '';
            background: $_colorBlackSecondary;
            opacity: 0;
            position: absolute;
            top: 0;
            height: 200vh;
            width: 200vw;

            pointer-events: none;
            transition: opacity $_timeS ease-in-out;
        }

        &--open {
            transform: translate(100%, 0);

            &::after {
                opacity: 1;
                pointer-events: all;
            }
        }
    }

    .#{$baseClass}__inner {
        position: relative;
        z-index: 1;
        height: 100%;
        padding: $_insetS;
        flex-shrink: 0;
        overflow: auto;
        -webkit-overflow-scrolling: touch;
        background: $_colorWhite;
    }

    .#{$baseClass}__header {
        display: flex;
    }
}

@media #{$_mediaMUp} {
    .#{$baseClass} {
        height: calc(100% - #{$_headerHeightTablet});
        top: $_headerHeightTablet;
    }
}

@media #{$_mediaLUp} {
    .#{$baseClass} {
        height: auto;
    }

    .#{$baseClass}__inner {
        height: auto;
    }
}
