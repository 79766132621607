$baseClass: baseclass('CarisList');

.#{$baseClass}__headline {
    padding: $_insetS $_insetM $_insetS;
    font-weight: bold;
    color: $_colorBrand;
    font-size: 14px;
    line-height: 18px;
    // border-top: 1px solid $_colorAlto;
}
