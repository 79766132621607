
$_insetXXS:      2px;
$_insetXS:       4px;
$_insetS:        8px;
$_insetM:        16px;
$_insetL:        32px;
$_insetXL:       64px;

$_insetSquishS:  4px 8px;
$_insetSquishM:  8px 16px;
$_insetSquishL:  16px 32px;

$_insetStretchS: 12px 8px;
$_insetStretchM: 24px 16px;

$_stackXXS:      2px;
$_stackXS:       4px;
$_stackS:        8px;
$_stackM:        16px;
$_stackL:        32px;
$_stackXL:       64px;

$_inlineXXS:     2px;
$_inlineXS:      4px;
$_inlineS:       8px;
$_inlineM:       16px;
$_inlineL:       32px;
$_inlineXL:      64px;
