$baseClass: baseclass('CheckoutCart');

.#{$baseClass} {
    border: 1px solid $_colorAlto;

    &::before {
        content: '';
        position: fixed;
        top: 0;
        left: 0;
        z-index: 0;
        width: 100%;
        height: 100%;
        background: rgba(51, 51, 51, 0.5);
        pointer-events: none;

        opacity: 0;
        transition: opacity $_timeS ease-in-out;
    }

    &--open {
        &::before {
            opacity: 1;
        }

        .#{$baseClass}__content {
            opacity: 1;
            transition: height $_timeS ease-in-out, max-height $_timeS ease-in-out;
        }

        .#{$baseClass}__contentInner {
            opacity: 1;

            transition: opacity $_timeS ease-in-out $_timeS;
        }

        .#{$baseClass}__headerIcon {
            transform: rotate(180deg);
        }
    }
}

.#{$baseClass}__legalText {
    font-size: 14px;
    line-height: 20px;
}

.#{$baseClass}__header {
    display: flex;
    justify-content: space-between;
    padding: 12px $_insetM;
    position: relative;
    z-index: 0;
    background: $_colorWhite;

    cursor: pointer;

    &::before {
        content: '';
        display: block;
        position: absolute;
        top: -1px;
        left: -1px;
        width: 7px;
        height: calc(100% + 2px);
        background: $_colorBrand;
    }
}

.#{$baseClass}__headerText {
    display: flex;
    align-items: center;

    font-weight: bold;
}

.#{$baseClass}__headerIcon {
    flex-shrink: 0;
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 12px;

    border: 1px solid $_colorAlto;
    border-radius: 50%;
    background: $_colorWildSand;

    cursor: pointer;
    transform: rotate(0);
    transform-origin: center;
    transition: transform $_timeS ease-in-out;

    @include blueFocus;

    svg {
        fill: $_colorBrand;
    }
}

.#{$baseClass}__content {
    position: relative;
    z-index: 1;
    height: 0;
    overflow: auto;
    max-height: calc(100vh - 200px);
    background: $_colorWhite;

    transition: height $_timeS ease-in-out $_timeS;
}

.#{$baseClass}__contentInner {
    padding: 0 $_insetM $_insetS;
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    opacity: 0;
    transition: opacity $_timeS ease-in-out;
}

.#{$baseClass}__submit {
    width: 100%;
    padding: $_insetM 0;
    display: flex;
    flex-direction: column;
}

.#{$baseClass}__submitButton {
    width: 100%;
}

.#{$baseClass}__termsLinks {
    display: none !important;

    &--visible {
        display: block !important;
        margin-top: 1em;
        font-size: 14px;
        line-height: 20px;
        width: 100%;
    }

    a {
        text-decoration: underline;
    }
}

.#{$baseClass}__term {
    margin-top: 1em;
}

.#{$baseClass}__readyToBuy {
    display: none;

    &--visible {
        display: block;
    }
}

@media #{$_mediaXLUp} {
    .#{$baseClass} {
        &::before {
            content: none;
        }
    }

    .#{$baseClass}__header {
        padding: 18px 22px;
    }

    .#{$baseClass}__headerIcon {
        display: none;
    }

    .#{$baseClass}__content {
        height: auto !important;
        max-height: none !important;
    }

    .#{$baseClass}__contentInner {
        opacity: 1 !important;
    }
}

.#{$baseClass}--rebranding {
    border-color: $_colorRebrandingGallery;

    .#{$baseClass}__header::before {
        background: $_colorRebrandingPrimary;
    }

    .#{$baseClass}__headerText {
        color: $_colorRebrandingBlackOut;
    }

    .#{$baseClass}__headerIcon svg {
        fill: $_colorRebrandingManatee;
    }
}
