$baseClass: baseclass('ErrorNotice');

.#{$baseClass} {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.#{$baseClass}__inner {
    padding: 10px 20px;
    overflow: hidden;
}

.#{$baseClass}__headline {
    font-weight: bold;
    color: $_colorNero;
    font-size: $_fontSizeL;
    line-height: 34px;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: $_stackL;
    text-align: center;

    @media #{$_mediaSDown} {
        font-size: $_fontSizeM;
        line-height: 19px;
    }
}

.#{$baseClass}__highlight {
    color: $_colorBrand;
}

.#{$baseClass}__text {
    margin-top: $_stackM;
    color: $_colorBlackPrimary;
    font-size: $_fontSizeM;
    line-height: 26px;
    max-width: 480px;
    text-align: center;

    @media #{$_mediaSDown} {
        font-size: 14px;
    }
}

.#{$baseClass}__car {
    max-width: 510px;
}
