$baseClass: baseclass('CmsPrimarySecondaryBanners');

.#{$baseClass} {
    width: 100%;
}

.#{$baseClass}__bannersRow {
    display: flex;
    flex-flow: row nowrap;
    align-items: stretch;
    margin: 0 -16px;

    @media (min-width: 1300px) {
        margin: 0;
    }
}

.#{$baseClass}__primary,
.#{$baseClass}__secondary {
    position: relative;
    height: 434px;
    overflow: hidden;
    cursor: pointer;
}

.#{$baseClass}__primaryRatio {
    position: relative;
    padding-bottom: 53%;

    .#{$baseClass}__primaryPosition {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;

        img {
            width: 100%;
        }
    }

    @media #{$_mediaLDown} {
        display: none;
    }
}

.#{$baseClass}__primaryPicture {
    display: none;

    @media #{$_mediaLDown} {
        display: block;
    }
}

.#{$baseClass}__primary {
    flex-grow: 1;
    border-bottom-right-radius: $_borderRadiusDefault;
    border-bottom-left-radius: $_borderRadiusDefault;

    @media #{$_mediaLDown} {
        height: auto;
    }
}

.#{$baseClass}__secondary {
    flex: 320px 0 0;
    margin-left: $_stackM;
    align-self: stretch;
    border-bottom-right-radius: $_borderRadiusDefault;
    border-bottom-left-radius: $_borderRadiusDefault;

    @media #{$_mediaLUp} {
        width: 320px;
    }

    @media #{$_mediaLDown} {
        display: none;
    }
}

.#{$baseClass}__button {
    position: absolute;
    bottom: 5%;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;

    &--hover {
        background-color: $_colorToryBlue;
        border-color: $_colorToryBlue;
    }
}

.#{$baseClass}__imageInner {
    width: 100%;
    height: 100%;
}
