$baseClass: baseclass('Facets');

.#{$baseClass} {
    width: 100%;
    overflow: auto;
    -webkit-overflow-scrolling: touch;

    @include removeScrollbar;

    &--toControllers {
        .#{$baseClass}__facet {
            @media #{$_mediaMDown} {
                display: none;

                &:first-of-type {
                    display: block;
                }
            }
        }
    }

    &--mobile {
        border: 1px solid $_colorAlt;
        background-color: $_colorWhite;

        @media #{$_mediaMDown} {
            .#{$baseClass}__facets {
                padding: $_insetS 0;
            }

            .#{$baseClass}__inner {
                flex-flow: column nowrap;
                justify-content: flex-start;
                align-items: stretch;
            }

            .#{$baseClass}__facet {
                border: none;
                border-radius: 0;
                margin: 0;
                justify-content: flex-start;
                padding: $_insetS $_insetM;

                .#{$baseClass}__facetContent {
                    width: 100%;
                    justify-content: flex-start;
                }

                &:first-of-type {
                    display: none;
                }
            }

            .#{$baseClass}__facetIcon {
                margin-left: auto;
            }
        }
    }
}

.#{$baseClass}__inner {
    display: flex;
    flex-flow: row wrap;

    @media #{$_mediaMDown} {
        justify-content: center;
    }
}

.#{$baseClass}__facet {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    margin: $_stackXS $_stackS $_stackXS 0;
    padding: $_insetS;
    border: 1px solid $_colorAlto;
    background: $_colorWhite;
    border-radius: $_borderRadiusDefault;

    color: $_colorRollingStone;

    transition: all $_timeXS ease-in-out;


    &:hover {
        background: $_colorSecondary;

        .#{$baseClass}__facetName,
        .#{$baseClass}__facetValue {
            color: $_colorWhite;
        }

        .#{$baseClass}__facetIcon {
            fill: $_colorWhite;
        }
    }
}

.#{$baseClass}__facetContent {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    color: $_colorRollingStone;

    .#{$baseClass}__facetValue--centered {
        width: 100%;
        text-align: center;
        font-weight: bold;
        text-transform: uppercase;

        @media #{$_mediaMDown} {
            font-size: $_fontSizeM;
            display: block;
            padding: $_insetXS;
        }
    }
}

.#{$baseClass}__facetName {
    font-weight: bold;
    font-size: $_fontSizeS;
}

.#{$baseClass}__facetValue {
    font-size: $_fontSizeS;
}

.#{$baseClass}__facetIcon {
    margin-left: $_stackXS;
    fill: $_colorRollingStone;
    transition: fill $_timeXS ease-in-out;
    width: 14px;
    height: 14px;
}
