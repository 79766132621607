$baseClass: baseclass('List');

.#{$baseClass} {
    width: 100%;
    padding-bottom: $_inlineM;
}

.#{$baseClass}__list {
    width: 100%;

    color: $_colorMineShaft;
    font-size: 14px;
    line-height: 20px;
}

.#{$baseClass}__title {
    font-weight: bold;
}

.#{$baseClass}__item {
    font-weight: normal;

    &--bold {
        font-weight: bold;
    }
}
