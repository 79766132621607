$baseClass: baseclass('ProductCategories');

.#{$baseClass} {
    width: 100%;
    margin: $_stackL 0;
}

.#{$baseClass}__container {
    display: flex;
    flex-flow: row wrap;
    align-items: baseline;
    justify-content: flex-start;
    margin: 0 -#{$_stackXS};

    @media #{$_mediaMDown} {
        display: none;
    }
}

.#{$baseClass}__mobileContainer {
    @media #{$_mediaLUp} {
        display: none;
    }

    margin: 0 -$_insetM;
}

.#{$baseClass}__itemWrapper {
    flex: 0 0;
    padding: $_stackS;
    height: auto;
    flex-basis: 25%;

    @media #{$_mediaLDown} {
        flex-basis: 33%;
    }
}

.#{$baseClass}__title {
    color: $_colorChathamsBlue;
    font-size: 20px;
    margin-bottom: $_stackS;
    font-weight: 700;
    line-height: 20px;

    @media #{$_mediaLDown} {
        margin-bottom: $_stackL;
    }

    @media #{$_mediaMDown} {
        line-height: 18px;
        font-size: 18px;
    }
}
