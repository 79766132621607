$baseClass: baseclass("CarpageCarpartsList");

.#{$baseClass} {
    margin-top: $_stackL;
    width: 100%;
}

.#{$baseClass}__sectionTitle {
    color: $_colorChathamsBlue;
    font-size: 20px;
    margin-bottom: $_stackS;
    font-weight: 700;
    line-height: 20px;
}

.#{$baseClass}__container {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: flex-start;
    margin: $_stackM -#{$_stackS};

    @media #{$_mediaMDown} {
        flex-flow: column nowrap;
        justify-content: flex-start;
        align-items: stretch;
    }
}

.#{$baseClass}__item {
    padding: 0 $_insetS;
    flex-grow: 0;
    width: 25%;
    margin-bottom: 25px;

    @media #{$_mediaMDown} {
        border-top: solid 1px $_colorAlt;
        width: 100%;
        padding: 0;
        flex-grow: 1;
        margin-bottom: 0;

        &:last-of-type {
            border-bottom: solid 1px $_colorAlt;
        }
    }
}
