html {
    scroll-padding-top: 150px;
}

body,
input,
button,
textarea,
select {
    letter-spacing: 0;
    font-family: 'Glacial Indifference', sans-serif;
    text-size-adjust: 100%;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

body {
    font-size: 16px !important;
    background: $_colorWhite;
}

html,
body {
    overflow-x: hidden;
}

.staticContent {
    font-size: 14px;

    h1,
    h2,
    h3,
    h4,
    h5 {
        font-family: 'Glacial Indifference' !important;
        font-weight: bold !important;
    }

    h1 {
        font-size: $_fontSizeL !important;
        color: $_colorSecondary;
    }

    h2 {
        font-size: 20px !important;
    }

    h3 {
        font-size: $_fontSizeM !important;
    }

    h4,
    h5,
    h6 {
        font-size: $_fontSizeS !important;
    }

    ol,
    ul {
        margin-left: 2.5em;
    }

    ol {
        list-style: decimal;
    }

    ul {
        list-style: disc;
    }

    .marginBottomBig {
        margin-bottom: 30px !important;
    }

    .marginTopXXSmall {
        margin-top: 1px !important;
    }

    p {
        margin-bottom: 15px !important;
    }

    a,
    b {
        font-weight: bold;
    }

    table {
        margin-bottom: 15px !important;
    }

    td,
    th {
        vertical-align: middle;
    }
}
