$baseClass: baseclass("CarpageManufacturersList");

.#{$baseClass} {
  margin-top: $_stackL;
  width: 100%;
}

.#{$baseClass}__container {
  @media #{$_mediaMDown} {
    margin: 0 -#{$_stackS};
  }
}

.#{$baseClass}__sectionTitle {
  color: $_colorChathamsBlue;
  font-size: 20px;
  margin-bottom: $_stackS;
  font-weight: 700;
  line-height: 20px;
}

.#{$baseClass}__items {
  display: flex;
  flex-flow: row wrap;
  margin: 0 -#{$_insetS};
}

.#{$baseClass}__item {
  flex: 0 0 20%;
  padding: $_insetS;

  @media #{$_mediaLUp} {
    max-width: 20%;
  }
}

@media #{$_mediaMDown} {
  .#{$baseClass}__items {
    flex-flow: column nowrap;
  }
  .#{$baseClass}__item {
    flex: 0 0 0;
    padding: 0;
  }
}
