$baseClass: baseclass('CarisFilterTrigger');

.#{$baseClass} {
    display: flex;
    align-items: center;
    justify-content: space-between;

    position: relative;
    width: 100%;
    padding: 12px 8px 12px 16px;
    border: 1px solid $_colorAlto;
    border-radius: $_borderRadiusDefault;

    color: $_colorMineShaft;
    cursor: pointer;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;

    &:focus {
        border-color: $_colorBrand;

        &::-moz-focus-inner {
            border: none;
        }
    }

    &--disabled {
        color: $_colorAlto;
        cursor: default;
        pointer-events: none;
        border-color: $_colorGallery;

        .#{$baseClass}__icon {
            fill: $_colorAlto;
        }
    }

    &--selected {
        .#{$baseClass}__icon {
            fill: $_colorMineShaft;
        }
    }
}

.#{$baseClass}__text {
    flex-grow: 1;
    text-align: left;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    line-height: 20px;
}

.#{$baseClass}__icon {
    fill: $_colorBrand;
    flex-shrink: 0;
}
