$baseClass: baseclass('CategoriesHeader');

.#{$baseClass}__navigation {
    display: flex;
    background-color: $_colorConcrete;
    align-items: center;
    height: 60px;

    &.#{$baseClass}__white {
        background-color: $_colorWhite;
    }
}

.#{$baseClass}__backButton {
    height: 60px;
    width: 60px;

    svg {
        width: 40px;
        height: 40px;
    }
}

.#{$baseClass}__navigationTitleContainer {
    display: flex;
    align-items: center;
    flex-grow: 1;
    justify-content: space-between;
    padding-right: $_insetM;
}

.#{$baseClass}__navigationTitle {
    display: flex;
    align-items: center;
    font-size: 18px;
    font-weight: bold;
    color: $_colorMineShaft;
    padding-left: $_insetM;

    &.#{$baseClass}__blue {
        color: $_colorChathamsBlue;
    }
}

.#{$baseClass}__close {
    fill: $_colorRollingStone;
    cursor: pointer;
}

.#{$baseClass}__icon {
    margin-right: $_stackS;
    fill: $_colorBoulder;
    max-height: 35px;
    max-width: 40px;
}

.#{$baseClass}__titleContainer {
    display: flex;
    flex-direction: column;
}

.#{$baseClass}__parentTitle {
    font-size: 14px;
    font-weight: normal;
    margin-bottom: 4px;
}
