$baseClass: baseclass('OptionBar');

.#{$baseClass} {
    background: $_colorBrand;

    &--menu {
        .#{$baseClass}__menu {
            &::before {
                transform: translate(0, 8px) rotate(-45deg);
            }

            &::after {
                transform: translate(0, -8px) rotate(45deg);
            }
        }

        .#{$baseClass}__menuMiddle {
            opacity: 0;
        }
    }
}

.#{$baseClass}__inner {
    @include pageWidth;
    @include pagePadding;

    padding-right: 22px;
    padding-top: 9px;
    padding-bottom: 9px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 50px;
}

.#{$baseClass}__label {
    font-weight: bold;
    font-size: 15px;
    color: $_colorWhite;
    line-height: 26px;
    transform: translate(0, 6%);
}

.#{$baseClass}__searchWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
    position: relative;
}

.#{$baseClass}__search {
    flex-grow: 1;
    margin: 0 $_stackM;
    position: relative;
    display: flex;
    align-items: center;
}

.#{$baseClass}__searchInput {
    flex-grow: 1;
}

.#{$baseClass}__searchSubmit {
    margin: 0;
    position: absolute;
    right: 30px;
    bottom: 4px;

    font-weight: bold;
    color: $_colorWhite;
    cursor: pointer;

    @include blueFocus;
}

.#{$baseClass}__searchIcon {
    position: absolute;
    left: 5px;
    bottom: 4px;

    fill: $_colorWhite;
    pointer-events: none;
}

.#{$baseClass}__searchClose {
    position: absolute;
    right: 5px;
    top: 50%;
    transform: translate(0, -50%);
    fill: $_colorWhite;
    cursor: pointer;
}

.#{$baseClass}__buttons {
    flex-shrink: 0;
    display: flex;
    transition: opacity $_timeS ease-in-out;

    &--hidden {
        opacity: 0;
    }

    .#{$baseClass}--filters-open & {
        z-index: 0;
    }
}

.#{$baseClass}__button {
    &--search {
        margin-right: $_stackS;
    }

    &--wishlist,
    &--cart {
        margin-left: $_stackS;
    }
}

.#{$baseClass}__back {
    margin-right: $_stackM;
}

.#{$baseClass}__menu {
    position: relative;
    width: 28px;
    padding: 8px 0;
    cursor: pointer;
    margin-right: 6px;
    flex-shrink: 0;
    z-index: 2;
    left: 0;

    &::before,
    &::after {
        content: '';
        position: absolute;
        left: 0;
        width: 100%;
        height: 4px;
        background: $_colorWhite;
        border-radius: $_borderRadiusDefault;

        transition: transform $_timeS ease-in-out;
    }

    &::before {
        top: 0;
    }

    &::after {
        bottom: 0;
    }

    &.#{$baseClass}__menu--right {
        position: absolute;
        left: calc(100% - 39px);
        transition: left $_timeS ease-in-out;
        z-index: 4;
    }
}

.#{$baseClass}__menuMiddle {
    height: 4px;
    background: $_colorWhite;
    border-radius: $_borderRadiusDefault;

    transition: opacity $_timeS ease-in-out;
}

.#{$baseClass}__logoGroup {
    display: flex;
    align-items: center;
    flex-shrink: 0;
}

.#{$baseClass}__logo {
    flex-shrink: 0;
    max-width: 243px;

    &.#{$baseClass}__logo--pushRight {
        @media #{$_mediaMDown} {
            max-width: 221px;
        }
    }
}

@media #{$_mediaMUp} {
    .#{$baseClass} {
        &:not(.#{$baseClass}--reduced) {
            .#{$baseClass}__inner {
                height: 78px;
            }
        }
    }

    .#{$baseClass}__menu {
        margin-right: $_stackM;
    }

    .#{$baseClass}__label {
        font-size: $_fontSizeL;
    }
}

@media #{$_mediaLUp} {
    .#{$baseClass}__menu {
        display: none !important;
    }
}

@media (min-width: 651px) {
    .#{$baseClass}__button {
        &--search {
            display: none !important;
        }
    }

    .#{$baseClass}__searchWrapper {
        max-width: 550px;
        width: 100%;
    }

    .#{$baseClass}__searchClose {
        display: none;
    }

    .#{$baseClass}__searchSubmit {
        right: 0;
    }
}

@media (max-width: 650px) {
    .#{$baseClass} {
        &--search {
            .#{$baseClass}__searchWrapper {
                top: 32px;
                display: flex;
                z-index: 3;
            }
        }

        &--searchShow {
            .#{$baseClass}__searchWrapper {
                transform: translate(0, 0);
                opacity: 1;
            }
        }
    }

    .#{$baseClass}__searchWrapper {
        display: none;

        position: absolute;
        background: $_colorBrand;
        width: 100%;
        left: 0;
        top: 0 !important;
        margin: 0;
        height: 78px;
        z-index: 2;

        transform: translate(100%, 0);
        opacity: 0;
        transition: opacity $_timeS ease-in-out, transform $_timeS ease-in-out;

        &::after {
            content: '';
            position: absolute;
            z-index: 1;
            top: 78px;
            right: 0;
            height: 200vh;
            width: 200vw;
            background: $_colorBlackSecondary;
        }
    }

    .#{$baseClass}__search {
        width: 100%;
        margin: 0 $_stackS;
    }
}

@media #{$_mediaSDown} {
    .#{$baseClass}__searchWrapper {
        height: 60px;

        &::after {
            top: 60px;
        }
    }

    .#{$baseClass}__headerButton {
        border: none;
    }
}

@media (min-width: 900px) {
    .#{$baseClass}__buttons {
        width: 100%;
        max-width: 320px;
        flex-grow: 1;
        flex-shrink: 0;
    }

    .#{$baseClass}__button {
        &--account,
        &--cart {
            flex-grow: 1;
            width: 100%;
            width: 132px;
        }
    }
}


@media print {
    .#{$baseClass} {
        -webkit-print-color-adjust: exact;
        color-adjust: exact;
    }

    .#{$baseClass}__label,
    .#{$baseClass}__searchWrapper,
    .#{$baseClass}__buttons {
        display: none;
    }

    .#{$baseClass}__inner {
        justify-content: center;
    }
}
