$baseClass: baseclass('ProductArticleType');

.#{$baseClass} {
    p {
        font-size: 14px;
        line-height: 1.3;
        margin-bottom: $_stackS;
    }

    a {
        color: $_colorSecondary;
    }
}
