@import 'src/sets/shop/scss/libs/slick';

$baseClass: baseclass('CmsBanners');

.#{$baseClass} {
    width: 100%;
    background: $_colorWhite;
}

.#{$baseClass}__inner {
    @include pageWidth;
}

.#{$baseClass}__headline {
    font-size: 20px;
    font-weight: bold;
    color: $_colorSecondary;

    &.#{$baseClass}__headline--reversed {
        color: $_colorBrand;
    }
}

.#{$baseClass}__items {
    display: flex;
}

.#{$baseClass}__item {
    @include orangeFocus;

    display: block !important;
    flex-shrink: 0;
}

.#{$baseClass}__image {
    display: block;
    width: 100%;
    height: auto;
}

@media #{$_mediaSDown} {
    .#{$baseClass}__headline {
        padding: $_insetM 12px $_insetXS;
    }

    .#{$baseClass}__items {
        padding: 0 20px;

        &.slick-initialized {
            padding: 0;
        }
    }

    .#{$baseClass}__item {
        padding: 5px;
        width: 100%;

        .#{$baseClass}__image {
            &--desktop {
                display: none;
            }
        }
    }
}

@media #{$_mediaMUp} {
    .#{$baseClass}__headline {
        padding: 25px 0 0;
    }

    .#{$baseClass}__items {
        padding: 0 50px;

        &.slick-initialized {
            padding: 0;
        }
    }

    .#{$baseClass}__item {
        padding: 10px;
        width: 50%;

        .#{$baseClass}__image {
            &--mobile {
                display: none;
            }
        }
    }
}

@media #{$_mediaLUp} {
    .#{$baseClass}__headline {
        padding: 20px 0 0;
    }

    .#{$baseClass}__items {
        display: flex;
        flex-wrap: wrap;
        padding: 10px 0;
        margin: 0 -10px;
    }

    .#{$baseClass}__item {
        width: 33.333%;
        flex-shrink: 0;
    }
}
