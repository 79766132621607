$baseClass: baseclass("CarSelectionNotice");

.#{$baseClass} {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    width: 100%;
    position: relative;
    justify-content: flex-start;

    @media #{$_mediaMDown} {
        flex-direction: column;
        align-items: stretch;
    }

    @media #{$_mediaSDown} {
        margin-top: $_stackM;
    }
}

.#{$baseClass}__caption {
    flex: 0 0 50%;

    p {
        font-size: 16px;
        font-weight: 700;
        margin-top: $_stackS;
        line-height: 26px;

        @media #{$_mediaMDown} {
            line-height: 20px;
        }
    }

    @media #{$_mediaLDown} {
        flex-basis: 100%;
    }

    @media #{$_mediaMDown} {
        line-height: 22px;
    }

    .#{$baseClass}__pretitle  {
        display: block;
        padding-top: $_insetS;
    }
}

.#{$baseClass}__image {
    .#{$baseClass}__imageElement {
        max-height: 120px;
        transform: scale(1.45);
        position: relative;
        top: -15px;
        left: -20px;

        @media #{$_mediaMDown} {
            transform: scale(1.7);
        }

        @media #{$_mediaSDown} {
            max-height: 80px;
            top: -20px;
            left: 0;
            position: relative;
            transform: scale(2);
        }
    }

    &--noDesktop {
        @media #{$_mediaXLUp} {
            display: none;
        }
    }
}

.#{$baseClass}__fallbackImg {
    max-height: 200px;
    max-width: 250px;
    margin-right: $_insetM;
}

.#{$baseClass}__arrowGroup {
    margin-left: auto;

    @media #{$_mediaMDown} {
        display: none;
    }
}

.#{$baseClass}__buttonWrapp {
    flex: 0 0 320px;
    margin-left: auto;

    @media #{$_mediaMDown} {
        margin-top: $_stackM;
        flex-basis: 100%;
        margin-left: 0;
    }

    .#{$baseClass}__selectCar {
        display: block;
        margin: 0 auto;

        @media #{$_mediaMDown} {
            width: 100%;
        }
    }
}
