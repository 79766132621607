$baseClass: baseclass('CatalogSorting');

.#{$baseClass} {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.#{$baseClass}__results {
    font-size: 14px;
    color: $_colorRollingStone;

    strong {
        font-weight: bold;
        color: $_colorMineShaft;
    }
}

.#{$baseClass}__sorting {
    display: flex;
    align-items: center;
    position: relative;
}
