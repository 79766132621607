@import 'src/sets/shop/scss/libs/slick';

$baseClass: baseclass('CmsCarousel');

.#{$baseClass} {
    position: relative;
    background: $_colorWhite;
}

.#{$baseClass}__inner {
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    width: 100vw;
}

.#{$baseClass}__items {
    &.slick-initialized {
        .#{$baseClass}__item {
            display: block !important;
        }
    }
}

.#{$baseClass}__item {
    @include orangeFocus;

    position: relative;
    display: none;

    &:first-child {
        display: block;
    }
}

.#{$baseClass}__itemInner {
    background-position: center;
    background-size: cover;
}

.#{$baseClass}__image {
    @include pageWidth;

    display: block;
    width: 100%;
    height: auto;
}

.#{$baseClass}__dots {
    position: absolute;
    bottom: 7px;
    left: 50%;
    transform: translate(-50%, 0);
    display: flex;

    li {
        display: block;
        padding: 0 2px 0 3px;

        &.slick-active {
            button {
                background: $_colorBrand;
                cursor: default;
            }
        }
    }

    button {
        display: block;
        background: $_colorWhite;
        border: 1px solid $_colorBrand;
        border-radius: 50%;
        width: 8px;
        height: 8px;
        overflow: hidden;
        padding: 0;
        text-indent: -9999px;
        font-size: 0;
        cursor: pointer;

        transition: background $_timeXS ease-in-out;

        &:hover {
            background: $_colorGallery;
        }
    }
}


@media #{$_mediaSDown} {
    .#{$baseClass}__itemInner {
        background-image: none !important;
    }
}

@media #{$_mediaMUp} {
    .#{$baseClass}__dots {
        bottom: 10px;

        li {
            padding: 0 4px;
        }

        button {
            width: 10px;
            height: 10px;
        }
    }
}


@media #{$_mediaLUp} {
    .#{$baseClass}__dots {
        bottom: 20px;

        li {
            padding: 0 6px;
        }

        button {
            width: 16px;
            height: 16px;
        }
    }
}
