$baseClass: baseclass('ComputopForm');

.#{$baseClass} {
    width: 100%;
    overflow: hidden;
    background: $_colorWhite;

    select:invalid {
        box-shadow: none;
    }
}

.#{$baseClass}__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.#{$baseClass}__title {
    font-weight: bold;
    font-size: 21px;
    color: $_colorMineShaft;
    line-height: 32px;
}

.#{$baseClass}__link {
    font-size: 14px;
    text-decoration: underline;
}

.#{$baseClass}__fields {
    display: flex;
    flex-wrap: wrap;
    margin-left: -$_stackS;
    margin-right: -$_stackS;
}

.#{$baseClass}__input {
    width: 100%;
    min-width: 200px;
    padding-left: $_insetS;
    padding-right: $_insetS;
    margin: $_stackS 0 !important;
    text-align: left;

    &--half {
        flex-basis: 50%;
        flex-grow: 1;
    }

    &--hidden {
        display: none !important;
    }
}

.#{$baseClass}__creditCardContainer {
    display: flex;
    width: 100%;
    justify-content: center;
    font-family: 'OCRA';
    text-shadow: 0 1px 1px $_colorBlackSecondary;
    color: $_colorWhite;
    margin: 25px 0;
    text-align: center;
}

.#{$baseClass}__creditCardContent {
    position: relative;
}

.#{$baseClass}__creditLabels {
    position: absolute;
    font-size: 15px;
    text-transform: uppercase;
    text-align: left;

    @media #{$_mediaSDown} {
        font-size: 13px;
    }
}

.#{$baseClass}__creditLogos {
    position: absolute;
    top: 30px;
    right: 25px;
    max-height: 20px;
    opacity: 0;

    transition: opacity $_timeS ease-in-out;

    &--active {
        opacity: 1;
    }
}

.#{$baseClass}__mastercard {
    max-height: 35px;
    top: 25px;
}

.#{$baseClass}__cardHolderNameTitle,
.#{$baseClass}__cardExpireDateTitle {
    font-family: sans-serif;
    color: $_colorAlto;
    font-size: 8px;
    text-shadow: none;
    top: 138px;

    @media #{$_mediaSDown} {
        top: 125px;
    }
}

.#{$baseClass}__cardHolderNameTitle {
    left: 30px;
}

.#{$baseClass}__cardExpireDateTitle {
    left: 220px;

    @media #{$_mediaSDown} {
        left: 203px;
    }
}

.#{$baseClass}__cardHolderNameLabel {
    top: 150px;
    left: 30px;
    max-width: 170px;
    word-break: break-word;
    overflow: hidden;
    max-height: 45px;

    @media #{$_mediaSDown} {
        top: 137px;
    }
}

.#{$baseClass}__cardExpireDateLabel {
    top: 150px;
    right: 50px;

    @media #{$_mediaSDown} {
        top: 137px;
        right: 45px;
    }
}

.#{$baseClass}__cardNumberLabel {
    top: 90px;
    left: 30px;
    font-size: 19px;
}

.#{$baseClass}__cvcNumber svg {
    cursor: pointer;
    pointer-events: all;
    fill: $_colorBlack;
}

.#{$baseClass}__cardBackground {
    display: block;
    width: 317px;

    @media #{$_mediaSDown} {
        width: 288px;
    }
}

.#{$baseClass}__expireContainer {
    display: flex;
    flex-wrap: wrap;
    flex-grow: 1;
}

.#{$baseClass}__submit {
    flex-grow: 1;
    display: none;
}


@media #{$_mediaXS} {
    .#{$baseClass}__creditCardContainer {
        display: none;
    }
}

@media #{$_mediaSDown} {
    .#{$baseClass}__fields {
        margin-left: -$_stackXS;
        margin-right: -$_stackXS;
    }

    .#{$baseClass}__input {
        padding-left: $_insetXS;
        padding-right: $_insetXS;
        margin: $_stackXS 0 !important;
    }
}
