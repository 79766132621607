$baseClass: baseclass('CheckoutStepAddress');

.#{$baseClass}__section {
  & + & {
    margin-top: $_stackL;
  }
}

.#{$baseClass}__headline {
  margin-bottom: $_stackM;

  font-weight: bold;
  font-size: 21px;
}

.#{$baseClass}__info {
  margin: $_stackS 0 $_stackM;

  line-height: 26px;
  font-size: 14px;
}

.#{$baseClass}__input {
  flex-grow: 1;
  width: 100%;
  margin: $_stackS 0;
}

.#{$baseClass}__registerHeadline {
  margin-bottom: $_stackM;

  font-weight: bold;
  font-size: 21px;
}

.#{$baseClass}__features {
  margin: 25px 0;
}

@media #{$_mediaMUp} {
  .#{$baseClass}__headline {
    margin-bottom: 24px;
  }

  .#{$baseClass}__info {
    margin: $_stackM 0 $_stackL;

    line-height: 26px;
    font-size: 14px;
  }
}

.#{$baseClass}--rebranding {
  color: $_colorRebrandingBlackOut;

  .#{$baseClass}__headline {
    @include rebranding-h4;
  }
}
