$baseClass: baseclass('CarpageCarSelection');

.#{$baseClass} {
    width: 100%;
    background-color: $_colorWildSand;
    padding: 0 0 $_insetM;
    position: relative;
}

.#{$baseClass}__alertMessage {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;
    height: 52px;
    background-color: $_colorChathamsBlue;
    position: relative;
    color: $_colorWhite;
    font-size: 16px;
    font-weight: bold;

    &::before,
    &::after {
        content: "";
        display: block;
        background-color: $_colorChathamsBlue;
        width: 100%;
        position: absolute;
        height: 100%;
        top: 0;
    }

    &::before {
        left: -100%;
    }

    &::after {
        left: 100%;
        z-index: 1;
    }

    .#{$baseClass}__errorIcon {
        fill: $_colorWhite;
        margin-right: 13px;
    }
}



.#{$baseClass}__filtersContainer {
    margin-top: $_stackS;
    display: flex;
    flex-flow: row nowrap;

    @media #{$_mediaMDown} {
        margin-top: $_stackM;
        flex-direction: column;
        align-items: stretch;
    }
}

.#{$baseClass}__filterWrapper {
    flex-grow: 1;
    max-width: 20%;
    margin: 0 $_stackS;

    &:first-of-type {
        margin-left: 0;
    }

    &:last-of-type {
        margin-right: 0;
    }

    @media (max-width: 910px) {
        max-width: 25%;
    }

    @media #{$_mediaMDown} {
        margin: $_stackXS 0;
        max-width: 100%;

        &:first-of-type {
            margin-top: 0;
        }

        &:last-of-type {
            margin-bottom: 0;
        }
    }
}

.#{$baseClass}__formWrap {
    margin-bottom: $_stackM;
    padding-bottom: $_stackM;
    border-bottom: solid 1px $_colorAlt;
}

.#{$baseClass}__formLabel {
    display: flex;
    flex-flow: row;
    margin: 6px 0;
    color: $_colorRollingStone;
    justify-content: flex-start;
    align-items: center;

    .#{$baseClass}__help {
        margin-left: $_stackS;
    }
}

.#{$baseClass}__formFields {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: flex-start;
    width: 70%;
    max-width: 500px;
}

.#{$baseClass}__formField {
    flex-grow: 1;
    padding-right: $_insetM;
}

.#{$baseClass}__submitButton {
    margin-top: $_stackS;
    height: 50px;
}
.#{$baseClass}__tabsSection {
    display: none;
}

.#{$baseClass}__help {
    cursor: pointer;
}

.#{$baseClass}__contentWrapper {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    padding: 0 15px;

    .#{$baseClass}__mainContent {
        flex-grow: 1;
    }
    .#{$baseClass}__imgSection {
        flex-grow: 0;
        max-width: 300px;
        min-width: 250px;
        position: relative;

        @media #{$_mediaLDown} {
            display: none;
        }

        .#{$baseClass}__imageElement,
        .#{$baseClass}__fallbackImg {
            max-width: 100%;
            position: absolute;
            top: 50%;
            right: 20px;
            transform: translateY(-50%);
        }

        .#{$baseClass}__fallbackImg {
            max-width: 90%;
        }

        .#{$baseClass}__imageElement {
            transform: scale(1.3);
        }
    }
}


@media #{$_mediaMDown} {
    .#{$baseClass}__submitButton {
        width: 100%;
        margin-top: $_stackM;
    }
    .#{$baseClass}__formFields {
        flex-direction: column;
        justify-content: flex-start;
        align-items: stretch;
        width: 100%;
        max-width: 100%;
    }
    .#{$baseClass}__formField {
        flex-grow: 0;
        padding-right: 0;
    }
    .#{$baseClass}__formLabel {
        justify-content: center;
    }
    .#{$baseClass}__formWrap {
        border-bottom: none;
    }
    .#{$baseClass}__tabsSection {
        display: block;
        margin-top: $_stackL;

        .#{$baseClass}__tabs {
            display: flex;
            flex-flow: row nowrap;
            justify-content: center;
            align-items: baseline;
            height: 45px;

            .#{$baseClass}__tab {
                color: $_colorRollingStone;
                line-height: 45px;
                flex-grow: 1;
                text-align: center;
                cursor: pointer;
                border: solid 1px $_colorAlt;
                border-top-color: transparent;

                &::before {
                    content: '';
                    display: block;
                    border-top: 4px solid $_colorAlt;
                    position: relative;
                    width: calc(100% + 3px);
                }

                &--active {
                    &::before {
                        border-top-color: $_colorBrand;
                        z-index: 1;
                    }
                    border-color: transparent;
                    color: $_colorBrand;
                    font-weight: bold;
                }
                &:first-of-type{
                    border-left-color: transparent;
                    &::before {
                        right: 1px;
                    }
                }
                &:last-of-type{
                    border-right-color: transparent;
                    &::before {
                        left: -2px;
                    }
                }
            }
        }
    }
    .#{$baseClass}__tabbedContainer {
        display: none;
        padding: $_insetM 0;

        &--active {
            display: block;
        }
    }
}