$baseClass: baseclass('ComputopSuccess');

.#{$baseClass} {
    background: $_colorWhite;
}

.#{$baseClass}__title {
    margin-bottom: $_stackS;

    font-weight: bold;
    font-size: 21px;
    color: $_colorMineShaft;
    line-height: 32px;
}

.#{$baseClass}__message {
    margin-bottom: $_stackS;

    line-height: 24px;
}

.#{$baseClass}__buttons {
    margin-left: -$_stackS;
    margin-right: -$_stackS;
}

.#{$baseClass}__buttonsInner {
    width: 100%;
    display: flex;
    flex-wrap: wrap-reverse;
    align-items: center;
    justify-content: flex-end;
}

.#{$baseClass}__button {
    margin: $_stackS !important;
    flex-grow: 1;

    &--expand {
        flex-grow: 99;
    }
}
