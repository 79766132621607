$baseClass: baseclass('TheTemplate');

.#{$baseClass} {
    background-color: $_colorWhite;

    min-height: 100vh;
    display: flex;
    flex-direction: column;
}

.#{$baseClass}__content {
    @include pageWidth;
    @include pagePadding;

    flex-grow: 1;
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
}

.#{$baseClass}__pageTitle {
    width: 100%;
    display: flex;
    align-items: flex-end;
    padding-top: 8px;
    padding-bottom: 4px;
}

@media #{$_mediaMUp} {
    .#{$baseClass}__pageTitle {
        padding-top: 18px;
        padding-bottom: 18px;
    }
}

@media #{$_mediaLUp} {
    .#{$baseClass}__pageTitle {
        padding-top: 28px;
        padding-bottom: 20px;
    }
}

@media print {
    .#{$baseClass},
    .#{$baseClass}__content {
        display: block;
    }
}
