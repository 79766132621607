$baseClass: baseclass('Table');

.#{$baseClass} {
    border-radius: $_borderRadiusDefault;
    border: 1px solid $_colorAlto;
    border-bottom: 0;
    color: $_colorMineShaft;

    &--flat {
        border: 0;
        overflow: auto;
    }

    &--bigText {
        margin-bottom: $_stackM;

        .#{$baseClass}__bodyItem {
            font-size: 16px;
            padding-top: 13px;
            padding-bottom: 9px;
        }

        .#{$baseClass}__caption {
            color: $_colorCodGray;
            font-size: 16px;
        }

        .#{$baseClass}__headItem {
            font-size: 18px;
        }
    }

    &--loading {
        .#{$baseClass}__loadingSpinner {
            max-height: 100px;
            opacity: 1;
        }

        padding-bottom: 20px;
    }

    &--borderBottom {
        .#{$baseClass}__body:last-child {
            border-bottom: 1px solid $_colorAlto;
        }
    }

    &--uncompressed {
        .#{$baseClass}__bodyItem {
            @media #{$_mediaMDown} {
                padding: 10px 20px 7px 0;
            }
        }
    }
}

.#{$baseClass}__table {
    width: 100%;
}

.#{$baseClass}__loadingSpinner {
    height: 100px;
    position: relative;
    max-height: 0;
    opacity: 0;
    overflow: hidden;
    transition: max-height $_timeS ease-in, opacity $_timeS ease-in;
}

.#{$baseClass}__caption {
    color: $_colorCodGray;
    font-size: 14px;
    font-weight: bold;
    line-height: 20px;
    text-align: left;
    margin-bottom: 5px;
}

.#{$baseClass}__head {
    border-bottom: 1px solid $_colorAlto;
}

.#{$baseClass}__headItem {
    padding: 10px 12px 7px $_insetS;

    line-height: 20px;
    font-size: 17px;
    font-weight: bold;
    text-align: left;

    &--bold {
        font-weight: bold;
    }

    &--right {
        text-align: right;
    }
}

.#{$baseClass}__spinner {
    animation: linearRotationKeyframes $_timeL ease-in-out infinite;
    position: absolute;
    top: 30%;
    left: 50%;
    transform: translateY(-50%);
    z-index: 2;
    width: 50px;
    height: 50px;

    g {
        stroke: $_colorAlto;
    }
}

.#{$baseClass}__body {
    border-bottom: 1px solid $_colorAlto;

    &:last-child {
        border-bottom: 0;
    }
}

.#{$baseClass}__bodyItem {
    padding: 10px 12px 7px $_insetS;

    color: $_colorNero;
    font-size: 14px;
    line-height: 17px;
    max-width: 325px;

    &--bold {
        font-weight: bold;
    }

    &--right {
        text-align: right;
    }

    .#{$baseClass}--padding & {
        padding-left: 20px;
    }

    &--padding {
        padding-left: 20px;
    }

    a {
        cursor: pointer;

        &:hover,
        &:active {
            color: $_colorEndeavour;
            text-decoration: underline;
        }
    }
}

.#{$baseClass}__loadMoreButton {
    margin: $_stackM auto;
    display: block;

    &--hidden {
        display: none;
    }
}

@media #{$_mediaSDown} {
    .#{$baseClass}__headItem {
        padding: $_inlineS $_inlineXS;
    }

    .#{$baseClass}__bodyItem {
        padding: $_inlineXS $_inlineS $_inlineXS $_inlineXS;

        .#{$baseClass}--padding & {
            padding-left: $_inlineXS;
        }
    }
}

@media #{$_mediaMDown} {
    .#{$baseClass},
    .#{$baseClass}__table {
        overflow-x: scroll;
    }
}
