$baseClass: baseclass('ContentText');

.#{$baseClass} {
    &--fullWidth {
        width: 100%;
    }

    max-width: 100%;

    h1,
    h2,
    h3,
    h4,
    h5 {
        margin-top: 1em !important;

        font-weight: bold;
        line-height: 1.25em;

        & + * {
            margin-top: 0.5em;
        }

        &:first-child {
            margin-top: 0 !important;
        }
    }

    h1 {
        margin-bottom: 0.5em;

        font-size: $_fontSizeXL;
        color: $_colorSecondary;
    }

    h2 {
        font-size: $_fontSizeL;
    }

    h3 {
        font-size: 20px;
    }

    table,
    p,
    ol,
    ul,
    blockquote {
        margin-bottom: 0.5em;

        font-size: 16px;
        line-height: 26px;
    }

    ol,
    ul {
        margin-left: 1.5em;
    }

    ol {
        list-style: decimal;
    }

    ul {
        list-style: disc;
    }

    a {
        color: $_colorSecondary;
        text-decoration: underline;
    }

    b,
    strong,
    th {
        font-weight: bold;
        font-size: 17px;
    }

    u {
        text-decoration: underline;
    }

    em,
    i {
        font-style: italic;
    }

    tr {
        & + & {
            th,
            td {
                padding-top: $_insetS;
            }
        }
    }

    td,
    th {
        padding-left: $_insetM;
        vertical-align: top;

        &:first-child {
            padding-left: 0;
        }
    }

    .ql-indent-1 {
        margin-left: 1.5em;
    }

    .ql-indent-2 {
        margin-left: 3em;
    }

    .ql-indent-3 {
        margin-left: 4.5em;
    }

    .ql-indent-4 {
        margin-left: 6em;
    }

    .ql-indent-5 {
        margin-left: 7.5em;
    }

    .ql-indent-6 {
        margin-left: 9em;
    }

    .ql-indent-7 {
        margin-left: 10.5em;
    }

    blockquote {
        border-left: 4px solid $_colorRollingStone;
        padding-left: calc(1.5em - 3px);
    }

    &.#{$baseClass}__k-com {
        h1 {
            color: $_colorBrand;
        }
    }
}
