$baseClass: baseclass('Button');

.#{$baseClass} {
  display: inline-block;
  width: auto;
  padding: 0;
  flex-shrink: 0;
  position: relative;

  font-size: $_fontSizeM;
  font-weight: bold;
  line-height: 26px;
  text-transform: uppercase;

  text-align: center;
  color: $_colorSecondary;

  cursor: pointer;

  transition: color $_timeS ease-in-out;

  @include blueFocus;

  &:focus {
    z-index: 1;
  }

  &__inner {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;

    padding: 6px $_insetM;
  }

  &--disabled {
    pointer-events: none;
    color: $_colorBlackDisabled;

    &.#{$baseClass}__icon {
      fill: $_colorBlackDisabled;
    }
  }

  &--primary,
  &--secondary,
  &--tertiary,
  &--ghostOrange,
  &--ghostWhite {
    border-radius: $_borderRadiusDefault;
    transition: all $_timeXS ease-in-out;
    border: 1px solid;
  }

  &--primary.#{$baseClass}--error {
    background-color: red;
  }

  &--primary,
  &--secondary,
  &--tertiary {
    &:hover,
    &.#{$baseClass}--active,
    &:active {
      background-color: $_colorSecondary;
      border-color: $_colorSecondary;
      color: $_colorWhite;

      &:not(.#{$baseClass}--loading) {
        .#{$baseClass}__icon {
          &,
          path {
            fill: $_colorWhite;
          }
        }
      }
    }

    &.#{$baseClass}--disabled {
      background-color: $_colorSilver;
      color: $_colorWhite;

      border-color: $_colorSilver;

      .#{$baseClass}__icon {
        &,
        path {
          fill: $_colorWhite;
        }
      }
    }

    .#{$baseClass}__inner {
      padding: 6px $_insetL;
    }
  }

  &--secondary {
    color: $_colorRollingStone;
    background-color: $_colorWhite;
    border-color: $_colorAlto;

    .#{$baseClass}__icon {
      fill: $_colorRollingStone;

      path {
        fill: $_colorRollingStone;
      }
    }
  }

  &--flatGray {
    color: $_colorRollingStone;
  }

  &--lowercase {
    text-transform: unset;
  }

  &--stretched {
    height: 100%;
  }

  &--primary {
    color: $_colorWhite;
    background-color: $_colorBrand;
    border-color: $_colorBrand;

    @include blueFocus;

    .#{$baseClass}__icon {
      &,
      path {
        fill: $_colorWhite;
      }
    }
  }

  &--tertiary {
    color: $_colorWhite;
    background-color: $_colorSecondary;
    border-color: $_colorSecondary;

    @include whiteFocus;

    .#{$baseClass}__icon {
      &,
      path {
        fill: $_colorWhite;
      }
    }
  }

  &--ghostOrange {
    color: $_colorBrand;
    border-color: $_colorAlto;
    background: $_colorWhite;

    .#{$baseClass}__icon {
      &,
      path {
        fill: $_colorBrand;
      }
    }

    &:hover {
      background: darken($_colorWhite, 2);
    }
  }

  &--ghostWhite {
    color: $_colorWhite;
    border-color: $_colorWhite;

    .#{$baseClass}__icon {
      &,
      path {
        fill: $_colorWhite;
      }
    }

    &:hover {
      background: rgba($_colorWhite, 0.2);
    }
  }

  &--smallBubble {
    .#{$baseClass}__bubble {
      font-size: 12px;
    }
  }

  &--expand {
    display: block;
    flex-grow: 1;

    .#{$baseClass}__inner {
      padding-left: $_insetM;
      padding-right: $_insetM;
    }
  }

  &--full {
    display: block;
    width: 100%;

    .#{$baseClass}__inner {
      padding-left: $_insetM;
      padding-right: $_insetM;
    }
  }

  &--slim {
    .#{$baseClass}__inner {
      padding-left: 12px;
      padding-right: 12px;
    }
  }

  &--block {
    width: 42px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0;
    border: none;

    color: $_colorRollingStone;
    font-size: 12px;
    letter-spacing: 0;
    line-height: 24px;

    .#{$baseClass}__inner {
      padding-left: 10px;
      padding-right: 10px;
    }

    .#{$baseClass}__icon {
      margin: 0;

      & + .#{$baseClass}__title {
        margin-left: $_inlineS;
      }
    }

    &:not(:hover) {
      &.#{$baseClass}--activeOrange {
        color: $_colorBrand;

        .#{$baseClass}__icon {
          fill: $_colorBrand;
        }
      }
    }

    &.#{$baseClass}--disabled {
      background: none;
    }
  }

  &--icon {
    .#{$baseClass}__icon {
      & + .#{$baseClass}__title {
        margin-left: $_inlineS;
      }
    }
  }

  &--iconInverted {
    .#{$baseClass}__inner {
      flex-direction: row-reverse;
    }

    .#{$baseClass}__icon {
      & + .#{$baseClass}__title {
        margin-right: $_inlineS;
      }
    }
  }

  &--hasLoading {
    .#{$baseClass}__inner {
      position: relative;
    }

    .#{$baseClass}__icon--loading {
      animation: linearRotationKeyframes $_timeL ease-in-out infinite;
      opacity: 0;
      position: absolute;
      left: calc(50% - 16px);
      top: 3px;
      transition: opacity $_timeS ease-in-out;

      path {
        fill: none;
      }
    }

    .#{$baseClass}__icon + .#{$baseClass}__title {
      transition: opacity $_timeS ease-in-out;
    }

    &.#{$baseClass}--loading {
      background-color: $_colorSecondary;
      border-color: $_colorSecondary;
      pointer-events: none;

      .#{$baseClass}__icon.#{$baseClass}__icon--loading {
        opacity: 1;
      }

      .#{$baseClass}__icon,
      .#{$baseClass}__icon + .#{$baseClass}__title {
        opacity: 0;
      }
    }
  }

  &--small {
    .#{$baseClass}__inner {
      height: 30px;
      padding: 0 $_insetM;

      font-size: 12px;
      line-height: 16px;
    }
  }

  &--smaller {
    .#{$baseClass}__inner {
      height: 28px;
      padding: 0 $_insetS;

      font-size: 11px;
      line-height: 15px;
    }
  }

  &--reverse {
    .#{$baseClass}__inner {
      flex-direction: row-reverse;

      .#{$baseClass}__title {
        margin-right: $_stackS;
        margin-left: 0;
      }
    }
  }

  &--nowrap {
    .#{$baseClass}__title {
      white-space: nowrap;
    }
  }
}

.#{$baseClass}__title {
  &:empty {
    display: none;
  }
}

.#{$baseClass}__icon {
  flex-shrink: 0;

  &,
  path {
    fill: $_colorSecondary;
  }
}

.#{$baseClass}__bubble {
  position: absolute;
  top: 0;
  right: 0;
  width: 26px;
  height: 26px;
  transform: translate(50%, -50%);
  border-radius: 50%;
  background: $_colorSecondary;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;

  color: $_colorWhite;
  font-size: 14px;
  font-weight: bold;
}

.#{$baseClass}__bubbleInner {
  transform: translate(0, 5%);
}

@media #{$_mediaMUp} {
  .#{$baseClass} {
    &--label-mobile {
      .#{$baseClass}__title {
        display: none;
      }
    }

    &--mobile {
      display: none;
    }
  }
}

@media #{$_mediaLUp} {
  .#{$baseClass} {
    &--label-desktop {
      .#{$baseClass}__icon + .#{$baseClass}__title {
        display: block;
        margin-left: 0;
      }
    }

    &--icon-mobile {
      .#{$baseClass}__icon {
        display: none;
      }
    }
  }
}

@media #{$_mediaMDown} {
  .#{$baseClass} {
    &--label-desktop {
      .#{$baseClass}__title {
        display: none;
      }
    }

    &--icon-mobile {
      .#{$baseClass}__icon {
        display: block;
      }
    }
  }
}


@media (max-width: 600px) {
  .#{$baseClass} {
    &--ghostWhite {
      &.#{$baseClass}--icon {
        .#{$baseClass}__inner {
          padding: 9px;

          .#{$baseClass}__title {
            display: none;
          }
        }
      }
    }
  }
}

@media #{$_mediaSDown} {
  .#{$baseClass} {
    &--primary,
    &--secondary {
      .#{$baseClass}__inner {
        padding: 6px $_insetM;
      }
    }
  }

  .#{$baseClass}__bubble {
    transform: translate(25%, -25%);
  }
}

.#{$baseClass}--rebranding {
  @include rebranding-para-bold;

  &.#{$baseClass}--primary {
    color: $_colorRebrandingWhite;
    background-color: $_colorRebrandingPrimary;
    border-color: $_colorRebrandingPrimary;

    &:hover {
      background-color: $_colorRebrandingPrimaryHover;
    }

    &:active {
      background-color: $_colorRebrandingPrimaryPressed;
    }

    &.#{$baseClass}--disabled {
      background-color: $_colorRebrandingPrimaryDisabled;
    }

    &.#{$baseClass}--loading {
      background-color: $_colorRebrandingPrimary;
    }
  }

  &.#{$baseClass}--secondary {
    border-color: $_colorRebrandingAbbey;
    color: $_colorRebrandingAbbey;
    background: none;

    &:hover {
      border-color: $_colorRebrandingBlackOut;
      color: $_colorRebrandingBlack;
    }

    &:active {
      border-color: $_colorRebrandingFrenchGray;
      color: $_colorRebrandingGallery;
    }

    &.#{$baseClass}--disabled {
      border-color: $_colorRebrandingFrenchGray;
      color: $_colorRebrandingFrenchGray;
    }

    &.#{$baseClass}--loading {
      background-color: $_colorRebrandingAbbey;
    }
  }

  &.#{$baseClass}--icon {
    &.#{$baseClass}--secondary {
      svg path {
        fill: $_colorRebrandingAbbey;
      }

      &:hover {
        svg path {
          fill: $_colorRebrandingBlackOut;
        }
      }

      &:active {
        svg path {
          fill: $_colorRebrandingAbbey;
        }
      }

      &--disabled {
        svg path {
          fill: $_colorRebrandingFrenchGray;
        }
      }
    }
  }
}
