$baseClass: baseclass("ExpandableContainer");

.#{$baseClass}__base {
  padding: $_insetS;
}

.#{$baseClass}__button {
  display: block;
  margin: 0 auto;

  &--hidden {
    display: none;
  }
}

.#{$baseClass}__collapsable {
  max-height: 100px;
  overflow: hidden;
  transition: max-height 0.3s ease-in;

  @media #{$_mediaMDown} {
    border-bottom: 1px solid $_colorAlt;
  }
}
