$baseClass: baseclass('Input');

.#{$baseClass} {
  font-size: $_fontSizeM;

  .#{$baseClass}__input {
    width: 100%;
    padding: 12px $_insetM;
    border: none;
    border: 1px solid $_colorAlto;
    border-radius: $_borderRadiusDefault;
    margin: 0;

    box-shadow: inset 0 0 0 25px $_colorWhite;
    background-color: transparent;
    background-clip: content-box;
    transition: all $_timeS;

    line-height: 14px;
    color: $_colorMineShaft;

    overflow: hidden;
    text-overflow: ellipsis;

    ::placeholder {
      text-overflow: ellipsis;
    }

    &::-ms-clear {
      display: none;
      width: 0;
      color: $_colorWhite;
      height: 0;
    }
  }

  &--label {
    position: relative;
    padding-top: 9px;
  }

  &--hidden {
    position: absolute;
    left: -9999px;
    visibility: hidden;
  }

  &--initialized {
    .#{$baseClass}__label {
      opacity: 1;
      transition: opacity $_timeS, color $_timeS, transform $_timeS;
    }
  }

  &--focused:not(&--error),
  &--focused:not(&--disabled) {
    .#{$baseClass}__label {
      color: $_colorChathamsBlue;
    }

    .#{$baseClass}__input {
      border: 1px solid $_colorChathamsBlue;
    }

    &.#{$baseClass}--withButton {
      .#{$baseClass}__input {
        border-right: 0;
      }
    }
  }

  &--yellow {
    .#{$baseClass}__input {
      box-shadow: inset 0 0 0 25px #F4D310;
    }

    .#{$baseClass}__label {
      background: #F4D310;
      overflow: visible;

      &::before {
        content: '';
        display: block;
        background: white;
        position: absolute;
        top: -1px;
        left: 0;
        width: 100%;
        height: 50%;
        z-index: -1;
        right: 0;
        opacity: 0;
        transition: opacity $_timeS;
      }
    }
  }

  &--filled,
  &--focused,
  &--top {
    .#{$baseClass}__label {
      transform: translate3d(0, -25px, 0) scale(0.75, 0.75);
    }

    .#{$baseClass}__label::before {
      opacity: 1;
    }
  }

  &--disabled {
    pointer-events: none;

    .#{$baseClass}__label {
      color: $_colorBoulder;
    }

    .#{$baseClass}__input {
      color: $_colorSilver;
      -webkit-text-fill-color: $_colorSilver;
      opacity: 1;
    }
  }

  &--white {
    .#{$baseClass}__label {
      color: $_colorWhite !important;
      background: transparent;
    }

    .#{$baseClass}__input {
      color: $_colorWhite !important;
      box-shadow: inset 0 0 0 25px $_colorBrand;
      height: 28px;
    }
  }

  &--error,
  &--error.#{$baseClass}--focused {
    .#{$baseClass}__error {
      color: $_colorGuardsmanRed;
      opacity: 1;
      display: block;
    }

    .#{$baseClass}__label {
      color: $_colorGuardsmanRed;
    }

    .#{$baseClass}__input {
      border-color: $_colorGuardsmanRed;
    }

    &.#{$baseClass}--focused:not(.#{$baseClass}--disabled) {
      .#{$baseClass}__input {
        border-color: $_colorGuardsmanRed;
      }
    }

    .#{$baseClass}__icon {
      fill: $_colorGuardsmanRed;
    }

    &.#{$baseClass}--withButton {
      .#{$baseClass}__input {
        border-right: 0;
      }
    }
  }

  &--icon,
  &--valid,
  &--error {
    .#{$baseClass}__input {
      padding-right: 44px;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &.#{$baseClass}--has-clear-button {
      .#{$baseClass}__input {
        padding-right: 70px;
      }

      .#{$baseClass}__icon--clear {
        right: 42px;
      }
    }
  }

  &--valid {
    .#{$baseClass}__validIcon {
      opacity: 1;
    }
  }

  &--error {
    .#{$baseClass}__invalidIcon {
      opacity: 1;
    }
  }

  &--withButton {
    .#{$baseClass}__input {
      border-right: 0;
      border-radius: $_borderRadiusDefault 0 0 $_borderRadiusDefault;
      height: 40px;
    }

    &.#{$baseClass}--filled,
    &.#{$baseClass}--focused,
    &.#{$baseClass}--top {
      .#{$baseClass}__label {
        transform: translate3d(3px, -21px, 0) scale(0.75, 0.75);
      }

      .#{$baseClass}__label::before {
        opacity: 1;
      }
    }
  }

  &--iconLeft {
    .#{$baseClass}__label {
      margin-left: 32px;
    }

    .#{$baseClass}__input {
      height: 40px;
      padding-left: 32px;
    }

    &.#{$baseClass}--filled,
    &.#{$baseClass}--focused {
      .#{$baseClass}__label {
        transform: translate3d(5px, -22px, 0) scale(0.75, 0.75);
      }

      .#{$baseClass}__label::before {
        opacity: 1;
      }
    }
  }

  &__icon--show {
    .#{$baseClass}__input {
      padding-right: 44px;
    }

    .#{$baseClass}__icon--clear {
      visibility: visible;
      z-index: 1000;
      position: absolute;
    }
  }
}

.#{$baseClass}__inputWrapper {
  position: relative;

  input[type="password"] {
    padding-right: $_inlineXL;
    outline: none;
  }
}

.#{$baseClass}__input {
  height: 48px;
}

.#{$baseClass}__label {
  position: absolute;
  z-index: 0;
  top: 50%;
  left: 0;
  margin-top: -7px;
  opacity: 0;
  padding-left: 6px;
  padding-right: 6px;
  margin-left: 10px;
  background: $_colorWhite;

  max-width: calc(100% - 27px);
  overflow: hidden;
  text-overflow: ellipsis;

  white-space: nowrap;
  color: $_colorRollingStone;
  line-height: 1.1;
  transform-origin: left center;

  pointer-events: none;
}

.#{$baseClass}__icon {
  pointer-events: none;
  position: absolute;
  right: 16px;
  top: calc(50%);
  transform: translateY(-50%);
  transition: opacity $_timeXS;
  height: 20px;
  width: 20px;
  fill: $_colorBlackDisabled;

  &--clear {
    pointer-events: all;
    cursor: pointer;
    visibility: hidden;
    z-index: -1;
  }
}

.#{$baseClass}__validIcon {
  fill: $_colorJapaneseLaurel;
}

.#{$baseClass}__invalidIcon {
  path {
    fill: $_colorGuardsmanRed;
  }
}

.#{$baseClass}__validIcon,
.#{$baseClass}__invalidIcon {
  pointer-events: none;
  opacity: 0;

  transition: opacity $_timeXS ease-in-out;
}

.#{$baseClass}__message,
.#{$baseClass}__error {
  color: $_colorMineShaft;
  font-size: $_fontSizeS;
  line-height: 16px;
  padding-left: 17px;
}

.#{$baseClass}__error {
  opacity: 0;
  display: none;
  transition: opacity $_timeS ease-in-out;
}

.#{$baseClass}--rebranding {
  // Standard
  .#{$baseClass}__input {
    @include rebranding-para-regular;
    border-color: $_colorRebrandingFrenchGray;
    color: $_colorRebrandingBlackOut;
  }

  .#{$baseClass}__label {
    @include rebranding-para-regular;
    top: 40%;
    color: $_colorRebrandingManatee;
  }

  // Focused
  .#{$baseClass}--focused {
    .#{$baseClass}__input {
      border-color: $_colorRebrandingPrimary;
    }

    .#{$baseClass}__label {
      color: $_colorRebrandingPrimary;
    }
  }

  // Error
  .#{$baseClass}--error {
    .#{$baseClass}__input {
      border-color: $_colorRebrandingSemanticRed;
    }

    .#{$baseClass}__label {
      color: $_colorRebrandingSemanticRed;
    }
  }

  // Disabled
  .#{$baseClass}--disabled {
    .#{$baseClass}__input {
      color: $_colorRebrandingFrenchGray;
    }

    .#{$baseClass}__label {
      color: $_colorRebrandingAbbey;
    }
  }
}
