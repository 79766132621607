$baseClass: baseclass("FoldableLinksList");

.#{$baseClass} {
    &--expanded {
        .#{$baseClass}__headerArrow {
            transform: rotate(180deg);
        }

        .#{$baseClass}__collapsable {
            @media #{$_mediaMDown} {
                transition: max-height 0.3s ease-in;
                max-height: 500px;
            }
        }
    }

    &--light {
        .#{$baseClass}__listItem {
            @media #{$_mediaLUp} {
                font-weight: 400;
                border-bottom: none;
            }
        }
        .#{$baseClass}__itemChevron {
            @media #{$_mediaLUp} {
                max-width: 16px;
            }
        }
    }
    &--noMobileImgs {
        .#{$baseClass}__imgWrapper {
            @media #{$_mediaMDown} {
                display: none;
            }
        }
    }

    &--fixedImageRatio {
        .#{$baseClass}__image {
            max-width: 55px !important;
        }
    }
}

.#{$baseClass}__header {
    height: 55px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;

    @media #{$_mediaMDown} {
        height: 50px;
        padding: 0 $_insetS;

        .#{$baseClass}__imgWrapper {
            width: 50px;
        }
    }
}

.#{$baseClass}__image {
    max-width: 100%;
    max-height: 55px;
    vertical-align: middle;
    flex-shrink: 0;

    @media #{$_mediaMDown} {
        display: block;
        max-height: 35px;
        max-width: 100%;
        margin: 0 auto;
    }
}

.#{$baseClass}__headerTitleWrapp {
    flex-grow: 1;
    padding-left: $_insetS;
}

.#{$baseClass}__headerTitle {
    font-size: 16px;
    font-weight: bold;
}

.#{$baseClass}__preTitle {
    font-size: 14px;
    color: $_colorRollingStone;

    @media #{$_mediaMDown} {
        display: none;
    }
}

.#{$baseClass}__headerArrow {
    display: inline-block;
    transition: transform 0.3s ease-in-out;

    @media #{$_mediaLUp} {
        display: none;
    }
}

.#{$baseClass}__collapsable {
    @media #{$_mediaMDown} {
        transition: max-height 0.3s ease-in;
        max-height: 0;
        overflow: hidden;
    }
}

.#{$baseClass}__listItem {
    font-size: 14px;
    height: 40px;
    font-weight: bold;
    border-bottom: solid 1px $_colorAlt;
    padding: 12px 0;

    &:last-of-type {
        border-bottom-color: transparent;
    }

    @media #{$_mediaMDown} {
        font-weight: normal;
        height: 50px;
        font-size: 16px;
        padding: 12px;
        padding-left: 16px;
    }
}

.#{$baseClass}__itemInner {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;

    .#{$baseClass}__itemChevron {
        margin-left: -8px;

        @media #{$_mediaMDown} {
            order: 2;
            margin-left: auto;
        }
    }
}
