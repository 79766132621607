$baseClass: baseclass('SeriesModelList');

.#{$baseClass} {
    width: 100%;
    margin-top: $_stackXL;
    margin-bottom: $_stackXL;

    &--alignLeft {
        .#{$baseClass}__columnsArea {
            justify-content: flex-start;
        }

        .#{$baseClass}__column {
            margin-right: 24px;
        }
    }
}

.#{$baseClass}__title {
    color: $_colorChathamsBlue;
    font-size: 20px;
    margin-bottom: $_stackS;
    font-weight: 700;
    line-height: 20px;

    @media #{$_mediaMDown} {
        margin-bottom: 0;
        padding-bottom: $_insetM;
        border-bottom: 1px solid $_colorAlto;
        line-height: 18px;
        font-size: 18px;
    }
}

.#{$baseClass}__columnsArea {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    width: 100%;
    align-items: flex-start;

    @media #{$_mediaMDown} {
        display: none;
    }
}

.#{$baseClass}__column {
    flex: 0 1 30%;
    border-top: 1px solid $_colorAlto;
    border-bottom: 1px solid $_colorAlto;
}

.#{$baseClass}__mobile {
    @media #{$_mediaLUp} {
        display: none;
    }
}

.#{$baseClass}__collapsable {
    max-height: 0;
    overflow-y: hidden;
    transition: max-height 0.3s 0 ease-in;
}

.#{$baseClass}__showMoreButton {
    margin: $_insetM auto;
    display: block;
}

.#{$baseClass}__extraInfo {
    &--expanded {
        border-top: 1px solid $_colorAlto;

        .#{$baseClass}__collapsable {
            max-height: 1000px;
        }

        .#{$baseClass}__showMoreButton {
            display: none;
        }
    }

    @media #{$_mediaLUp} {
        display: none;
    }
}
