$baseClass: baseclass('CarisFilter');

.#{$baseClass} {
    &--animate {
        .#{$baseClass}__views {
            transition: transform $_timeS ease-in-out;
        }
    }
}

.#{$baseClass}__header {
    flex-shrink: 0;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid $_colorAlto;
}

.#{$baseClass}__headerButton {
    display: flex;
    justify-content: center;
    align-items: center;
    background: $_colorBrand;
    cursor: pointer;
    padding: $_insetM;

    @include blueFocus;
}

.#{$baseClass}__headerContent {
    flex-grow: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    white-space: nowrap;
    overflow: hidden;
    padding: $_insetM;
}

.#{$baseClass}__headerIcon {
    fill: $_colorWhite;
}

.#{$baseClass}__headerText {
    text-overflow: ellipsis;

    font-size: 16px;
    line-height: 22px;
    font-weight: bold;
}

.#{$baseClass}__views {
    display: flex;
    flex-direction: row;
    overflow: hidden;
    width: 300%;
    height: calc(100vh - 57px);

    &--animate {
        transition: transform $_timeS ease-in-out;
    }
}

.#{$baseClass}__view {
    width: 33.333%;
}
