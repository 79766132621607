$baseClass: baseclass('Wishlist');

.#{$baseClass} {
    width: 100%;
    background: $_colorWhite;
    padding-top: $_insetM;
}

.#{$baseClass}__box {
    padding: 7px;
    border: 1px solid $_colorAlto;
}

.#{$baseClass}__tile {
    & + .#{$baseClass}__box {
        border-top: none;
    }
}

.#{$baseClass}__headline {
    margin-bottom: $_stackM;

    font-weight: bold;
    color: $_colorSecondary;
    font-size: $_fontSizeL;
    line-height: 26px;
}

.#{$baseClass}__loggedOut {
    margin-bottom: $_stackL;
}

.#{$baseClass}__loggedOutText {
    margin-bottom: $_stackM;

    font-size: $_fontSizeM;
    line-height: 16px;
}

.#{$baseClass}__header {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: $_stackXS;
}

.#{$baseClass}__status {
    margin: $_stackXS;

    font-size: $_fontSizeS;
    line-height: 20px;
}

.#{$baseClass}__statusLabel {
    color: $_colorMineShaft;
    font-weight: bold;
    font-size: $_fontSizeM;
}

.#{$baseClass}__statusValue {
    font-size: $_fontSizeM;
}

.#{$baseClass}__buttons {
    display: flex;
    flex-wrap: wrap;
}

.#{$baseClass}__button {
    flex-grow: 1;
    margin: $_stackXS;
}

.#{$baseClass}__empty {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    margin: $_stackM $_stackS;
}

.#{$baseClass}__emptyText {
    margin: $_stackS;

    line-height: 20px;
}

.#{$baseClass}__emptyButton {
    margin: $_stackS;
}

@media #{$_mediaMUp} {
    .#{$baseClass}__empty {
        margin: $_stackL $_stackM;
    }

    .#{$baseClass}__emptyText {
        margin: $_stackM;
    }

    .#{$baseClass}__emptyButton {
        margin: $_stackM;
    }
}
