$baseClass: baseclass('AutosuggestLayer');

.#{$baseClass} {
    position: fixed;
    z-index: 11;
    margin-top: 10px;
    display: none;

    &--visible {
        display: block;
    }

    &::before {
        content: '';
        width: 15px;
        height: 15px;
        position: absolute;
        left: 30px;
        top: -6px;
        z-index: 1;
        transform: translateX(-50%) scale(0.6, 1) rotate(45deg);
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.3);

        background: $_colorWhite;
    }

    &::after {
        content: '';
        display: block;
        position: fixed;
        z-index: 0;
        top: -100vh;
        left: -100vw;
        width: 300vw;
        height: 300vh;
    }
}

.#{$baseClass}__message {
    position: relative;
    z-index: 2;
    padding: 12px;
    border-bottom: 1px solid $_colorGallery;
    background: $_colorWhite;

    font-weight: bold;
    font-size: 14px;

    &:empty {
        display: none;
    }
}

.#{$baseClass}__items {
    background: $_colorWhite;
    overflow: hidden;
    border-bottom-right-radius: $_borderRadiusDefault;
    border-bottom-left-radius: $_borderRadiusDefault;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.3);
    padding: $_insetS 0;

    &::before {
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        z-index: 1;
        background: $_colorWhite;
    }
}

.#{$baseClass}__item {
    position: relative;
    z-index: 2;
    padding: $_insetS $_insetM;
    background: $_colorWhite;

    transition: background-color $_timeS ease-in-out;
    cursor: pointer;

    &--active {
        background: $_colorGallery;
    }

    em {
        color: $_colorBrand;
    }
}
