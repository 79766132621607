$baseClass: baseclass('VoucherTrigger');

.#{$baseClass} {
    background: $_colorWhite;
}

.#{$baseClass}__button {
    width: 100%;
    font-size: 12px;
}
