$baseClass: baseclass('Bubble');


.#{$baseClass} {
    color: #FFFFFF;
    display: inline-block;

    &__shape {
        background-color: $_colorMonza;
        border-radius: 50%;
        width: 54px;
        height: 54px;
    }

    &__inner {
        height: 100%;
        text-align: center;
        line-height: 10px;
        padding-top: 16px;

        & h2 {
            font-size: $_fontSizeM;
            font-weight: 600;
            margin: 0;
        }

        & span {
            font-size: $_fontSizeS;
        }
    }
}
