$baseClass: baseclass('FooterInformation');

.#{$baseClass} {
    max-width: 100%;

    .#{$baseClass}__itemsInner {
        display: flex;
        flex-wrap: wrap;
    }

    .#{$baseClass}__headline {
        margin-bottom: 13px;
        color: $_colorRollingStone;
        font-weight: bold;
        line-height: 1.4em;
    }

    .#{$baseClass}__payment {
        margin-bottom: $_stackM;
        max-width: 850px;
    }

    .#{$baseClass}__item {
        cursor: default;
        margin: 0 $_stackL $_stackM 0;
        color: $_colorRollingStone;
        font-size: 13px;
        line-height: 18px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        & .#{$baseClass}__itemIcon {
            max-height: 30px;
            max-width: 70px;

            &--generic {
                &,
                g,
                path {
                    filter: invert(52%) sepia(21%) saturate(168%) hue-rotate(149deg) brightness(88%) contrast(92%);
                }
            }

            &--gray {
                filter: grayscale(100%);
                opacity: 0.9;
            }
        }
    }

    .#{$baseClass}__itemLabel {
        margin-top: $_stackXS;

        font-size: $_fontSizeS;
        color: $_colorRollingStone;
    }
}
