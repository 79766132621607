$baseClass: baseclass('AddressForm');

.#{$baseClass} {
    background: $_colorWhite;
}

.#{$baseClass}__buttons {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-top: $_insetL;
}
