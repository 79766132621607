$baseClass: baseclass('AddressFormFields');

.#{$baseClass} {
    display: flex;
    flex-wrap: wrap;

    margin-left: -$_stackS;
    margin-right: -$_stackS;
}

.#{$baseClass}__input {
    flex-grow: 1;
    width: 100%;
    padding-left: $_insetS;
    padding-right: $_insetS;
    margin: $_stackS 0 !important;

    &--hide {
        display: none;
    }
}

.#{$baseClass}__packageStation--active {
    & ~ .#{$baseClass}__inputPackageStation {
        display: block;
    }

    & ~ .#{$baseClass}__locationContainer,
    & ~ .#{$baseClass}__street,
    & ~ .#{$baseClass}__number,
    & ~ .#{$baseClass}__street2,
    & ~ .#{$baseClass}__inputDefaultBilling {
        display: none;
    }
}

.#{$baseClass}__prefix--company {
    & ~ .#{$baseClass}__inputCompany {
        display: block;
    }
}

.#{$baseClass}__inputCompany {
    display: none;
}

@media #{$_mediaMUp} {
    .#{$baseClass}__input {
        &--three-quarter {
            width: 75%;
        }

        &--half {
            width: 50%;
        }

        &--quarter {
            width: 25%;
        }
    }
}

@media #{$_mediaSDown} {
    .#{$baseClass} {
        margin-left: -$_stackXS;
        margin-right: -$_stackXS;
    }

    .#{$baseClass}__input {
        padding-left: $_insetXS;
        padding-right: $_insetXS;
        margin: $_stackXS 0 !important;
    }
}

.pac-container {
    border-top: none;
    z-index: 100000;

    &::after {
        border-top: 1px solid #E6E6E6;
        padding: 5px;
        background-position: calc(100% - 10px) center;
        opacity: 0.5;
    }
}
