$baseClass: baseclass('Logo');

.#{$baseClass} {
    display: block;

    &--reduced {
        .#{$baseClass}__small {
            display: block;
            width: 77px;
            height: 15px;
        }

        .#{$baseClass}__regular {
            display: none;
        }
    }
}

.#{$baseClass}__icon {
    width: 100%;
    height: auto;
}

.#{$baseClass}__small {
    width: 115px;
}

.#{$baseClass}__regular {
    display: none;
    width: 128px;
}

.#{$baseClass}--kfzteile-com {
    .#{$baseClass}__regular {
        width: 243px;

        @media #{$_mediaMDown} {
            width: 170px;
        }
    }
}

@media #{$_mediaMUp} {
    .#{$baseClass}__small {
        display: none;
    }

    .#{$baseClass}__regular {
        display: block;
    }
}

@media #{$_mediaLUp} {
    .#{$baseClass}__regular {
        width: 150px;
    }
}
