$baseClass: baseclass('UserForm');

.#{$baseClass} {
  &__fields {
    display: flex;
    flex-wrap: wrap;

    margin-left: -$_stackS;
    margin-right: -$_stackS;
  }

  &__input {
    flex-grow: 1;
    width: 100%;
    padding-left: $_insetS;
    padding-right: $_insetS;
    margin: $_stackS 0 !important;

    &--hide {
      display: none;
    }
  }

  &__buttons {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-top: $_insetL;
  }

  &__delete_button {
    margin-right: $_stackS;
  }

  &__company_details_wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 40px;
  }

  &__company_details_header {
    display: flex;
    flex-direction: column;
    width: 100%;
    color: #184187;
    margin-bottom: 24px;
  }

  &__company_details_header_title {
    font-size: 24px;
    line-height: 26px;
    font-weight: bold;
    color: #222222;
  }

  &__company_details_header_subtitle {
    font-size: 16px;
    line-height: 22px;
    margin-top: 4px;
    color: #222222;
  }

  &__company_details_info_wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 16px;
  }

  &__company_details_info_label {
    font-size: 12px;
    line-height: 16px;
    color: #545456;
    margin-bottom: 4px;
  }

  &__company_details_info_value {
    font-size: 16px;
    line-height: 22px;
    color: #222222;
  }

  &__company_information_wrapper {
    border: 1px solid #ededed;
    margin-top: $_stackM + $_stackS;
    padding: 0 16px 16px 16px;
    border-radius: 5px;
  }

  &__subtitle {
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
    color: #222222;
    margin-top: $_stackL + $_stackL
  }

  &__company_information_content {
    p {
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
      color: #222222;
    }
  }

  &__list {
    margin-top: $_stackM;
    color: $_colorNero;

    li {
      display: flex;
      line-height: 22px;
    }

    li:not(:first-child) {
      margin-top: $_stackS;
    }
  }

  &__contact {
    li {
      display: inline-block;
      padding: $_insetS;
    }
  }

  &__info_title {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    color: #222222;
    margin: 16px 0 $_stackS;
  }

  &__circle {
    outline: 2px solid #EDEDED;
    border-radius: 50%;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    color: #222;
    font-weight: bold;
    line-height: 32px;
    margin-right: $_stackS;
    text-align: center;
    min-width: 32px;
    width: 32px;
    height: 32px;
    display: inline-block;
  }

  &__contact {
    a {
      text-decoration: underline;
    }
  }

  &__contact_icon {
    position: relative;
    top: 3px;
  }
}

@media #{$_mediaMUp} {
  .#{$baseClass} {
    &__input {
      &--half {
        flex-grow: 0;
        width: 50%;
      }

      &--quarter {
        flex-grow: 0;
        width: 25%;
      }
    }
  }
}
