@import 'src/sets/shop/scss/libs/slick';

$baseClass: baseclass('ProductDetailImage');

.#{$baseClass} {
    overflow: hidden;
}

.#{$baseClass}__carouselArrow {
    position: absolute;
    top: 0;
    padding: 0;
    z-index: 1;
    cursor: pointer;
    width: 50px;
    overflow: hidden;
    height: 100%;
    display: none;
    align-items: center;
    justify-content: center;

    &--prev {
        left: 0;

        .#{$baseClass}__carouselArrow--icon {
            transform: rotate(180deg);
        }
    }

    &--next {
        right: 0;
    }

    &--visible {
        display: flex !important;
    }

    @include u-hidden-lg-up;
}

.#{$baseClass}__ratioKeeper {
    position: relative;
    padding-bottom: 75%;

    @media #{$_mediaMDown} {
        padding-bottom: 50%;
    }
}

.#{$baseClass}__slickContainer {
    border: 1px solid $_colorAlto;
    margin-bottom: 10px;
    background-color: $_colorWhite;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    overflow: hidden;

    &.slick-slider {
        position: absolute;

        @media #{$_mediaMDown} {
            margin: 0 50px;
            overflow: visible;
        }
    }

    .#{$baseClass}__productImage {
        height: 100%;
        width: 100%;
        background-color: $_colorWhite;
        display: flex;
        flex-flow: column nowrap;
        justify-content: center;
        align-items: center;
        padding: $_inlineS;
    }

    .#{$baseClass}__image {
        max-height: 100%;
        max-width: 100%;
    }

    .slick-list.draggable {
        height: 100%;
    }

    .slick-track {
        height: 100%;
    }

    .slick-slide > div {
        width: 100%;
        height: 100%;
    }
}

.#{$baseClass}__slickContainer .#{$baseClass}__productImage:not(:first-child) {
    display: none;
}

.slick-initialized .#{$baseClass}__productImage {
    display: flex !important;
    position: relative;
}

.#{$baseClass}__brandImage {
    position: absolute;
    top: 15px;
    left: 15px;
    pointer-events: none;
    z-index: 1;
    display: flex;
    height: 100px;
    align-items: flex-start;

    @media #{$_mediaMDown} {
        display: none;
    }

    &--loaded {
        .#{$baseClass}__brandLogo {
            opacity: 1;
        }
    }
}

.#{$baseClass}__brandLogo {
    max-width: 100px;
    opacity: 0;
    position: relative;
    z-index: 1;
    transition: opacity $_timeS ease-in-out;
}

.#{$baseClass}__thumbnailContainer {
    width: calc(100% + 10px);
    margin: -5px;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;

    @media #{$_mediaMDown} {
        display: none;
    }

    .#{$baseClass}__thumbnail {
        width: 20%;
        padding: 5px;
        position: relative;

        &--active {
            .#{$baseClass}__unavailableImage,
            .#{$baseClass}__productImage {
                border-color: $_colorChathamsBlue;
            }
        }

        .#{$baseClass}__unavailableImage {
            position: initial;
            height: 75px;
        }
    }

    .#{$baseClass}__productImage {
        height: 75px;
        overflow: hidden;
        position: relative;
        display: flex;

        cursor: pointer;
        border: 1px solid $_colorAlto;
        background-color: $_colorWhite;
        transition: border-color $_timeXS ease-in-out;
    }

    .#{$baseClass}__image {
        max-height: 75px;
        padding: $_inlineXS;
        display: block;
        margin: 0 auto;
        max-width: 100%;
        align-self: center;
    }
}

.#{$baseClass}__thumbnailImage {
    margin: auto;
}

.#{$baseClass}__unavailableImage {
    display: flex;
    margin-bottom: $_stackL;
    align-items: center;

    border: 1px solid $_colorAlto;
    background-color: $_colorWhite;

    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    &__icon {
        width: 60%;
        height: 60%;
        margin: $_stackM auto;
    }
}
