$baseClass: baseclass('Image');

.#{$baseClass}__img {
    display: block;
    width: auto;
    height: auto;
    filter: none;
    transition: filter $_timeS ease-in-out, opacity $_timeS ease-in-out;
    opacity: 1;
}
