$baseClass: baseclass('Cart');

.#{$baseClass} {
    width: 100%;

    background: $_colorWhite;
}

.#{$baseClass}__buttonSection {
    width: 100%;
    margin-bottom: 14px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
}

.#{$baseClass}__title-wrapper {
    margin: 5px $_stackS 5px 0;
    flex-grow: 99;
}

.#{$baseClass}__dummy {
    flex-grow: 99;
}

.#{$baseClass}__title {
    font-weight: bold;
    font-size: $_fontSizeXL;
    color: $_colorNero;
}

.#{$baseClass}__usp-list {
    background: rgb(249, 249, 249);
    border-radius: 3px;
    width: 100%;
    margin-top: 16px;
    margin-bottom: 32px;
    padding: 20px 24px;
    display: none;

    &--visible {
        display: flex;
    }
}

.#{$baseClass}__usp-item {
    margin-right: 48px;
    display: flex;
    align-items: center;
}

.#{$baseClass}__usp-icon {
    margin-right: 12px;
}

.#{$baseClass}__usp-icon svg {
    fill: rgb(84, 84, 86);
    height: 32px;
    width: 32px;
}

.#{$baseClass}__usp-icon--wrench svg {
    width: 26px;
}

.#{$baseClass}__usp-text {
    font-weight: bold;
    color: $_colorNero;
    font-size: 16px;
    line-height: 22px;
}

.#{$baseClass}__share {
    margin: 6px;
    flex-grow: 1;
}

.#{$baseClass}__clipboard--hidden,
.#{$baseClass}__clipboard-message--hidden {
    display: none;
}

.#{$baseClass}__text {
    margin-bottom: $_stackM;

    font-weight: bold;
    font-size: $_fontSizeS;
    color: $_colorMineShaft;
    line-height: 16px;
}

.#{$baseClass}__footer {
    @include clearfix;

    padding-top: $_insetL;
}

.#{$baseClass}__voucher {
    margin-bottom: $_stackM;
}

.#{$baseClass}__buttons {
    margin: $_stackS -6px;
    display: flex;
    flex-flow: row-reverse wrap;
    flex-grow: 1;
    flex-shrink: 0;
    max-width: calc(100% + 12px);
}

.#{$baseClass}__button {
    margin: 6px;
    flex-shrink: 0;

    &--checkout {
        min-width: 250px;
    }
}

.#{$baseClass}__info {
    clear: both;
}

.#{$baseClass}__empty {
    padding: $_insetL;
    border: 1px solid $_colorAlto;
    min-height: 150px;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
}

.#{$baseClass}__emptyText {
    font-weight: bold;
    color: $_colorMineShaft;
    line-height: 26px;
}

.#{$baseClass}__emptyButtons {
    margin: 0 #{-$_insetS};
}

.#{$baseClass}__emptyButton {
    margin: $_insetS;
}

@media #{$_mediaSDown} {
    .#{$baseClass}__buttonSection {
        &--top {
            .#{$baseClass}__share {
                display: none;
            }
        }
    }
}

@media #{$_mediaMUp} {
    .#{$baseClass}__buttonSection {
        &--bottom {
            .#{$baseClass}__share {
                display: none;
            }
        }
    }

    .#{$baseClass}__voucher {
        float: left;
    }

    .#{$baseClass}__amounts {
        float: right;
    }
}

@media #{$_mediaMDown} {
    .#{$baseClass}__title {
        font-size: 28px;
        line-height: 32px;
    }

    .#{$baseClass}__usp-list {
        flex-direction: column;
    }

    .#{$baseClass}__usp-item {
        align-items: flex-start;

        &:not(:last-child) {
            margin-bottom: 20px;
        }
    }

    .#{$baseClass}__usp-item--hide-in-mobile {
        display: none;
    }
}