$baseClass: baseclass('Radio');

.#{$baseClass} {
  @include clearfix;

  &--block {
    border: 1px solid $_colorBlackDecorative;

    .#{$baseClass}__options {
      align-items: flex-start;
    }

    .#{$baseClass}__option {
      float: none;
      padding: $_insetS $_insetM;

      & + .#{$baseClass}__option {
        border-top: 1px solid $_colorBlackDecorative;
        margin-top: 0;
      }
    }

    .#{$baseClass}__label {
      padding-left: 10px;
    }
  }

  &--inline {
    .#{$baseClass}__options {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
    }

    .#{$baseClass}__option {
      margin-right: $_stackL;

      & + .#{$baseClass}__option {
        margin-top: 0;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }

  &--error {
    .#{$baseClass}__radio {
      border-color: $_colorRed;

      & + {
        .#{$baseClass}__label {
          color: $_colorRed;
        }
      }
    }
  }

  &--inline-text {
    .#{$baseClass}__labelContent {
      display: flex;
    }

    .#{$baseClass}__labelTitle {
      max-width: 15%;
      margin-right: 16px;
      flex-grow: 0;
    }

    .#{$baseClass}__option {
      float: none;
    }
  }

  &__groupLabel {
    padding-bottom: $_stackS;

    font-weight: bold;
    color: $_colorBlackSecondary;
    font-size: 38px;
    line-height: 48px;
  }

  &__option {
    display: flex;
    margin: 0;
    float: left;
    clear: left;
    max-width: 100%;

    cursor: pointer;

    & + & {
      margin-top: $_stackS;
    }

    &--icon {
      width: 100%;
    }
  }

  &__native {
    position: absolute;
    opacity: 0;
    cursor: pointer;

    &:checked + .#{$baseClass}__radio {
      border-color: $_colorBlueSelected;

      &::before {
        width: 12px;
        height: 12px;
        display: block;
      }

      & + {
        .#{$baseClass}__label {
          color: $_colorBlueSelected;
        }
      }
    }

    &:focus + .#{$baseClass}__radio {
      @include secondaryFocusBoxShadow('');
    }
  }

  &__radio {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    width: 20px;
    height: 20px;
    cursor: pointer;

    border: 2px $_colorRollingStone solid;
    border-radius: 50%;
    box-shadow: 0 0 0 3px transparent;

    transition: border-color $_timeXS ease-in-out, box-shadow $_timeXS ease-in-out;

    &::before {
      content: '';
      display: none;
      width: 0;
      height: 0;
      border-radius: 50%;
      background-color: $_colorBlueSelected;
      cursor: pointer;
      transition: width $_timeXS ease-in-out, height $_timeXS ease-in-out;
    }
  }

  &__label {
    flex-grow: 1;
    padding-left: $_inlineS;
    padding-top: $_stackXXS;

    color: $_colorBlackPrimary;
    font-size: 15px;
    line-height: 20px;
    overflow: hidden;
    text-overflow: ellipsis;

    position: relative;
    display: flex;
  }

  &__labelContent {
    flex-grow: 1;
  }

  &__labelTitle {
    flex-grow: 1;
    font-weight: bold;
  }

  &__titleIcon {
    position: absolute;
    right: 0;
    display: flex;
    flex-grow: 0;
  }

  &__labelInfo {
    padding-top: 2px;
    font-size: 14px;

    a {
      color: $_colorSecondary;
    }
  }

  &__svg {
    display: block;
    height: 16px;
    width: auto;

    &.#{$baseClass}__svg--paypal {
      width: 64px;
    }

    &.#{$baseClass}__svg--creditcard {
      width: 54px;
    }

    &.#{$baseClass}__svg--sofortuberweisung {
      width: 52px;
    }

    &.#{$baseClass}__svg--invoice {
      width: 14px;
    }

    &.#{$baseClass}__svg--cash_on_delivery {
      width: 34px;
    }

    &.#{$baseClass}__svg--payment_in_advance {
      width: 19px;
    }

    &.#{$baseClass}__svg--ideal {
      width: 19px;
    }

    &.#{$baseClass}__svg--payu {
      width: 50px;
    }

    &.#{$baseClass}__svg--carte_bancaire {
      width: 22px;
    }

    &.#{$baseClass}__svg--cash_on_delivery,
    &.#{$baseClass}__svg--invoice,
    &.#{$baseClass}__svg--payment_in_advance {
      fill: $_colorNevada;
    }
  }
}

@media #{$_mediaMUp} {
  .#{$baseClass} {
    &--inline {
      .#{$baseClass}__option {
        margin-right: $_stackL;
      }
    }

    &__titleIcon {
      position: relative;
      align-items: center;
      min-width: 128px;
      justify-content: flex-end;
    }

    &__svg {
      &.#{$baseClass}__svg--cash_on_delivery,
      &.#{$baseClass}__svg--invoice,
      &.#{$baseClass}__svg--ideal,
      &.#{$baseClass}__svg--carte_bancaire,
      &.#{$baseClass}__svg--payment_in_advance,
      &.#{$baseClass}__svg--payu {
        height: 24px;
      }

      &.#{$baseClass}__svg--invoice {
        width: 20px;
      }

      &.#{$baseClass}__svg--cash_on_delivery {
        width: 50px;
      }

      &.#{$baseClass}__svg--payment_in_advance {
        width: 28px;
      }

      &.#{$baseClass}__svg--ideal {
        width: 28px;
      }

      &.#{$baseClass}__svg--carte_bancaire {
        width: 33px;
      }
    }
  }
}

.#{$baseClass}--rebranding {
  .#{$baseClass}__native + .#{$baseClass}__radio {
    border-color: $_colorRebrandingAbbey;
  }

  .#{$baseClass}__native:checked + .#{$baseClass}__radio {
    border-color: $_colorRebrandingSecondary;

    &::before {
      display: block;
      background-color: $_colorRebrandingSecondary;
    }
  }

  .#{$baseClass}__native:checked + .#{$baseClass}__radio + .#{$baseClass}__label {
    color: $_colorRebrandingSecondary;
  }

  .#{$baseClass}__labelTitle {
    @include rebranding-para-bold;
  }

  .#{$baseClass}__labelContent {
    @include rebranding-para-small-regular;
  }

}
