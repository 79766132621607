$baseClass: baseclass('VoucherBox');

.#{$baseClass} {
  & + & {
    .#{$baseClass}__cell {
      padding-top: $_insetXS;
    }
  }
}

.#{$baseClass}__cell {
  padding: 10px 0 $_insetXS;
  text-align: left;
  vertical-align: top;

  & + & {
    padding-left: 12px;
  }
}

.#{$baseClass}__delete_icon {
  vertical-align: middle;
}

.#{$baseClass}__code {
  //white-space: nowrap;
}

.#{$baseClass}__label {
  padding-top: 2px;
  white-space: nowrap;
  font-size: 10px;
}

.#{$baseClass}__remove {
  flex-shrink: 0;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;

  border: 1px solid $_colorAlto;
  border-radius: 50%;
  background: $_colorWildSand;

  cursor: pointer;
  transform: rotate(0);
  transform-origin: center;
  transition: transform $_timeS ease-in-out;

  svg {
    fill: $_colorRollingStone;
  }
}

.#{$baseClass}--rebranding {
  .#{$baseClass}__cell {
    color: $_colorRebrandingBlackOut;
    @include rebranding-h4--regular;
  }
}
