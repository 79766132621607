$baseClass: baseclass('PollingLoader');

.#{$baseClass} {
    padding: $_insetM;
    max-width: 800px;
    background: $_colorWhite;
}

.#{$baseClass}__loader {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: $_insetM;
}

.#{$baseClass}__loaderText {
    margin: $_stackM auto 0;
    max-width: 320px;

    line-height: 20px;
    text-align: center;
}

.#{$baseClass}__loaderIcon {
    animation: linearRotationKeyframes $_timeL ease-in-out infinite;
    transform-origin: center;
    width: 60px;
    height: 60px;
}
