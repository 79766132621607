$baseClass: baseclass('Dropdown');

.#{$baseClass} {
  position: relative;

  &--error {
    .#{$baseClass}__error {
      color: $_colorRed;
    }

    .#{$baseClass}__label {
      color: $_colorRed;
    }

    .#{$baseClass}__select {
      border: 1px solid $_colorRed;
    }

    .#{$baseClass}__icon {
      fill: $_colorRed;
    }
  }

  &--disabled {
    pointer-events: none;

    .#{$baseClass}__label {
      color: $_colorBoulder;
    }

    .#{$baseClass}__select {
      pointer-events: none;
      color: $_colorSilver;
    }

    .#{$baseClass}__icon {
      fill: $_colorBlackDisabled;
    }
  }

  &--has-titleIcon.#{$baseClass}--indentText {
    .#{$baseClass}__buttonText {
      padding-left: 48px;
    }
  }
}

.#{$baseClass}__inner {
  position: relative;
}

.#{$baseClass}__label {
  display: block;

  color: $_colorBlackPrimary;
  font-size: $_fontSizeS;
  line-height: 16px;
  position: absolute;
  top: 1px;
  transform: translate(0, -50%);
  z-index: 0;
  background: $_colorWhite;
  padding: 0 6px;
  margin: 0 10px;

  overflow: hidden;
  text-overflow: ellipsis;
  max-width: calc(100% - 66px);
  white-space: nowrap;

  transition: color $_timeS ease-in-out, font-size $_timeS ease-in-out, transform $_timeS ease-in-out;

  .#{$baseClass}--focused & {
    color: $_colorChathamsBlue;
  }
}

.#{$baseClass}__button {
  display: flex;
  border: 1px solid $_colorAlto;
  height: 48px;
  border-radius: $_borderRadiusDefault;
  background: $_colorWhite;

  transition: all $_timeS;
  cursor: pointer;

  .#{$baseClass}--label & {
    margin-top: 9px;
  }
}

.#{$baseClass}__buttonText {
  padding: 12px $_stackM;
  flex-grow: 1;

  font-size: 16px;
  color: $_colorMineShaft;
  line-height: 24px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  min-height: 48px;
}

.#{$baseClass}__buttonIcon {
  flex-shrink: 0;
  width: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-left: 1px solid $_colorAlto;
}

.#{$baseClass}__select {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  appearance: none;

  &:focus + .#{$baseClass}__button {
    border: 1px solid $_colorChathamsBlue;
    outline: none;

    .#{$baseClass}__buttonIcon {
      border-left-color: $_colorChathamsBlue;
    }
  }

  &:disabled + .#{$baseClass}__button .#{$baseClass}__buttonText {
    opacity: 0.5;
  }
}

.#{$baseClass}--withButton {
  .#{$baseClass}__select {
    border-radius: $_borderRadiusDefault 0 0 $_borderRadiusDefault;
  }
}

.#{$baseClass}__icon {
  fill: $_colorBrand;
  transition: fill $_timeS ease-in-out;

  .#{$baseClass}--focused & {
    fill: $_colorBrand;
  }
}

.#{$baseClass}__optionIcon {
  position: absolute;
  height: 100%;
  top: 0;
  left: 0;
  width: 20px;
  margin: 0 $_stackS 0 $_stackM;
  cursor: pointer;
  pointer-events: none;
  opacity: 0;
  transition: opacity $_timeS ease-in-out;

  &--selected {
    opacity: 1;
  }
}

.#{$baseClass}__message,
.#{$baseClass}__error {
  color: $_colorMineShaft;
  font-size: $_fontSizeS;
  line-height: 16px;
  padding-left: 17px;
}

.#{$baseClass}--rebranding {
  .#{$baseClass}__select {
    @include rebranding-para-regular;
    border-color: $_colorRebrandingFrenchGray;
    color: $_colorRebrandingBlackOut;
  }

  .#{$baseClass}__label {
    @include rebranding-helper-text-regular;
    color: $_colorRebrandingManatee;
  }
}
