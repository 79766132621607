$baseClass: baseclass('CatalogBrandFilter');

.#{$baseClass} {
    width: 82px;
    height: 60px;
    margin: 0 auto;
    background-color: $_colorWhite;
    border-radius: $_borderRadiusDefault;
    border: solid 1px $_colorAlt;

    &--disabled {
        cursor: initial;
    }

    &--active {
        border-color: $_colorBrand;

        .#{$baseClass}__inputBox {
            border-color: $_colorBrand;
        }
    }

    &:hover {
        border-color: $_colorBrand;

        .#{$baseClass}__inputBox {
            border-color: $_colorBrand;
        }
    }

    @media #{$_mediaLDown} {
        border-radius: 0;
    }
}

.#{$baseClass}__content {
    position: relative;
    height: 100%;
}

.#{$baseClass}__link {
    display: block;
    height: 100%;
}

.#{$baseClass}__visuals {
    height: 100%;

    &--loaded {
        .#{$baseClass}__fallbackText {
            opacity: 0;
        }

        .#{$baseClass}__logoImage {
            opacity: 1;
        }
    }
}

.#{$baseClass}__fallback {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: table;
}

.#{$baseClass}__fallbackText {
    display: table-cell;
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
    vertical-align: middle;
    height: 100%;
    font-size: 10px;
    padding: $_insetXS;
    max-width: 80px;
    opacity: 1;
    transition: opacity $_timeS ease-in-out;
}

.#{$baseClass}__logoImage {
    max-width: 58px;
    max-height: 28px;
    width: auto;
    height: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: opacity $_timeS ease-in-out;
    opacity: 0;
}
