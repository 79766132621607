$baseClass: baseclass('Header');

.#{$baseClass} {
    transition: padding-top $_timeS ease-in-out, margin-bottom $_timeS ease-in-out, margin-top $_timeS ease-in-out;

    padding-top: $_measureHeaderMobile;

    &--caris {
        &::before {
            display: block;
            content: '';
            width: 100%;
            height: $_measureCarisMobile;
        }

        .#{$baseClass}__fixed {
            top: -#{$_measureHeaderMobile + $_measureCarisMobile};
            transform: translate(0, #{$_measureHeaderMobile + $_measureCarisMobile});
        }
    }

    &.no-caris {
        &::before {
            display: none;
        }

        @media #{$_mediaLUp} {
            .#{$baseClass}__caris {
                min-width: 210px;
                width: 210px;
            }
        }
    }

    @media #{$_mediaMUp} {
        padding-top: $_measureHeaderTablet;

        &--caris {
            &::before {
                height: $_measureCarisDesktop;
            }

            .#{$baseClass}__fixed {
                top: -#{$_measureHeaderTablet + $_measureCarisDesktop};
                transform: translate(0, #{$_measureHeaderTablet + $_measureCarisDesktop});
            }
        }
    }

    @media #{$_mediaLUp} {
        padding-top: $_measureHeaderDesktop;

        &--navigation,
        &--caris {
            &::after {
                display: block;
                content: '';
                width: 100%;
                height: $_measureNavigation;
            }
        }

        &--caris {
            &::before {
                content: none;
            }
        }

        .#{$baseClass}__fixed {
            top: 0;
            transform: translate(0, 0);
        }

        .#{$baseClass}__bar {
            padding-left: 0;
        }
    }

    @media #{$_mediaLDown} {
        &--static {
            padding-top: 0 !important;
            margin-bottom: 0 !important;

            &::before,
            &::after {
                content: none;
            }

            .#{$baseClass}__fixed {
                position: static;
                transform: none;
            }
        }
    }

    @media #{$_mediaMDown} {
        &.#{$baseClass}--caris-open {
            #promo-banner {
                display: none !important;
            }
        }
    }

    @media (min-width: 1300px) {
        .#{$baseClass}__bar {
            padding-right: $_insetM;
            padding-left: $_insetM;
        }
    }
}

.#{$baseClass}__fixed {
    position: fixed;
    z-index: 12;
    left: 0;
    width: 100%;
    background: $_colorWhite;
    box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.3);

    transition: transform $_timeS ease-in-out;
}

@media (max-width: #{$_widthMTo}) {
    .#{$baseClass}__infoBar {
        display: none;
    }

    .#{$baseClass}__fixed {
        top: -78px;
        transform: translate(0, 78px);

        &--in {
            height: 100%;
            background-color: transparent;
        }
    }
}

@media #{$_mediaSDown} {
    .#{$baseClass}__fixed {
        top: -60px;
        transform: translate(0, 60px);
    }
}

@media #{$_mediaLUp} {
    .#{$baseClass}__bar {
        @include pageWidth;

        position: relative;
        display: flex;
        background-color: $_colorWhite;
    }

    .#{$baseClass}__menu {
        width: 100%;
        flex-grow: 999;
    }

    .#{$baseClass}__caris {
        position: relative;
        z-index: 3;
        flex-grow: 1;
        flex-shrink: 0;
        min-width: 320px;
        width: 320px;
    }

    .#{$baseClass}--caris-open {
        .#{$baseClass}__caris {
            z-index: 5;
        }
    }
}


body.scrolled {
    &:not(.navigation) {
        .#{$baseClass}__fixed {
            transform: translate(0, 0);
        }
    }
}


@media print {
    .#{$baseClass} {
        padding-top: 0;
    }

    .#{$baseClass}__infoBar {
        display: none;
    }

    .#{$baseClass}__fixed {
        position: relative;
        transform: none;
        top: 0;
    }
}
