$baseClass: baseclass('Stepper');

.#{$baseClass} {
    display: inline-block;
    height: 42px;

    border: 1px solid $_colorBlackDecorative;
    border-radius: $_borderRadiusDefault;

    &__inner {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
    }

    &__input {
        text-align: center;
        width: 37px;
        height: 100%;
        user-select: none;
    }

    input[type=number]::-webkit-inner-spin-button,
    input[type=number]::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    input[type=number] {
        -moz-appearance: textfield;
    }

    &__button {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 23px;
        height: 100%;

        text-align: center;
        color: $_colorBrand;
        background-color: $_colorWildSand;

        cursor: pointer;

        &::selection {
            background: transparent;
        }

        &::-moz-selection {
            background: transparent;
        }
    }

    &__button--left {
        border-right: 1px solid $_colorBlackDecorative;
        border-radius: 3px 0 0 3px;
    }

    &__button--rigth {
        border-left: 1px solid $_colorBlackDecorative;
        border-radius: 0 3px 3px 0;
    }
}
