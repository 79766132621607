$baseClass: baseclass('DeliveryTile');

.#{$baseClass} {
    border: 1px solid $_colorAlto;

    & + & {
        margin-top: $_stackM;
    }
}

.#{$baseClass}__headerWrapper {
    overflow: auto;
}

.#{$baseClass}__header {
    position: relative;
    background: $_colorWhite;
    white-space: nowrap;
    border-bottom: 1px solid $_colorAlto;

    &::before {
        content: '';
        position: absolute;
        height: 5px;
    }

    &::before {
        border-top-right-radius: $_borderRadiusDefault;
        border-top-left-radius: $_borderRadiusDefault;
        width: calc(100% + 2px);
        top: -1px;
        left: -1px;
        background: $_colorBrand;
    }
}

.#{$baseClass}__headerInner {
    display: flex;
}

.#{$baseClass}__section {
    display: flex;
    align-items: center;

    padding: $_insetM $_insetS;
    font-size: $_fontSizeS;

    & + & {
        border-left: 1px solid $_colorAlto;
    }

    &--options {
        flex-grow: 1;
        justify-content: flex-end;
    }
}

.#{$baseClass}__label {
    font-weight: bold;
    color: $_colorRollingStone;
}

.#{$baseClass}__label,
.#{$baseClass}__value {
    position: relative;
    z-index: 1;

    line-height: 19px;
}

.#{$baseClass}__status {
    padding-left: $_insetL;
}

.#{$baseClass}__product {
    & + & {
        border-top: 1px solid $_colorAlto;
    }
}

@media #{$_mediaMUp} {
    .#{$baseClass}__section {
        padding: $_insetM;
    }
}
