body {
    .buorg {
        box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.3);
        border: none;
        color: $_colorWhite;
        background-color: $_colorSecondary;
        font: inherit;
    }

    .buorg-pad {
        padding: $_insetM;
        line-height: 1.3em;
        max-width: 650px;
        margin: 0 auto;
    }

    .buorg-buttons {
        margin-top: $_stackS;
    }

    #buorgul {
        box-shadow: none;
        padding: $_stackS $_stackM;
        border-radius: $_borderRadiusDefault;
        background: $_colorBrand;
        font-weight: bold;
    }

    #buorgig {
        padding: $_stackS $_stackM;
        color: $_colorGoldDrop;
        border-color: $_colorAlto;
        background: $_colorWhite;
    }
}
