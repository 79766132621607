$baseClass: baseclass('Checkbox');

.#{$baseClass} {
  display: block;
  break-inside: avoid;

  @include clearfix;

  &--disabled {
    pointer-events: none;

    .#{$baseClass}__native {
      position: absolute;
      opacity: 0;

      &:checked + .#{$baseClass}__checkbox {
        background-color: $_colorSilver;
        border-color: $_colorSilver;
      }
    }

    .#{$baseClass}__labelText,
    .#{$baseClass}__labelInfo {
      color: $_colorSilver;
    }
  }

  &--padding {
    padding: $_stackS 0;
    break-inside: avoid;
  }

  &--error {
    .#{$baseClass}__checkbox {
      border-color: $_colorRed;
    }

    .#{$baseClass}__native {
      &:checked + .#{$baseClass}__checkbox {
        background-color: $_colorRed;
        border-color: $_colorRed;
      }
    }

    .#{$baseClass}__labelText,
    .#{$baseClass}__labelError {
      color: $_colorRedRibbon;
    }
  }

  &--bold-gray .#{$baseClass}__labelText {
    color: $_colorRollingStone;
    font-size: 14px;
    font-weight: bold;
  }
}

.#{$baseClass}__label {
  float: left;
  clear: left;
  cursor: pointer;
}

.#{$baseClass}__labelInner {
  display: flex;
  align-items: flex-start;
}

.#{$baseClass}__native {
  position: absolute;
  opacity: 0;

  &:checked + .#{$baseClass}__checkbox {
    background-color: $_colorBlueSelected;
    border-color: $_colorBlueSelected;

    svg {
      opacity: 1;
      fill: $_colorWhite;
    }
  }

  &:focus + .#{$baseClass}__checkbox {
    @include secondaryFocusBoxShadow('');
  }
}

.#{$baseClass}__checkbox {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  margin-top: $_stackS;
  width: 18px;
  height: 18px;

  border: 2px $_colorRollingStone solid;
  border-radius: 3px;
  transition: background-color $_timeXS ease-in-out, border-color $_timeXS ease-in-out, box-shadow $_timeXS ease-in-out;

  svg {
    display: block;
    opacity: 0;
    width: 12px;
  }
}

.#{$baseClass}__labelContent {
  padding: $_insetS;

  .#{$baseClass}--flat & {
    display: flex;
    align-items: baseline;
  }
}

.#{$baseClass}__labelText {
  color: $_colorBlackPrimary;
  font-size: 15px;
  line-height: 20px;

  a {
    text-decoration: underline;
  }

  &--required::after {
    content: '*';
  }
}

.#{$baseClass}__labelInfo,
.#{$baseClass}__labelError {
  margin-top: $_stackXXS;

  color: $_colorBlackSecondary;
  font-size: $_fontSizeS;
  line-height: 16px;

  &:empty {
    display: none;
  }

  .#{$baseClass}--flat & {
    margin-left: $_stackS;
    margin-top: 0;
  }
}

.#{$baseClass}__labelError {
  transition: background-color $_timeXS ease-in-out;
}

.#{$baseClass}--rebranding {
  .#{$baseClass}__labelText {
    @include rebranding-para-regular;
  }

  .#{$baseClass}__labelInfo, .#{$baseClass}__labelError {
    @include rebranding-helper-text-regular;
  }

  // Standard
  .#{$baseClass}__checkbox {
    border-color: $_colorRebrandingAbbey;
  }

  .#{$baseClass}__native:checked + .#{$baseClass}__checkbox {
    border-color: $_colorBlackSecondary;
    background-color: $_colorBlackSecondary;
  }

  .#{$baseClass}__labelText,
  .#{$baseClass}__labelInfo {
    color: $_colorRebrandingBlackOut;
  }

  // Error
  &.#{$baseClass}--error {
    .#{$baseClass}__checkbox {
      border-color: $_colorRebrandingSemanticRed;
    }

    .#{$baseClass}__native:checked + .#{$baseClass}__checkbox {
      background-color: $_colorRebrandingSemanticRed;
      border-color: $_colorRebrandingSemanticRed;

    }

    .#{$baseClass}__labelText,
    .#{$baseClass}__labelError {
      color: $_colorRebrandingSemanticRed;
    }
  }

  // Disabled
  &.#{$baseClass}--disabled {
    .#{$baseClass}__checkbox {
      border-color: $_colorRebrandingFrenchGray;
    }

    .#{$baseClass}__native:checked + .#{$baseClass}__checkbox {
      background-color: $_colorRebrandingFrenchGray;
      border-color: $_colorRebrandingFrenchGray;

    }

    .#{$baseClass}__labelText, .#{$baseClass}__labelInfo {
      color: $_colorRebrandingFrenchGray;
    }
  }
}
