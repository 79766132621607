$baseClass: baseclass('AccountHeader');

.#{$baseClass} {
    display: flex;
    align-items: flex-end;
    margin-bottom: $_stackM;

    font-weight: bold;
    color: $_colorSecondary;

    &--kcom,
    &--a24 {
        color: $_colorBrand;
    }
}

.#{$baseClass}__small {
    font-size: $_fontSizeS;
    line-height: 20px;
}

.#{$baseClass}__large {
    font-size: $_fontSizeL;
    line-height: 26px;
}

.#{$baseClass}__drawer {
    margin-right: $_stackM;
    flex-shrink: 0;
}

@media #{$_mediaLUp} {
    .#{$baseClass}__drawer {
        display: none !important;
    }
}
