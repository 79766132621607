@mixin pageWidth ($maxWidth: 1300px) {
    width: 100%;
    max-width: $maxWidth;
    margin: 0 auto;
}

@mixin pagePadding {
    padding-left: $_insetM;
    padding-right: $_insetM;

    @media #{$_mediaSDown} {
        padding-left: $_insetS;
        padding-right: $_insetS;
    }
}

@mixin removeScrollbar {
    -ms-overflow-style: none;
    overflow: -moz-scrollbars-none;

    &::-webkit-scrollbar {
        position: absolute;
        width: 0 !important;
        height: 0 !important;
    }
}

@mixin forceScrollbar {
    ::-webkit-scrollbar {
        -webkit-appearance: none;
        width: 7px;
    }

    ::-webkit-scrollbar-thumb {
        border-radius: 4px;
        background-color: rgba(0, 0, 0, 0.5);
        box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
    }
}

@mixin clearfix {
    &::before,
    &::after {
        content: ' ';
        display: table;
    }

    &::after {
        clear: both;
    }
}

@mixin placeholder {
    &::-webkit-input-placeholder {
        @content;
    }

    &::-moz-placeholder {
        @content;
    }

    &:-ms-input-placeholder {
        @content;
    }

    &:-moz-placeholder {
        @content;
    }
}

@function baseclass($identifier) {
    @return $prefix+$identifier+'-'+$version;
}
