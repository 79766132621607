$baseClass: baseclass('FilterSelect');

.#{$baseClass} {
    position: relative;
    flex-shrink: 0;
    flex-grow: 1;
    height: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    padding-bottom: $_insetL;

    &--empty {
        .#{$baseClass}__empty {
            display: block;
        }
    }

    &::before {
        content: '';
        position: absolute;
        display: block;
        background: $_colorBrand;
        width: 0;
        height: 3px;
        opacity: 0;

        transition: width $_timeS ease-in-out, opacity $_timeS ease-in-out;
    }

    &.is-loading::before {
        width: 100%;
        opacity: 1;
    }

    &.is-loading {
        .#{$baseClass}__spinner {
            display: block;
        }
    }
}

.#{$baseClass}__inner {
    height: 100%;
}

.#{$baseClass}__tableHeaderWrapper {
    padding: 0 16px;

    table {
        width: 100%;
    }
}

.#{$baseClass}__optionsWrapper {
    position: relative;
    height: calc(100% - 75px);
}

.#{$baseClass} {
    &.has-status {
        .#{$baseClass}__optionsWrapper {
            height: calc(100% - 109px);
        }
    }
}

.#{$baseClass}__header {
    background-color: $_colorWildSand;

    th {
        color: $_colorRollingStone;
        text-align: left;
        font-weight: bold;
        font-size: 14px;
        height: 32px;
        line-height: 32px;
        padding: 0 10px;
    }
}

.#{$baseClass}__status {
    padding: $_insetM $_insetM 0;

    &:empty {
        display: none;
    }
}

.#{$baseClass}__statusFragment {
    display: inline;
    font-weight: bold;
    line-height: 20px;
}

.#{$baseClass}__filter {
    position: relative;
    margin: 0 $_stackM;
    padding: $_insetM 0;
    flex-shrink: 0;
}

.#{$baseClass}__filterIcon {
    position: absolute;
    top: 50%;
    left: 9px;
    z-index: 2;
    transform: translate(0, -50%);
    fill: $_colorMineShaft;
    pointer-events: none;

    path {
        fill: $_colorRollingStone;
    }
}

.#{$baseClass}__group--hidden {
    display: none;
}

.#{$baseClass}__divider {
    padding: 8px 10px;
    background: $_colorGallery;
    font-size: 14px;
    font-weight: bold;
    text-align: left;
    white-space: nowrap;
    color: $_colorRollingStone;
    border-bottom: 1px solid $_colorConcrete;
    position: relative;
    z-index: 5;
}

.#{$baseClass}__options {
    padding: 0 $_insetM;
    height: 100%;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    min-height: 20vh;

    // TODO: properly fix IOS and scrolling issue
    max-height: calc(100vh - 130px);
    padding-bottom: 44px;

    table {
        width: 100%;
    }
}

.#{$baseClass}__option {
    font-size: $_fontSizeS;
    color: $_colorMineShaft;
    line-height: 1.5;
    letter-spacing: 0;
    background: $_colorWhite;
    border-bottom: 1px solid $_colorConcrete;

    cursor: pointer;
    transition: background $_timeS ease-in-out;

    &--active {
        background: $_colorGallery;
    }

    &:hover {
        background-color: $_colorWildSand;
    }
}

.#{$baseClass}__optionIcon {
    padding: $_insetS $_insetS $_insetS 0;
    width: 25px;
    vertical-align: middle;
    text-align: center;

    & + .#{$baseClass}__optionLabel {
        padding-left: 0;
    }
}

.#{$baseClass}__optionImage {
    display: block;
    max-width: 100%;
    opacity: 0;
    max-height: 30px;

    transition: opacity $_timeS ease-in-out;

    &--loaded {
        opacity: 1;
    }
}

.#{$baseClass}__option--selected {
    background: $_colorBrand !important;
    color: $_colorWhite;
}

.#{$baseClass}__option--hidden {
    display: none;
}

.#{$baseClass}__optionLabel {
    padding: $_insetS 0 $_insetS 10px;

    &:last-child {
        padding-right: 10px;
        vertical-align: middle;
    }
}

.#{$baseClass}__empty {
    display: none;
    padding: 6px 20px;
    border-top: 1px solid $_colorConcrete;

    font-size: $_fontSizeS;
    color: $_colorMineShaft;
    line-height: 1.5;
    letter-spacing: 0;
    background: $_colorWhite;
}

.#{$baseClass}__groupHeader {
    &--stick {
        height: 31px;

        .#{$baseClass}__divider {
            display: block;
            position: absolute;
            top: 0;
            left: $_stackM;
            right: 17px;
            z-index: 5;
        }
    }
}

.#{$baseClass}__spinner {
    display: none;
    animation: linearRotationKeyframes $_timeL ease-in-out infinite;
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
    width: 50px;
    height: 50px;

    g {
        stroke: $_colorAlto;
    }
}
