$baseClass: baseclass('Tabs');

.#{$baseClass}__titleContainer {
    display: flex;
    margin-bottom: -1px;
}

.#{$baseClass}__title {
    padding: 15px 20px 10px;
    position: relative;
    border: 1px solid transparent;
    cursor: pointer;

    &::after {
        display: none;
        content: '';
        position: absolute;
        bottom: -1px;
        height: 1px;
        background: $_colorWhite;
        width: 100%;
        left: 0;
    }

    &--active {
        font-weight: bold;
        border-color: $_colorAlto;
        border-radius: $_borderRadiusDefault $_borderRadiusDefault 0 0;
        background: $_colorWhite;
        z-index: 1;

        &::after {
            display: block;
        }
    }
}

.#{$baseClass}__tabContainer {
    border: 1px solid $_colorAlto;
    position: relative;
    background: $_colorWhite;
    transition: height $_timeS ease-in-out;
    overflow: hidden;
}

.#{$baseClass}__tab {
    opacity: 0;
    transition: opacity $_timeS ease-in-out;
    position: absolute;
    z-index: 1;
    width: 100%;
    top: 0;
    left: 0;
    pointer-events: none;

    &--active {
        opacity: 1;
        position: relative;
        pointer-events: all;
    }

    &--scrollable {
        max-height: 530px;
        overflow: auto;
    }
}
