$baseClass: baseclass('CheckoutStepPayment');

.#{$baseClass}__section {
    & + & {
        margin-top: $_stackM;
    }
}

.#{$baseClass}__headline {
    margin-bottom: $_stackS;

    font-weight: bold;
    font-size: 21px;
}

@media #{$_mediaMUp} {
    .#{$baseClass}__section {
        & + & {
            margin-top: 24px;
        }
    }

    .#{$baseClass}__headline {
        margin-bottom: 24px;
    }
}
