$baseClass: baseclass('IconButton');

.#{$baseClass} {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;

    cursor: pointer;
    transition: background-color $_timeXS ease-in-out, border-color $_timeXS ease-in-out;

    &:active {
        outline: none;
    }

    @include orangeFocus;

    &--disabled {
        pointer-events: none;

        path {
            fill: $_colorBlackDisabled !important;
        }
    }

    &--rect {
        width: 40px;
        height: 40px;
        border-radius: $_borderRadiusDefault;
    }

    &--circle {
        width: 30px;
        height: 30px;
        border-radius: 50%;

        outline: none;
    }

    &--big {
        width: 44px;
        height: 44px;
    }

    &--smallBubble {
        .#{$baseClass}__bubble {
            font-size: 12px;
        }
    }

    &--ghostWhite {
        border: 1px solid $_colorWhite;
        background: transparent;

        @include blueFocus;

        svg {
            width: 100%;
            max-width: 21px;
            max-height: 24px;
            height: auto;
        }

        svg,
        g,
        path {
            fill: $_colorWhite;
        }

        &:hover {
            background: rgba($_colorWhite, 0.2);
        }

        &.#{$baseClass}--inverted {
            background: $_colorWhite;

            svg {
                width: 21px;
                height: auto;
            }

            svg,
            g,
            path {
                fill: $_colorBrand;
            }

            &:hover {
                background: $_colorWhite;
            }
        }
    }

    &--greyGrey {
        border: 1px solid $_colorAlto;
        background: $_colorWildSand;

        svg {
            fill: $_colorRollingStone;
        }

        &:hover {
            background: darken($_colorWildSand, 2);
        }
    }

    &--whiteOrange {
        border: 1px solid $_colorAlto;
        background: $_colorWhite;

        svg,
        g,
        path {
            fill: $_colorBrand;
        }

        &:hover {
            background: darken($_colorWhite, 2);
        }
    }

    &--greyOrange {
        border: 1px solid $_colorAlto;
        background: $_colorWildSand;

        svg,
        g,
        path {
            fill: $_colorBrand;
        }

        &:hover {
            background: darken($_colorWildSand, 2);
        }
    }

    &--orangeWhite {
        border: 1px solid $_colorBrand;
        background: $_colorBrand;

        @include blueFocus;

        svg,
        g,
        path {
            fill: $_colorWhite;
        }

        &:hover {
            background: $_colorSecondary;
            border-color: $_colorSecondary;
        }
    }

    &--whiteGrey {
        border: 1px solid $_colorAlto;
        background: $_colorWhite;

        svg,
        g,
        path {
            fill: $_colorRollingStone;
        }

        &:hover {
            background: darken($_colorWhite, 2);
        }
    }
}

.#{$baseClass}__bubble {
    position: absolute;
    top: 6px;
    right: 6px;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    background: $_colorWhite;
    display: flex;
    justify-content: center;
    align-items: center;

    color: $_colorSecondary;
    font-size: 10px;
    font-weight: bold;
}

.#{$baseClass}__bubbleInner {
    transform: translate(0, 5%);
}
