$baseClass: baseclass('FormFields');

.#{$baseClass} {
    font-size: $_fontSizeM;
}

.#{$baseClass}__input {
    width: 100%;
    padding-left: $_insetS;
    padding-right: $_insetS;
    margin: $_stackS 0 !important;

    &--hide {
        display: none;
    }
}

@media #{$_mediaSDown} {
    .#{$baseClass}__input {
        padding-left: $_insetXS;
        padding-right: $_insetXS;
        margin: $_stackXS 0 !important;
    }
}
