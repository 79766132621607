$baseClass: baseclass('ActionsTile');

.#{$baseClass} {
  display: flex;
  flex-direction: column;
  position: relative;
  border: 1px solid $_colorBlackDecorative;
  background: $_colorWhite;

  & + & {
    border-top: none;
  }

  &--selectable {
    cursor: pointer;
  }

  &--inactive {
    pointer-events: none;

    .#{$baseClass}__content,
    .#{$baseClass}__label {
      opacity: 0.5;
    }

    .#{$baseClass}__label {
      border: 1px solid $_colorBoulder;
      color: $_colorBoulder;
    }
  }

  &--unselectable {
    cursor: unset;

    .#{$baseClass}__label {
      opacity: 0.5;
    }

    .#{$baseClass}__label {
      border: 1px solid $_colorBoulder;
      color: $_colorBoulder;
    }
  }
}

.#{$baseClass}__content {
  flex-grow: 1;
  padding: $_insetM;
  display: flex;
  flex-basis: 70%;
}

.#{$baseClass}__contentRadio {
  flex-shrink: 0;
  padding-right: 10px;
}

.#{$baseClass}__contentInner {
  flex-grow: 1;
}

.#{$baseClass}__contentInfo {
  display: flex;
  align-items: center;
  justify-content: left;
  flex-basis: 30%;

  @media #{$_mediaSDown} {
    justify-content: center;
  }
}

.#{$baseClass}__labels {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: $_insetM;
  padding-left: 0;
}

.#{$baseClass}__label {
  margin: $_stackXS;
  margin-left: 0;
  padding: $_insetXS $_insetS;
  border: 1px solid $_colorBrand;
  color: $_colorBrand;
  font-weight: bold;
  font-size: 12px;
}

.#{$baseClass}__selectButtonContainer {
  display: flex;
  align-items: center;
  margin: $_insetM;
  margin-left: 0;

  @media #{$_mediaSDown} {
    flex-grow: 1;
  }
}

.#{$baseClass}__selectButton {
  @media #{$_mediaSDown} {
    flex-grow: 1;
    margin-left: $_insetM;
  }
}

@media #{$_mediaMUp} {
  .#{$baseClass} {
    flex-direction: row;
  }

  .#{$baseClass}__actions {
    padding-left: 0;
  }
}

@media print {
  .#{$baseClass}__actions {
    display: none !important;
  }
}

.#{$baseClass}--rebranding {
  &.#{$baseClass}--selected {
    color: $_colorRebrandingSecondary;
  }

  .#{$baseClass}__contentInfo {
    @include rebranding-helper-text-bold;
    color: $_colorRebrandingFrenchGray;
  }
}
