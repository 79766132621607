$baseClass: baseclass('HeaderCartButton');

.#{$baseClass} {
    position: relative;

    &--active {
        .#{$baseClass}__miniCart {
            min-height: 250px;
            max-height: 565px;
            opacity: 1;
            transform: translate(0, 0);
            pointer-events: all;
        }
    }
}

.#{$baseClass}__button {
    position: relative;
    white-space: nowrap;
}

.#{$baseClass}__miniCart {
    position: absolute;
    z-index: 6;
    transform: translate(0, -15px);
    top: 48px;
    right: 0;
    min-height: 40px;
    max-height: 40px;
    width: 320px;
    opacity: 0;
    transition: all 0.1s ease-in-out;
    box-shadow: 0 10px 18px 0 rgba(51, 51, 51, 0.3);
    pointer-events: none;

    &::before {
        content: '';
        display: block;
        background: $_colorWhite;
        position: absolute;
        top: -12px;
        right: 0;
        height: 12px;
        width: 132px;
        overflow: hidden;
    }

    @media #{$_mediaMDown} {
        display: none;
    }
}

@media (max-width: 899px) {
    .#{$baseClass} {
        &::before {
            content: none;
        }
    }

    .#{$baseClass}__miniCart {
        display: none;
    }
}
