$baseClass: baseclass('SeoImageDescription');

.#{$baseClass} {
    margin-top: $_stackL;
    font-size: 14px;
    line-height: 22px;
    padding-right: 0;
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;

    strong {
        font-weight: bold;
    }

    picture {
        flex: 0 1 160px;
        max-height: 150px;
        vertical-align: middle;
        margin-right: $_stackS;

        @media #{$_mediaMDown} {
            display: none;
        }

        img {
            max-width: 100%;
            max-height: 100%;
            margin: 0 auto;
        }
    }

    p {
        flex: 1 1;
        margin-right: $_stackM;

        @media #{$_mediaLDown} {
            text-align: justify;
        }
    }

    &.#{$baseClass}__carisClear {
        padding-right: 320px;

        @media #{$_mediaMDown} {
            padding-right: 0;
            margin-top: $_stackM;
        }
    }
}
