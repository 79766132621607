$baseClass: baseclass('VoucherList');

.#{$baseClass} {
    padding: 0 $_insetS;
}

.#{$baseClass}__message {
    margin: $_stackL 0;
    padding: 12px $_inlineM;
    border-radius: $_borderRadiusDefault;
    background: $_colorBrand;
    color: $_colorWhite;
    line-height: 20px;
}

.#{$baseClass}__subtitle {
    margin-bottom: $_stackS;
}
