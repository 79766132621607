$baseClass: baseclass('MiniCart');

.#{$baseClass} {
    background: $_colorWhite;
}

.#{$baseClass}__header {
    width: 320px;
    display: flex;
    color: $_colorBrand;
    height: 40px;
    border-bottom: 1px solid $_colorAlto;
    justify-content: center;
    align-items: center;

    font-size: $_fontSizeM;
    font-weight: bold;
    text-transform: uppercase;

    svg {
        fill: $_colorBrand;
        margin-right: $_stackS;
        margin-left: $_stackS;
    }
}

.#{$baseClass}__productsContainer {
    width: 320px;
    max-height: 400px;

    @include fancyScroll;
}

.#{$baseClass}__footer {
    padding: 15px;
    border-top: 1px solid $_colorAlto;
}

.#{$baseClass}__cartButton {
    margin-bottom: 10px;
}
